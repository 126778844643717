import PropTypes from 'prop-types';
import React from 'react';
import {Quantum, Type} from '@republic/bits';
import {owns} from '@republic/foundation/lang/object';
import {createComponent} from '@republic/react-foundation';
import colors from '../../core/components/colors';
import styles from '../../core/components/styles';

export default (
    createComponent(
        'CopySettings',
        {
            propTypes: {
                align: PropTypes.string,
                color: PropTypes.string,
                decoration: PropTypes.string,
                lh: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                responsive: PropTypes.bool,
                setting: PropTypes.string.isRequired,
                tag: PropTypes.string,
                transform: PropTypes.string,
                type: PropTypes.oneOf(['heading', 'copy', 'label']).isRequired,
                weight: PropTypes.string,
                forLabel: PropTypes.string,
                cursor: PropTypes.string
            },
            defaultProps: {
                responsive: false,
                decoration: 'none'
            }
        },
        ({align, children, color, decoration, lh, responsive, setting, tag, transform, type, weight, forLabel, cursor}) => {
            const style = styles[type][setting];

            return (
                responsive && owns(style, 'responsive') ?
                    <Quantum height="auto" leaps={[
                        {display: 'default'},
                        ['small', {display: 'tablet'}],
                        ['medium', {display: 'desktop'}]]}>

                        {({display}) => {
                            const size = (display && display !== 'default') ? style.responsive[display].size : style.size;

                            return (
                                <Type
                                    align={align || style.align}
                                    color={(
                                        color ?
                                            color.charAt(0) === '#' ?
                                                color :
                                                colors[color] :
                                            style.color)}
                                    decoration={decoration}
                                    leading={lh || style.lh}
                                    margin={0}
                                    padding={0}
                                    size={size}
                                    style={{letterSpacing: style.spacing || 'normal'}}
                                    tag={tag || style.tag}
                                    transform={transform || style.transform}
                                    weight={weight || style.weight}
                                    {...cursor ? {cursor: cursor} : {}}
                                    {...forLabel ? {htmlFor: forLabel} : {}}
                                    {...style.class ? {className: style.class} : {}}>

                                    {style.italic ?
                                        <em>{children}</em> :
                                        children}
                                </Type>);
                        }}
                    </Quantum> :
                    <Type
                        align={align || style.align}
                        color={(
                            color ?
                                color.charAt(0) === '#' ?
                                    color :
                                    colors[color] :
                                style.color)}
                        decoration={decoration}
                        leading={lh || style.lh}
                        margin={0}
                        padding={0}
                        size={style.size}
                        style={{letterSpacing: style.spacing || 'normal'}}
                        tag={tag || style.tag}
                        transform={transform || style.transform}
                        weight={weight || style.weight}
                        {...cursor ? {cursor: cursor} : {}}
                        {...style.class ? {className: style.class} : {}}>

                        {style.italic ?
                            <em>{children}</em> :
                            children}
                    </Type>);
        }));
