export default {
  "name": "prod",
  "analytics": {
    "gtm": {
      "id": "GTM-NPQRZZZ",
      "gtm_auth": "73kbh3aSYYuXzhEB35Fs4g",
      "gtm_preview": "env-2",
      "gtm_cookies_win": "x"
    }
  },
  "auth": {
    "client": "6P72J46q",
    "url": "https://auth.relaypro.com"
  },
  "dash": {
    "url": "https://dash.relaypro.com/"
  },
  "lc": {
    "group": 15,
    "license": 4660801
  },
  "relay": {
    "domain": "account.relaypro.com"
  },
  "rw": {
    "domain": "republicwireless.com",
    "account": "account.republicwireless.com"
  },
  "sourcepoint": {
    "mms": "https://message.sp-prod.net",
    "ccpa": "https://ccpa-service.sp-prod.net",
    "account": "1147"
  },
  "zoho": {
    "widget_code": "02bcad105d479eff48e8736554b57139d996e6b8e40707d951ecf3125bfea21d427d5c1c7ae89ec53c2346bb651ae231"
  },
  "views": {
    "upw": ["919771"]
  }
};