import PropTypes from 'prop-types';

const
    familyType = {
        model: PropTypes.string,
        name: PropTypes.string,
        slug: PropTypes.string,
        skus: (
            PropTypes.arrayOf(
                PropTypes.string.isRequired))
    },

    productType = {
        sku: PropTypes.string,
        model: PropTypes.string,
        available: PropTypes.number,
        status: PropTypes.string,
        price: PropTypes.number,
        name: PropTypes.string,
        marketed: PropTypes.bool,
        priceList: PropTypes.number,
        condition: PropTypes.string,
        devices: (
            PropTypes.arrayOf(
                PropTypes.string.isRequired)),
        color: PropTypes.string,
        display: (
            PropTypes.shape({
                color: PropTypes.string,
                condition: PropTypes.string
            })),
        image: (
            PropTypes.shape({
                thumb: (
                    PropTypes.shape({
                        url: PropTypes.string.isRequired,
                        width: PropTypes.number.isRequired
                    })
                    .isRequired),
                small: (
                    PropTypes.shape({
                        url: PropTypes.string.isRequired,
                        width: PropTypes.number.isRequired
                    })
                    .isRequired)
            })),
        family: (
            PropTypes.shape(
                familyType))
    },

    planType = {
        sku: PropTypes.string,
        model: PropTypes.string,
        type: (
            PropTypes.oneOf([
                'add',
                'upgrade'
            ])),
        price: PropTypes.number,
        min: PropTypes.number,
        max: PropTypes.number,
        name: PropTypes.string,
        marketed: PropTypes.bool,
        priceList: PropTypes.number,
        priceAnnual: PropTypes.number,
        priceListAnnual: PropTypes.number,
        image: (
            PropTypes.shape({
                thumb: (
                    PropTypes.shape({
                        url: PropTypes.string.isRequired,
                        width: PropTypes.number.isRequired
                    })
                    .isRequired),
                small: (
                    PropTypes.shape({
                        url: PropTypes.string.isRequired,
                        width: PropTypes.number.isRequired
                    })
                    .isRequired)
            })),
        family: (
            PropTypes.shape(
                familyType))
    },

    choiceType = {
        value: PropTypes.string.isRequired,
        display: PropTypes.string.isRequired,
        available: PropTypes.number.isRequired,
        selected: PropTypes.bool.isRequired,
        select: PropTypes.func.isRequired
    },

    linkType = {
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        image: PropTypes.string
    };

export {
    familyType,
    productType,
    planType,
    choiceType,
    linkType
};
