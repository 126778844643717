import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {decode} from '@republic/foundation/http/query';
import {each} from '@republic/foundation/lang/array';
import {keys} from '@republic/foundation/lang/object';
import {createComponent, createEnhancer, createNamedContext} from '@republic/react-foundation';
import {override} from '../../services/optimize';

const
    OptimizeContext = (
        createNamedContext(
            'Optimize',
            {
                loading: PropTypes.func.isRequired
            }));

export default (
    name => (
        createEnhancer(
            WrappedComponent => (
                createComponent(
                    `Optimize(${
                        WrappedComponent.displayName ||
                        WrappedComponent.name ||
                        'Component'
                    })`,
                    {},
                    class Optimize extends Component {
                        constructor(props) {
                            super(props);

                            const params = decode(window.location.search.slice(1));

                            each(keys(params), param => {
                                const match = /^optimize\.(.+)$/.exec(param);

                                if (match) {
                                    override(match[1], params[param]);
                                }
                            });
                            this.mounted = false;
                            this.loading = [];
                            this.provider = {
                                loading: promise => {
                                    if (!this.mounted) {
                                        this.loading.push(promise);
                                    }
                                }
                            };
                        }

                        componentDidMount() {
                            const loading = this.loading;

                            this.mounted = true;
                            this.loading = null;
                            (Promise.all(loading)
                            .then(() => {
                                if (this.mounted) {
                                    const doc = document.documentElement;

                                    doc.className = (
                                        doc.className.replace(
                                            new RegExp(`\\b${name}\\b`, 'g'),
                                            ''));
                                }
                            }));
                        }

                        componentWillUnmount() {
                            this.mounted = false;
                        }

                        render() {
                            return (
                                <OptimizeContext.Provider value={this.provider}>
                                    <WrappedComponent {...this.props} />
                                </OptimizeContext.Provider>);
                        }
                    })))));

export {
    OptimizeContext
};
