import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Container, Layout, Quantum} from '@republic/bits';
import {map, reduce} from '@republic/foundation/lang/array';
import {compose} from '@republic/foundation/lang/function';
import {owns} from '@republic/foundation/lang/object';
import {createComponent, create, use} from '@republic/react-foundation';
import $ from '@republic/spellcaster';
import Copy from '../../copy/components/Copy';
import Heading from '../../copy/components/Heading';
import Button from '../../core/components/Button';
import ButtonLink from '../../core/components/ButtonLink';
import colors from '../../core/components/colors';
import Grid from '../../core/components/Grid';
import GridCell from '../../core/components/GridCell';
import Image from '../../core/components/Image';
import {formatPrice} from '../../core/services/prices';
import ModalsStream from '../../core/streams/ModalsStream';
import PlanImage from '../../products/components/PlanImage';
import ProductImage from '../../products/components/ProductImage';
import PlanStream from '../../products/streams/PlanStream';
import ProductStream from '../../products/streams/ProductStream';
import {planType, productType} from '../../products/types';
import UserStream from '../../users/streams/UserStream';
import {constrained} from '../services/cart';
import BusinessCartStream from '../streams/BusinessCartStream';
import BusinessCartAccessoryLineStream from '../streams/BusinessCartAccessoryLineStream';
import BusinessCartDevicePlanLineStream from '../streams/BusinessCartDevicePlanLineStream';
import BusinessCartModalStream from '../streams/BusinessCartModalStream';
import BusinessCartPlanLineStream from '../streams/BusinessCartPlanLineStream';
import BusinessContractRemovalStream from '../streams/BusinessContractRemovalStream';
import BusinessPreviewStream from '../streams/BusinessPreviewStream';
import {cartProductLineType, cartProductItemType, cartType, previewType} from '../types';
import BusinessCartQuantity from './BusinessCartQuantity';
import BusinessCartRemove from './BusinessCartRemove';

const
    modals = ModalsStream.provide,
    Constrained = (
        use({cart: BusinessCartStream},
            ({cart}) => constrained(cart))(
            createComponent(
                'Constrained',
                {
                    propTypes: {
                        products: PropTypes.bool.isRequired,
                        plans: PropTypes.bool.isRequired
                    }
                },
                ({products, plans}) => (
                    (products || plans) ?
                        <Layout padding={{top: 1, bottom: 1}}>
                            {products ?
                                <Copy setting="body" align="center">
                                    Your cart contains the maximum number of products that
                                    can be shipped in a single order.
                                </Copy> :
                                null}
                            {plans ?
                                <Copy setting="body" align="center">
                                    Your cart contains the maximum number of service plans
                                    that can be added to your contract in a single order.
                                </Copy> :
                                null}
                        </Layout> :
                        null)))),

    ContractRemoved = (
        use({removal: BusinessContractRemovalStream},
            ({removal: {removed}}, {removal: {dismiss}, ...props}) => ({
                ...props,
                removed,
                dismiss
            }))(
            createComponent(
                'ContractRemoved',
                {},
                ({removed, dismiss}) => (
                    removed ?
                        <Layout align="center" padding={{top: 1, bottom: 1}}>
                            <Layout padding={{bottom: 1}}>
                                <Copy setting="small" align="center">
                                    We removed all Relay devices and service plans from your cart,
                                    because you already have a service contract. To purchase more
                                    devices or upgrade your service contract, please contact your
                                    account representative.
                                </Copy>
                            </Layout>
                            <Button action={dismiss} label="OK" />
                        </Layout> :
                        null)))),

    PlanItem = (
        compose(
            create(
                PlanStream,
                ({sku}) => ({sku}),
                {
                    propTypes: {
                        sku: PropTypes.string.isRequired
                    }
                }),
            use({
                cart: BusinessCartStream,
                plan: PlanStream
            }))(
            createComponent(
                'PlanItem',
                {
                    propTypes: {
                        mobile: PropTypes.bool,
                        cart: (
                            PropTypes.shape(
                                cartType)
                            .isRequired),
                        plan: (
                            PropTypes.shape(
                                planType)),
                        item: (
                            PropTypes.shape(
                                cartProductItemType)
                            .isRequired)
                    }
                },
                ({cart, plan, item, mobile}) => (
                    (plan && item) ?
                    <CartRow
                        name={plan.family ? plan.family.name : null}
                        price={(
                            cart.payment.annual ?
                                plan.priceAnnual ?
                                    plan.priceAnnual :
                                    null :
                                plan.price ?
                                    plan.price :
                                    null)}
                        totalPrice={(
                            cart.payment.annual ?
                                plan.priceAnnual ?
                                    (item.quantity * plan.priceAnnual) :
                                    null :
                                plan.price ?
                                    (item.quantity * plan.price) :
                                    null)}
                        priceFrequency={(
                            cart.payment.annual ?
                                plan.priceAnnual ?
                                    '/YR' :
                                    null :
                                plan.price ?
                                    '/MO' :
                                    null)}
                        mobile={mobile}
                        type="plan"
                        item={item} /> :
                        null)))),

    PlanLine = (
        compose(
            create(
                BusinessCartPlanLineStream,
                ({family}) => ({family, available: true}),
                {
                    propTypes: {
                        family: PropTypes.string.isRequired
                    }
                }),
            use({line: BusinessCartPlanLineStream}))(
            createComponent(
                'PlanLine',
                {
                    propTypes: {
                        mobile: PropTypes.bool,
                        line: (
                            PropTypes.shape(
                                cartProductLineType))
                    }
                },
                ({line, mobile}) => (
                    line ?
                        <>
                            {reduce(
                                line.family.skus,
                                (items, sku) => {
                                    if (owns(line.items, sku)) {
                                        items.push(
                                            <PlanItem
                                                key={sku}
                                                sku={sku}
                                                mobile={mobile}
                                                item={line.items[sku]} />);
                                    }
                                    return items;
                                },
                                [])}
                        </> :
                        null)))),

    // DeviceItem = (
    //     compose(
    //         create(
    //             ProductStream,
    //             ({sku}) => ({sku}),
    //             {
    //                 propTypes: {
    //                     sku: PropTypes.string.isRequired
    //                 }
    //             }),
    //         use({product: ProductStream}))(
    //         createComponent(
    //             'DeviceItem',
    //             {
    //                 propTypes: {
    //                     mobile: PropTypes.bool,
    //                     product: (
    //                         PropTypes.shape(
    //                             productType)),
    //                     item: (
    //                         PropTypes.shape(
    //                             cartProductItemType)
    //                         .isRequired)
    //                 }
    //             },
    //             ({product, item, mobile}) => (
    //                 (product && item) ?
    //                 <CartRow
    //                     name={product.family ? product.family.name : null}
    //                     color={product.display && product.display.color ? product.display.color : null}
    //                     price={product.price ? product.price : null}
    //                     totalPrice={product.price ? (item.quantity * product.price) : null}
    //                     mobile={mobile}
    //                     type="product"
    //                     item={item} /> :
    //                     null)))),
    //
    // DeviceLine = (
    //     compose(
    //         create(
    //             BusinessCartDeviceLineStream,
    //             ({family}) => ({family, available: true}),
    //             {
    //                 propTypes: {
    //                     family: PropTypes.string.isRequired
    //                 }
    //             }),
    //         use({line: BusinessCartDeviceLineStream}))(
    //         createComponent(
    //             'DeviceLine',
    //             {
    //                 propTypes: {
    //                     mobile: PropTypes.bool,
    //                     line: (
    //                         PropTypes.shape(
    //                             cartProductLineType))
    //                 }
    //             },
    //             ({line, mobile}) => (
    //                 line ?
    //                     <>
    //                         {reduce(
    //                             line.family.skus,
    //                             (items, sku) => {
    //                                 if (owns(line.items, sku)) {
    //                                     items.push(
    //                                         <DeviceItem
    //                                             key={sku}
    //                                             sku={sku}
    //                                             mobile={mobile}
    //                                             item={line.items[sku]} />);
    //                                 }
    //                                 return items;
    //                             },
    //                             [])}
    //                     </> :
    //                     null)))),

    DevicePlanItem = (
        compose(
            create(
                ProductStream,
                ({sku}) => ({sku}),
                {
                    propTypes: {
                        sku: PropTypes.string.isRequired
                    }
                }),
            use({product: ProductStream}))(
            createComponent(
                'DeviceItem',
                {
                    propTypes: {
                        mobile: PropTypes.bool,
                        product: (
                            PropTypes.shape(
                                productType)),
                        item: (
                            PropTypes.shape(
                                cartProductItemType)
                            .isRequired)
                    }
                },
                ({product, item, mobile}) => (
                    (product && item) ?
                        <CartRow
                            name={product.family ? product.family.name : null}
                            color={product.display && product.display.color ? product.display.color : null}
                            price={product.price ? product.price : null}
                            totalPrice={product.price ? (item.quantity * product.price) : null}
                            mobile={mobile}
                            type="product"
                            item={item} /> :
                            null)))),

    DevicePlanLine = (
        compose(
            create(
                BusinessCartDevicePlanLineStream,
                ({deviceFamily, planFamily}) => ({deviceFamily, planFamily, available: true}),
                {
                    propTypes: {
                        deviceFamily: PropTypes.string.isRequired,
                        planFamily: PropTypes.string.isRequired
                    }
                }),
            use({line: BusinessCartDevicePlanLineStream}))(
            createComponent(
                'DevicePlanLine',
                {
                    propTypes: {
                        mobile: PropTypes.bool,
                        line: (
                            PropTypes.shape(
                                cartProductLineType))
                    }
                },
                ({line, mobile}) => (
                    line ?
                        <>
                            {reduce(
                                line.family.skus,
                                (items, sku) => {
                                    if (owns(line.items, sku)) {
                                        items.push(
                                            <DevicePlanItem
                                                key={sku}
                                                sku={sku}
                                                mobile={mobile}
                                                item={line.items[sku]} />);
                                    }
                                    return items;
                                },
                                [])}
                        </> :
                        null)))),

    AccessoryItem = (
        compose(
            create(
                ProductStream,
                ({sku}) => ({sku}),
                {
                    propTypes: {
                        sku: PropTypes.string.isRequired
                    }
                }),
            use({product: ProductStream}))(
            createComponent(
                'AccessoryItem',
                {
                    propTypes: {
                        mobile: PropTypes.bool,
                        product: (
                            PropTypes.shape(
                                productType)),
                        item: (
                            PropTypes.shape(
                                cartProductItemType)
                            .isRequired)
                    }
                },
                ({product, item, mobile}) => (
                    (product && item) ?
                        <CartRow
                            name={product.family ? product.family.name : null}
                            color={product.display && product.display.color ? product.display.color : null}
                            price={product.price ? product.price : null}
                            totalPrice={product.price ? (item.quantity * product.price) : null}
                            mobile={mobile}
                            type="product"
                            item={item} /> :
                        null)))),

    AccessoryLine = (
        compose(
            create(
                BusinessCartAccessoryLineStream,
                ({family}) => ({family, available: true}),
                {
                    propTypes: {
                        family: PropTypes.string.isRequried
                    }
                }),
            use({line: BusinessCartAccessoryLineStream}))(
            createComponent(
                'AccessoryLine',
                {
                    propTypes: {
                        mobile: PropTypes.bool,
                        line: (
                            PropTypes.shape(
                                cartProductLineType))
                    }
                },
                ({line, mobile}) => (
                    line ?
                        <>
                            {reduce(
                                line.family.skus,
                                (items, sku) => {
                                    if (owns(line.items, sku)) {
                                        items.push(
                                            <AccessoryItem
                                                key={sku}
                                                sku={sku}
                                                mobile={mobile}
                                                item={line.items[sku]} />);
                                    }
                                    return items;
                                },
                                [])}
                        </> :
                        null)))),

    Summary = (
        compose(
            BusinessCartModalStream.context.with('modal'),
            use({preview: BusinessPreviewStream}))(
            createComponent(
                'Summary',
                {
                    modal: (
                        PropTypes.shape(
                            BusinessCartModalStream.types)
                        .isRequired),
                    preview: (
                        PropTypes.shape(
                            previewType)),
                    mobile: PropTypes.bool
                },
                ({preview, modal, mobile}) => (
                    <Layout position="relative" padding={{top: 1, bottom: 1}}>
                        {(preview && preview.data) ?
                            mobile ?
                                <Layout>
                                    <Grid>
                                        <GridCell width="60%" justify="center">
                                            {preview.data.total.price.recurring > 0 &&
                                                <Grid padding={{bottom: 0.5}}>
                                                    <GridCell width="60%" justify="center">
                                                        <Layout padding={{left: 0.5, right: 0.5}}>
                                                            <Copy setting="small">
                                                                Recurring Charges
                                                            </Copy>
                                                        </Layout>
                                                    </GridCell>
                                                    <GridCell width="40%" justify="center">
                                                        <Layout row>
                                                            <Copy setting="body" weight="bold">
                                                                {`$${formatPrice(preview.data.total.price.recurring)}`}
                                                            </Copy>
                                                            <Copy setting="body" weight="bold">
                                                                {preview.data.annual ? '/YR' : '/MO'}
                                                            </Copy>
                                                        </Layout>
                                                    </GridCell>
                                                </Grid>}
                                            <Grid>
                                                <GridCell width="60%" justify="center">
                                                    <Layout padding={{left: 0.5, right: 0.5}}>
                                                        <Copy setting="small">
                                                            One-Time Charge
                                                        </Copy>
                                                    </Layout>
                                                </GridCell>
                                                <GridCell width="40%" justify="center">
                                                    <Copy setting="body" weight="bold">
                                                        {`$${formatPrice(preview.data.total.price.once)}`}
                                                    </Copy>
                                                </GridCell>
                                            </Grid>
                                        </GridCell>
                                        <GridCell width="40%" justify="center">
                                            <Layout padding={{left: 0.5, right: 0.5}}>
                                                <ButtonLink
                                                    buttonColor="teal"
                                                    external={false}
                                                    href={$.url('pages:business:checkout').url}
                                                    label="Checkout"
                                                    padding={[1, 1, 1, 1]}
                                                    action={modal.hide} />
                                            </Layout>
                                        </GridCell>
                                    </Grid>
                                    <Layout
                                        padding={{top: 0.5}}
                                        align="center"
                                        role="button"
                                        cursor="pointer"
                                        aria-label="Email this quote"
                                        onClick={() => modals.show('save-team')}>

                                        <Copy setting="tiny" align="center" decoration="underline">
                                            Email this quote
                                        </Copy>
                                     </Layout>
                                </Layout> :
                                <Layout row align="center" justify="center">
                                    {preview.data.total.price.recurring > 0 &&
                                        <Layout padding={{right: 2}}>
                                            <Copy setting="small" color={colors.gray_4} weight="bold" transform="uppercase">
                                                Recurring Charges
                                            </Copy>
                                            <Layout row align="center">
                                                <Heading setting="huge" color={colors.gray_4}>
                                                    {`$${formatPrice(preview.data.total.price.recurring)}`}
                                                </Heading>
                                                <Heading setting="card_header" color={colors.gray_4}>
                                                    {preview.data.annual ? '/YR' : '/MO'}
                                                </Heading>
                                            </Layout>
                                        </Layout>}
                                    <Layout padding={{left: preview.data.total.price.recurring > 0 ? 2 : 0}}>
                                        <Copy setting="small" color={colors.gray_4} weight="bold" transform="uppercase">
                                            One-Time Charge
                                        </Copy>
                                        <Heading setting="huge" color={colors.gray_4}>
                                            {`$${formatPrice(preview.data.total.price.once)}`}
                                        </Heading>
                                    </Layout>
                                </Layout> :
                            null}
                        {!mobile &&
                            <Layout position="absolute" positions={{right: 1}}>
                                <ButtonLink
                                    buttonColor="teal"
                                    transparent={false}
                                    external={false}
                                    href={$.url('pages:business:checkout').url}
                                    label="Checkout"
                                    action={modal.hide} />
                                <Layout
                                    padding={{top: 0.5}}
                                    align="center"
                                    role="button"
                                    cursor="pointer"
                                    aria-label="Email this quote"
                                    onClick={() => modals.show('save-team')}>

                                    <Copy setting="tiny" align="center" decoration="underline">
                                        Email this quote
                                    </Copy>
                                 </Layout>
                            </Layout>}
                    </Layout>)))),
    CartRow = (
        createComponent(
            'CartRow',
            {
                name: PropTypes.string,
                color: PropTypes.string,
                price: PropTypes.number,
                totalPrice: PropTypes.number,
                priceFrequency: PropTypes.string,
                mobile: PropTypes.bool,
                type: PropTypes.string,
                item: (
                    PropTypes.shape(
                        cartProductItemType)
                    .isRequired)
            },
            ({name, color, price, totalPrice, priceFrequency, mobile, type, item}) => (
                mobile ?
                    <Grid>
                        <GridCell width="20%" align="center">
                            {type === 'product' && <ProductImage image="promo" />}
                            {type === 'plan' && <PlanImage image="promo" />}
                        </GridCell>
                        <GridCell width="40%">
                            <Layout padding={{left: 1}}>
                                <Layout row padding={{bottom: 0.5}}>
                                    {name ?
                                        <Copy setting="body">
                                            {name}
                                        </Copy> :
                                        null}
                                    {color ?
                                        <Copy setting="body">
                                            &nbsp;({color})
                                        </Copy> :
                                        null}
                                </Layout>
                                <Copy setting="body">
                                    {totalPrice ?
                                        `$${formatPrice(totalPrice)}${priceFrequency ? priceFrequency : ''}` :
                                        null}
                                </Copy>
                            </Layout>
                        </GridCell>
                        <GridCell width="40%" align="center">
                            <BusinessCartQuantity {...item} width="50px" fontSize="1.5em" />
                            <Layout padding={{top: 0.5}}>
                                <BusinessCartRemove {...item} />
                            </Layout>
                        </GridCell>
                    </Grid> :
                    <Grid>
                        <GridCell width="40%">
                            <Layout row>
                                {type === 'product' && <ProductImage image="thumb" />}
                                {type === 'plan' && <PlanImage image="thumb" />}
                                <Layout row padding={{left: 1}}>
                                    {name ?
                                        <Copy setting="body">
                                            {name}
                                        </Copy> :
                                        null}
                                    {color ?
                                        <Copy setting="body">
                                            &nbsp;({color})
                                        </Copy> :
                                        null}
                                </Layout>
                            </Layout>
                        </GridCell>
                        <GridCell width="15%" align="center">
                            <Copy setting="body">
                                {price ?
                                    `$${formatPrice(price)}${priceFrequency ? priceFrequency : ''}` :
                                    null}
                            </Copy>
                        </GridCell>
                        <GridCell width="30%" align="center">
                            <BusinessCartQuantity {...{
                                ...item,
                                ...type === 'plan' ? {readOnly: true} : {}
                            }} />
                            {type !== 'plan' ?
                                <Layout padding={{top: 0.5}}>
                                    <BusinessCartRemove {...item} />
                                </Layout> :
                                null}
                        </GridCell>
                        <GridCell width="15%" align="end">
                            <Copy setting="body">
                                {totalPrice ?
                                    `$${formatPrice(totalPrice)}${priceFrequency ? priceFrequency : ''}` :
                                    null}
                            </Copy>
                        </GridCell>
                    </Grid>)));

export default (
    compose(
        use({
                cart: BusinessCartStream,
                modal: BusinessCartModalStream,
                user: UserStream
            },
            ({cart: {payment, devices, accessories, contract}, modal: {opened, expanded}, user}, {modal, user: ignore1}) => ({
                payment,
                devices,
                accessories,
                contract,
                opened,
                expanded,
                modal,
                business: !!(user && user.data && user.data.business),
                consumer: !!(user && user.data && !user.data.business)
            })))(
        createComponent(
            'BusinessCart',
            {},
            ({contract, devices, accessories, opened, expanded, modal, consumer}) => (
                <Quantum leaps={[
                    {
                        mobile: true
                    },
                    ['small', {
                        mobile: false
                    }]]}>

                    {({mobile}) => (
                        <>
                            <Layout
                                position="fixed"
                                positions={{top: 0, bottom: 0, left: 0, right: 0}}
                                transition={true}
                                color={colors.white}
                                layer={201}
                                height={(opened && expanded ? '100vh' : 0)}
                                overflow="auto"
                                width="100%">

                                <Container>
                                    <Layout
                                        height={(opened && expanded) ? '100%' : '0px'}
                                        padding={{bottom: (opened && expanded) ? '243px' : 0, left: mobile ? 1 : 4, right: mobile ? 1 : 4}}>

                                        <Layout align="end" padding={{top: 1.5}}>
                                            <Layout onClick={modal.contract} role="button" aria-label="Collapse cart" title="Collapse cart" cursor="pointer">
                                                <Image
                                                    image={$.svg('../../static/svg/button/arrow-down-gray.svg')}
                                                    alt="Arrow down collapse cart"
                                                    style={{alignSelf: 'center'}} />
                                            </Layout>
                                        </Layout>
                                        {(contract.length > 0 || devices.length > 0 || accessories.length > 0) ?
                                            <Layout>
                                                <Layout padding={{bottom: 2}}>
                                                    <Heading setting="title" color={colors.teal} weight="normal">
                                                        In your cart
                                                    </Heading>
                                                </Layout>
                                                <Constrained />
                                                <ContractRemoved />
                                                {!mobile &&
                                                    <Grid padding={{bottom: 1}}>
                                                        <GridCell width="40%">
                                                            <Copy setting="body">
                                                                Product
                                                            </Copy>
                                                        </GridCell>
                                                        <GridCell width="15%" align="center">
                                                            <Copy setting="body">
                                                                Price
                                                            </Copy>
                                                        </GridCell>
                                                        <GridCell width="30%" align="center">
                                                            <Copy setting="body">
                                                                Quantity
                                                            </Copy>
                                                        </GridCell>
                                                        <GridCell width="15%" align="end">
                                                            <Copy setting="body">
                                                                Total
                                                            </Copy>
                                                        </GridCell>
                                                    </Grid>}
                                            </Layout> :
                                            <Layout>
                                                <Heading setting="title" color={colors.teal} weight="normal">
                                                    Your cart is empty
                                                </Heading>
                                            </Layout>}
                                        <Layout border={{width: [0, 0, (contract.length > 0 || devices.length > 0 || accessories.length > 0) ? 1 : 0, 0], color: colors.gray_3}}>
                                            <Layout>
                                                {map(
                                                    contract,
                                                    ({family}) => (
                                                        <Layout
                                                            key={family}
                                                            padding={[2, 0, 2, 0]}
                                                            border={{width: [1, 0, 0, 0], color: colors.gray_3}}>

                                                            <PlanLine family={family} mobile={mobile} />
                                                        </Layout>))}
                                            </Layout>
                                            {/* <Layout>
                                                {map(
                                                    devices,
                                                    ({family}) => (
                                                        <Layout
                                                            key={family}
                                                            padding={[2, 0, 2, 0]}
                                                            border={{width: [1, 0, 0, 0], color: colors.gray_3}}>

                                                            <DeviceLine family={family} mobile={mobile} />
                                                        </Layout>))}
                                            </Layout> */}
                                            <Layout>
                                                {map(
                                                    devices,
                                                    ({family}) => (
                                                        <Layout
                                                            key={family}
                                                            padding={[2, 0, 2, 0]}
                                                            border={{width: [1, 0, 0, 0], color: colors.gray_3}}>

                                                            {/* HACK (mkibbel): We're not associating the plan with the
                                                                device, so we have to just hardcode the plan family
                                                                slug here, rather than drive it from cart data. */}
                                                            <DevicePlanLine
                                                                deviceFamily={family}
                                                                planFamily="relay-service-plan"
                                                                mobile={mobile} />
                                                        </Layout>))}
                                            </Layout>
                                            <Layout>
                                                {map(
                                                    accessories,
                                                    ({family}) => (
                                                        <Layout
                                                            key={family}
                                                            padding={[2, 0, 2, 0]}
                                                            border={{width: [1, 0, 0, 0], color: colors.gray_3}}>

                                                            <AccessoryLine family={family} mobile={mobile} />
                                                        </Layout>))}
                                            </Layout>
                                        </Layout>
                                    </Layout>
                                </Container>
                            </Layout>
                            {!consumer &&
                                <Layout
                                    position="fixed"
                                    positions={{bottom: 0, left: 0, right: 0}}
                                    style={{transform: opened && (contract.length > 0 || devices.length > 0 || accessories.length > 0) ? 'translateY(0)' : 'translateY(100%)'}}
                                    color={colors.white}
                                    transition={true}
                                    layer={202}
                                    minHeight={opened ? '150px' : 0}>

                                    <Layout
                                        color={colors.gray_4}
                                        align="center"
                                        padding={0.5}
                                        position="relative">

                                        {expanded ?
                                            <Layout onClick={modal.contract} cursor="pointer" padding={{left: 2, right: 2}}>
                                                <Copy
                                                    setting="body"
                                                    color={colors.white}
                                                    weight="bold"
                                                    align="center"
                                                    transform="uppercase">

                                                    Hide Cart
                                                </Copy>
                                            </Layout> :
                                            <Layout onClick={modal.expand} cursor="pointer" padding={{left: 2, right: 2}}>
                                                <Copy
                                                    setting="body"
                                                    color={colors.white}
                                                    weight="bold"
                                                    align="center"
                                                    transform="uppercase">

                                                    Show Cart
                                                </Copy>
                                            </Layout>}
                                            <Layout
                                                position="absolute"
                                                positions={{right: 1}}
                                                cursor="pointer"
                                                role="button"
                                                aria-label="Close cart"
                                                onClick={modal.hide}>

                                                <Copy setting="large_body" color={colors.white} transform="uppercase" weight="normal">
                                                    X
                                                </Copy>
                                            </Layout>
                                        </Layout>
                                    <Summary mobile={mobile} />
                                </Layout>}
                        </>)}
                </Quantum>))));
