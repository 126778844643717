import PropTypes from 'prop-types';
import {createContainer, use} from '@republic/react-foundation';
import AuthStream from '../streams/AuthStream';

export default (
    createContainer(
        'LoginButtonContainer',
        {
            propTypes: {
                enabled: PropTypes.bool.isRequired,
                authorize: PropTypes.func.isRequired
            }
        },
        use({
                enabled: (
                    AuthStream.as(
                        auth => (
                            auth.map(
                                ({state}) => (
                                    state === AuthStream.states.unauthenticated)))))
            },
            ({enabled}, {enabled: {authorize}}) => ({
                enabled,
                authorize
            }))));
