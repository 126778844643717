import PropTypes from 'prop-types';
import {mapfind, reduce} from '@republic/foundation/lang/array';
import {noop} from '@republic/foundation/lang/function';
import {get, owns} from '@republic/foundation/lang/object';
import {createStream, createStreamContext, stream} from '@republic/react-foundation';
import CatalogStream from '../../products/streams/CatalogStream';
import OffersStream from '../../products/streams/OffersStream';
import PlanOffersStream from '../../products/streams/PlanOffersStream';
import UserStream from '../../users/streams/UserStream';
import {constraints} from '../services/cart';
import BusinessCartStream from './BusinessCartStream';

export default (
    createStream(
        'BusinessCartDevicePlanLineStream',
        {
            enhance: (
                stream(
                    ['deviceFamily', 'planFamily', 'available'],
                    {
                        propTypes: {
                            deviceFamily: PropTypes.string,
                            planFamily: PropTypes.string,
                            available: PropTypes.bool
                        }
                    }))
        },
        createStreamContext('BusinessCartDevicePlanLineContext'),
        {
            catalog: CatalogStream,
            offers: OffersStream,
            plans: PlanOffersStream,
            user: UserStream,
            cart: BusinessCartStream,
            deviceSlug: ({deviceFamily}) => deviceFamily,
            planSlug: ({planFamily}) => planFamily,
            available: ({available}) => available
        },
        null,
        null,
        null,
        (on, methods, {cart: {devicePlan}}) => on.dependencies((state, {catalog, offers, plans, user, cart, deviceSlug, planSlug, available}) => {
            const business = !(user && user.data) || !!user.data.business;

            if (deviceSlug && planSlug && catalog && catalog.data) {
                const
                    deviceFamily = catalog.data.families[deviceSlug],
                    planFamily = catalog.data.families[planSlug],
                    planSku = (
                        (planFamily && planFamily.skus.length === 1) ?
                            planFamily.skus[0] :
                            null),
                    plan = (planSku && catalog.data.products[planSku]) || null;

                if (deviceFamily && plan && plan.marketed) {
                    const
                        planOffer = get(plans, 'data', planSku) || null,
                        items = (
                            reduce(
                                deviceFamily ? deviceFamily.skus : [],
                                (items, deviceSku) => {
                                    const device = catalog.data.products[deviceSku] || null;

                                    if (device && device.marketed) {
                                        const deviceOffer = get(offers, 'data', deviceSku) || null;

                                        if (!available || (
                                                (!plans || (
                                                    planOffer &&
                                                    planOffer.available >= 0)) &&
                                                deviceOffer &&
                                                deviceOffer.available >= 0)) {

                                            const
                                                enabled = !!(
                                                    business &&
                                                    (!plans || (
                                                        planOffer &&
                                                        planOffer.available >= 0)) &&
                                                    deviceOffer &&
                                                    deviceOffer.available >= 0),
                                                quantity = (
                                                    mapfind(
                                                        cart.devices,
                                                        ({family, items}) => {
                                                            if (family === deviceSlug && owns(items, deviceSku)) {
                                                                return items[deviceSku].quantity;
                                                            }
                                                        }) ||
                                                    0),
                                                {min: planMin, max: planMax} = constraints(plan, planOffer, cart),
                                                {min: deviceMin, max: deviceMax} = constraints(device, deviceOffer, cart),
                                                min = Math.max(planMin - 1, deviceMin),
                                                max = Math.min(planMax - 1, deviceMax),
                                                update = (
                                                    enabled ?
                                                        next => {
                                                            if (next !== quantity) {
                                                                devicePlan(deviceSku, planSku, next);
                                                            }
                                                        } :
                                                        noop);

                                            items[deviceSku] = {
                                                enabled,
                                                quantity,
                                                min,
                                                max,
                                                update
                                            };
                                        }
                                    }
                                    return items;
                                },
                                {}));
                    return {
                        family: deviceFamily,
                        items
                    };
                }
            }
            return null;
        })));
