import PropTypes from 'prop-types';
import React from 'react';
import {NavLink} from 'react-router-dom';
import {Layout} from '@republic/bits';
import {find} from '@republic/foundation/lang/array';
import {compose} from '@republic/foundation/lang/function';
import {get} from '@republic/foundation/lang/object';
import {createComponent, create, use} from '@republic/react-foundation';
import {Type} from '@republic/bits';
import $ from '@republic/spellcaster';
import LoginButton from '../../auth/components/LoginButton';
import LogoutButton from '../../auth/components/LogoutButton';
import AuthStream from '../../auth/streams/AuthStream';
import Anchor from '../../core/components/Anchor';
import Button from '../../core/components/Button';
import colors from '../../core/components/colors';
import LoadingSpinner from '../../core/components/LoadingSpinner';
import ScrollContext from '../../core/components/ScrollContext';
import ModalStream from '../../core/streams/ModalStream';
import {modalType} from '../../core/types';
import ModalsStream from '../../core/streams/ModalsStream';
import env from '../../env';
import UserStream from '../../users/streams/UserStream';

const
    modals = ModalsStream.provide,

    authenticated = ({state}) => state === AuthStream.states.authenticated,

    authenticating = ({state}) => state === AuthStream.states.authenticating,

    owner = user => {
        const {data: {role} = {}} = user || {};

        return role === 'Owner';
    },

    admin = user => {
        const {data: {role} = {}} = user || {};

        return role === 'Admin';
    },

    billing = user => {
        const {data: {role} = {}} = user || {};

        return role === 'Billing';
    },

    business = user => {
        const {data: {business = null} = {}} = user || {};

        return !!business;
    },

    purchaser = user => {
        const {data: {portal_store} = {}} = user || {};

        return portal_store ? true : false;
    },

    canView = (user, view) => {
        const {data: {account} = {}} = user || {};

        return (!find(view, (id) => account === id));
    },

    greeting = user => {
        const {data: {profile} = {}} = user || {};

        return (
            profile ?
                `Hello, ${(profile.first_name || profile.last_name)}!` :
                'Account');
    };

export default (
    compose(
        create(ModalStream, {name: 'account-menu'}),
        use({
                modal: ModalStream,
                auth: AuthStream,
                user: UserStream
            },
            ({modal, auth, user}, {auth: ignore1, user: ignore2, ...props}) => ({
                ...props,
                modal,
                authenticated: authenticated(auth),
                authenticating: authenticating(auth),
                owner: owner(user),
                admin: admin(user),
                billing: billing(user),
                business: business(user),
                purchaser: purchaser(user),
                canView: canView(user, get(env, 'views', 'upw') || []),
                greeting: greeting(user)
            })))(
        createComponent(
            'AccountMenu',
            {
                propTypes: {
                    modal: PropTypes.shape(modalType).isRequired,
                    authenticated: PropTypes.bool.isRequired,
                    authenticating: PropTypes.bool.isRequired,
                    owner: PropTypes.bool.isRequired,
                    admin: PropTypes.bool.isRequired,
                    billing: PropTypes.bool.isRequired,
                    business: PropTypes.bool.isRequired,
                    purchaser: PropTypes.bool.isRequired,
                    canView: PropTypes.bool.isRequired,
                    greeting: PropTypes.string.isRequired
                }
            },
            ({modal, authenticated, authenticating, owner, admin, billing, business, purchaser, canView, greeting}) => (
                <>
                    {modal.shown ?
                        <div className="account-background" onClick={modal.hide}></div> :
                        null}
                    <div className={`account ${modal.shown ? 'account-show' : ''} ${!authenticated ? 'account-light' : ''}`}>
                        <ScrollContext className="account-container">
                            <div className="container padding-1">
                                <div className="row">
                                    <div className="always six columns body-copy-small">
                                        <Type color={colors.white}>{greeting}</Type>
                                    </div>
                                    <div className="always six columns text-right">
                                        <Button
                                            label="Hide"
                                            fontColor={authenticated ? 'white' : 'dark'}
                                            buttonColor={authenticated ? 'black_gray' : 'white_gray'}
                                            padding={[0, 0, 0, 0]}
                                            align="end"
                                            justify="end"
                                            transform="capitalize"
                                            action={() => {
                                                modal.hide();
                                            }} />
                                    </div>
                                </div>
                                <div className="row margin-top-1">
                                    {!authenticated ?
                                        <LoginButton /> :
                                        null}

                                    {authenticating ?
                                        <Layout align="center" justify="center" padding={2}>
                                            <LoadingSpinner
                                                image={$.image('../../static/img/icons/spinner.gif', {width: 100, height: 100})} />
                                        </Layout> :
                                        null}

                                    {authenticated ?
                                        <>
                                            <hr />
                                            <div className="margin-top-1 margin-bottom-1">
                                                <div className="flex x-end">
                                                    <Type
                                                        weight="bold"
                                                        margin={{bottom: 0.5}}
                                                        className="sofia"
                                                        size={1.375}
                                                        color={colors.white}>Manage Your Account</Type>
                                                </div>
                                                {(owner || billing || admin) ?
                                                    <div className="flex x-end">
                                                        <NavLink
                                                            className="nav-link side-link"
                                                            key="account-info"
                                                            to={$.url('account:info').url}
                                                            activeClassName="active-link"
                                                            exact={true}
                                                            onClick={modal.hide}>

                                                            Account Info
                                                        </NavLink>
                                                    </div> :
                                                    null}
                                                {(owner && !business) ?
                                                    <div className="flex x-end">
                                                        <NavLink
                                                            className="nav-link side-link"
                                                            key="account-cancel"
                                                            to={$.url('account:cancel').url}
                                                            activeClassName="active-link"
                                                            exact={true}
                                                            onClick={modal.hide}>

                                                            Cancel Service
                                                        </NavLink>
                                                    </div> :
                                                    null}
                                                {((owner || billing) && business) ?
                                                    <div className="flex x-end">
                                                        <NavLink
                                                            className="nav-link side-link"
                                                            key="account-make-payment"
                                                            to={$.url('account:make-payment').url}
                                                            activeClassName="active-link"
                                                            exact={true}
                                                            onClick={modal.hide}>

                                                            Make a Payment
                                                        </NavLink>
                                                    </div> :
                                                    null}
                                                {(owner || billing) ?
                                                    <div className="flex x-end">
                                                        <NavLink
                                                            className="nav-link side-link"
                                                            key="account-invoices"
                                                            to={$.url('account:invoices').url}
                                                            activeClassName="active-link"
                                                            exact={true}
                                                            onClick={modal.hide}>

                                                            Orders and Invoices
                                                        </NavLink>
                                                    </div> :
                                                    null}
                                                {owner ?
                                                    <div className="flex x-end">
                                                        <NavLink
                                                            className="nav-link side-link"
                                                            key="account-returns"
                                                            to={$.url('account:returns').url}
                                                            activeClassName="active-link"
                                                            exact={true}
                                                            onClick={modal.hide}>

                                                            Returns
                                                        </NavLink>
                                                    </div> :
                                                    null}
                                                <div className="flex x-end">
                                                    <NavLink
                                                        className="nav-link side-link"
                                                        key="account-profile"
                                                        to={$.url('account:update-profile').url}
                                                        activeClassName="active-link"
                                                        exact={true}
                                                        onClick={modal.hide}>

                                                        Profile
                                                    </NavLink>
                                                </div>
                                                {canView ?
                                                    <div className="flex x-end">
                                                        <NavLink
                                                            className="nav-link side-link"
                                                            key="account-password"
                                                            to={$.url('account:update-password').url}
                                                            activeClassName="active-link"
                                                            exact={true}
                                                            onClick={modal.hide}>

                                                            Password
                                                        </NavLink>
                                                    </div> :
                                                    null}
                                                {(owner || billing) ?
                                                    <div className="flex x-end">
                                                        <NavLink
                                                            className="nav-link side-link"
                                                            key="account-payment"
                                                            to={$.url('account:update-payment').url}
                                                            activeClassName="active-link"
                                                            exact={true}
                                                            onClick={modal.hide}>

                                                            Payment
                                                        </NavLink>
                                                    </div> :
                                                    null}
                                                {owner ?
                                                    <div className="flex x-end">
                                                        <NavLink
                                                            className="nav-link side-link"
                                                            key="account-privacy"
                                                            to={$.url('account:update-privacy').url}
                                                            activeClassName="active-link"
                                                            exact={true}
                                                            onClick={modal.hide}>

                                                            Privacy settings
                                                        </NavLink>
                                                    </div> :
                                                    null}
                                                {business && purchaser && owner ?
                                                    <div className="flex x-end">
                                                        <NavLink
                                                            className="nav-link side-link"
                                                            key="account-purchase"
                                                            to={$.url('account:purchase').url}
                                                            activeClassName="active-link"
                                                            exact={true}
                                                            onClick={modal.hide}>

                                                            Purchase accessories
                                                        </NavLink>
                                                    </div> :
                                                    null}
                                                {business ?
                                                    <div className="flex x-end">
                                                        <NavLink
                                                            className="nav-link side-link"
                                                            key="account-support"
                                                            to={$.url('account:support').url}
                                                            activeClassName="active-link"
                                                            exact={true}
                                                            onClick={modal.hide}>

                                                            Support
                                                        </NavLink>
                                                    </div> :
                                                    null}
                                            </div>
                                            <div className="margin-top-2">
                                                <LogoutButton />
                                            </div>
                                            {(business && !billing) ?
                                                <div className="flex x-center padding-top-1 padding-bottom-1">
                                                    <Anchor
                                                        href={env.dash.url}
                                                        setting="body"
                                                        color={colors.teal}
                                                        external={true}
                                                        onClick={modal.hide}>Log In to My Relay Dashboard</Anchor>
                                                </div> :
                                                null}
                                        </> :
                                        null}
                                </div>
                            </div>
                        </ScrollContext>
                    </div>
                </>))));
