import PropTypes from 'prop-types';
import React, {Component, createElement} from 'react';
import {Helmet} from 'react-helmet-async';
import {scrollLeft, toXY} from '@republic/foundation/browser/scroll';
import {map} from '@republic/foundation/lang/array';
import {isFunction} from '@republic/foundation/lang/is';
import {createComponent, createEnhancer} from '@republic/react-foundation';
import {withMeta} from '@republic/spellcaster/components';
import env from '../../env';

const
    resolve = (value, props) => (
        isFunction(value) ?
            value(props) :
            value);

export default (
    (title, description, meta, ...tags) => (
        createEnhancer(
            Wrapped => (
                withMeta('meta')(
                    createComponent(
                        `Page(${
                            Wrapped.displayName ||
                            Wrapped.name ||
                            'Component'
                        })`,
                        {
                            propTypes: {
                                meta: PropTypes.object
                            }
                        },
                        class Page extends Component {
                            componentDidMount() {
                                toXY(scrollLeft(), 0);
                            }

                            render() {
                                const
                                    {props} = this,
                                    config = {
                                        ...props.meta,
                                        ...resolve(meta, props) || {}
                                    };

                                return (
                                    <>
                                        {createElement(
                                            Helmet,
                                            {},
                                            <title>{resolve(title, props)}</title>,
                                            <meta name="description" content={resolve(description, props) || ''} />,
                                            <meta
                                                name="robots"
                                                content={
                                                    [
                                                        config.noindex ? 'noindex' : 'index',
                                                        config.nofollow ? 'nofollow' : 'follow'
                                                    ]
                                                    .join(', ')} />,
                                            <link rel="canonical" href={`https://${env.relay.domain}${config.canonical}`} />,
                                            ...map(tags, tag => resolve(tag, props)))}
                                        <Wrapped {...this.props} />
                                    </>);
                            }
                        }))))));
