import {reduce} from '@republic/foundation/lang/array';
import {keys} from '@republic/foundation/lang/object';

const
    limits = {
        products: 100,
        plans: 1000
    },

    products = (cart, sku = null) => (
        reduce(
            [...cart.devices, ...cart.accessories],
            (total, {items}) => (
                reduce(
                    keys(items),
                    (total, item) => (
                        !sku || item !== sku ?
                            total + items[item].quantity :
                            total),
                    total)),
            0)),

    plans = cart => (
        reduce(
            cart.contract,
            (max, {items}) => (
                reduce(
                    keys(items),
                    (max, sku) => (
                        Math.max(items[sku].quantity, max)),
                    max)),
            0)),

    constraints = (product, offer, cart) => {
        const {min = null, max = null} = offer || {};

        if (product.model === 'service_plan') {
            return {
                min,
                max: (
                    Math.min(
                        limits.plans,
                        max === null ? limits.plans : max))
            };
        } else {
            const remaining = limits.products - products(cart, product.sku);

            return {
                min,
                max: (
                    Math.min(
                        remaining,
                        max === null ? remaining : max))
            };
        }
    },

    constrained = cart => ({
        products: products(cart) >= limits.products,
        plans: plans(cart) >= limits.plans
    });

export {
    constraints,
    constrained
};
