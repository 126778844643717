import {each, map, mapfind, reduce, sorted} from '@republic/foundation/lang/array';
import {identity} from '@republic/foundation/lang/function';
import {keys, owns} from '@republic/foundation/lang/object';
import {createNamed} from '@republic/foundation/storage';
import local from '../../core/services/storage/local';
import ErrorsStream from '../../core/streams/ErrorsStream';

const
    errors = ErrorsStream.provide,

    storage = createNamed(local, 'cart-business/checkout', 2 * 60 * 60),

    prefix = 'checkout',

    steps = {
        account: `${prefix}/account`,
        shipping: `${prefix}/shipping`,
        delivery: `${prefix}/delivery`,
        billing: `${prefix}/billing`,
        payment: `${prefix}/payment`,
        review: `${prefix}/review`,
        redemptions: `${prefix}/redemptions`
    },

    formatLines = (lines, offers, selector = identity) => {
        const
            {offer, items} = (
                mapfind(
                    selector(offers.raw),
                    offer => {
                        const
                            components = (
                                reduce(
                                    offer.offer_components,
                                    (components, component) => {
                                        const
                                            items = (
                                                reduce(
                                                    component.offer_items,
                                                    (items, item) => {
                                                        const
                                                            {quantity} = (
                                                                mapfind(
                                                                    lines,
                                                                    ({items}) => {
                                                                        if (owns(items, item.item.sku)) {
                                                                            return items[item.item.sku];
                                                                        }
                                                                    }) ||
                                                                {});

                                                        if (quantity) {
                                                            items.push({item, quantity});
                                                        }
                                                        return items;
                                                    },
                                                    []));

                                        if (items.length) {
                                            components.push(items);
                                        }
                                        return components;
                                    },
                                    []));

                        if (components.length === offer.offer_components.length) {
                            return {
                                offer,
                                items: [].concat(...components)
                            };
                        }
                    }) ||
                {});

        return (
            (offer && items) ?
                [{offer, items}] :
                []);
    },

    withFormatter = (productOffers, planOffers, deliveryOffers) => {
        let formatted = [];
        const
            formatter = {
                contract: cart => {
                    if (cart.contract.length && planOffers) {
                        formatted = [
                            ...formatted,
                            ...formatLines(cart.contract, planOffers)
                        ];
                    }
                    return formatter;
                },

                devices: cart => {
                    if (cart.devices.length && productOffers) {
                        formatted = [
                            ...formatted,
                            ...formatLines(cart.devices, productOffers)
                        ];
                    }
                    return formatter;
                },

                accessories: cart => {
                    if (cart.accessories.length && productOffers) {
                        formatted = [
                            ...formatted,
                            ...formatLines(cart.accessories, productOffers)
                        ];
                    }
                    return formatter;
                },

                delivery: sku => {
                    if (sku && deliveryOffers) {
                        formatted = [
                            ...formatted,
                            ...formatLines(
                                [{items: {[sku]: {quantity: 1}}}],
                                deliveryOffers,
                                ({shipping_offer}) => [shipping_offer])
                        ];
                    }
                    return formatter;
                }
            };

        return format => {
            format(formatter);
            return formatted;
        };
    },

    serialize = (format, productOffers, planOffers, deliveryOffers) => (
        map(withFormatter(
                productOffers,
                planOffers,
                deliveryOffers)(
                    format),
            ({offer, items}, i) => ({
                metadata: String(i),
                offer: {ref_id: offer.ref_id},
                order_items: (
                    map(items,
                        ({item, quantity}) => ({
                            offer_item: {id: item.id},
                            quantity
                        })))
            }))),

    parseOrder = (order, catalog) => {
        const
            {payment_period_in_months: period = 1} = order,
            {
                original,
                discounts,
                total,
                lines
            } = (
                reduce(
                    reduce(
                        order.order_lines,
                        (lines, {order_items: items, metadata: ordering}) => {
                            if (ordering) {
                                lines.push({
                                    items: (
                                        reduce(
                                            items,
                                            (preview, item) => {
                                                const {by, items} = preview;

                                                if (item.item_to_discount) {
                                                    by[item.item_to_discount.uuid].discounts.push(item);
                                                } else {
                                                    items.push(by[item.uuid]);
                                                }
                                                return preview;
                                            },
                                            {
                                                by: (
                                                    reduce(
                                                        items,
                                                        (by, item) => {
                                                            by[item.uuid] = {
                                                                ...item,
                                                                discounts: []
                                                            };
                                                            return by;
                                                        },
                                                        {})),
                                                items: []
                                            })).items,
                                    ordering: parseInt(ordering, 10)
                                });
                            }
                            return lines;
                        },
                        []),
                (preview, {items, ordering}) => (
                    reduce(
                        items,
                        (preview,
                        {
                            item: {sku},
                            quantity,
                            one_time_charge_total: price_once,
                            tax_total: tax_once,
                            estimated_recurring_charge: price_recurring,
                            estimated_recurring_tax_charge: tax_recurring,
                            order_item_taxes: taxes,
                            discounts
                        },
                        index) => {
                            const
                                recurring = (catalog.data.products[sku] || {}).model === 'service_plan',
                                price = (
                                    recurring ?
                                        price_recurring :
                                        (price_once - tax_once)),
                                tax = (
                                    recurring ?
                                        tax_recurring :
                                        tax_once),
                                by = (
                                    reduce(
                                        taxes,
                                        (by, {name, level, pcode, amount}) => {
                                            by[`${level}:${pcode}:${name}`] = {
                                                name,
                                                amount,
                                                discount: 0,
                                                total: amount
                                            };
                                            return by;
                                        },
                                        {})),
                                line = {
                                    ordering,
                                    index,
                                    sku,
                                    recurring,
                                    quantity,
                                    original: {
                                        price,
                                        tax
                                    },
                                    discounts: {
                                        price: 0,
                                        tax: 0
                                    },
                                    total: {
                                        price,
                                        tax
                                    },
                                    taxes: (
                                        map(sorted(
                                                keys(by),
                                                (left, right) => (
                                                    by[left].name < by[right].name ?
                                                        -1 :
                                                        by[left].name > by[right].name ?
                                                            1 :
                                                            0)),
                                            code => by[code]))
                                };

                            each(
                                discounts || [],
                                ({
                                    one_time_charge_total: price_once,
                                    tax_total: tax_once,
                                    estimated_recurring_charge: price_recurring,
                                    estimated_recurring_tax_charge: tax_recurring
                                }) => {
                                    const
                                        price = (
                                            recurring ?
                                                price_recurring :
                                                (price_once - tax_once)),
                                        tax = (
                                            recurring ?
                                                tax_recurring :
                                                tax_once);

                                    line.discounts.price -= price;
                                    line.discounts.tax -= tax;
                                    line.total.price += price;
                                    line.total.tax += tax;
                                    each(taxes, ({name, level, pcode, amount}) => {
                                        const code = `${level}:${pcode}:${name}`;

                                        if (owns(by, code)) {
                                            by[code].discount -= amount;
                                            by[code].total += amount;
                                        }
                                    });
                                });
                            if (recurring) {
                                preview.original.price.recurring += line.original.price;
                                preview.original.tax.recurring += line.original.tax;
                                preview.discounts.price.recurring += line.discounts.price;
                                preview.discounts.tax.recurring += line.discounts.tax;
                                preview.total.price.recurring += line.total.price;
                                preview.total.tax.recurring += line.total.tax;
                            } else {
                                preview.original.price.once += line.original.price;
                                preview.original.tax.once += line.original.tax;
                                preview.discounts.price.once += line.discounts.price;
                                preview.discounts.tax.once += line.discounts.tax;
                                preview.total.price.once += line.total.price;
                                preview.total.tax.once += line.total.tax;
                            }
                            preview.lines.push(line);
                            return preview;
                        },
                        preview)),
                {
                    original: {
                        price: {
                            recurring: 0,
                            once: 0
                        },
                        tax: {
                            recurring: 0,
                            once: 0
                        }
                    },
                    discounts: {
                        price: {
                            recurring: 0,
                            once: 0
                        },
                        tax: {
                            recurring: 0,
                            once: 0
                        }
                    },
                    total: {
                        price: {
                            recurring: 0,
                            once: 0
                        },
                        tax: {
                            recurring: 0,
                            once: 0
                        }
                    },
                    lines: []
                }));

        return {
            annual: period === 12,
            original,
            discounts,
            total,
            lines: (
                sorted(
                    lines,
                    (left, right) => (
                        (left.ordering - right.ordering) ||
                        (left.index - right.index))))
        };
    },

    parse = (preview, catalog) => {
        const
            {
                unaccepted_terms: terms = [],
                rejected_redemptions: rejected = []
            } = preview;

        return {
            ...parseOrder(preview, catalog),
            terms: (
                map(terms,
                    ({name, version_number: version}) => ({
                        name,
                        version
                    }))),
            coupons: (
                reduce(
                    [
                        ...map(
                            rejected,
                            ({
                                input_code: code,
                                description = '',
                                reason = '',
                                implicit = false
                            }) => ({
                                code,
                                description,
                                reason,
                                implicit,
                                rejected: true
                            })),
                        ...reduce(
                            preview.order_lines,
                            (coupons, {order_items: items}) =>  (
                                reduce(
                                    items,
                                    (coupons,
                                    {
                                        item_to_discount: isDiscount,
                                        order_item_redemptions: redemptions = []
                                    }) => (
                                        (!isDiscount && redemptions.length) ?
                                            [
                                                ...coupons,
                                                ...map(
                                                    redemptions,
                                                    ({
                                                        input_code: code,
                                                        coupon: {
                                                            description = '',
                                                            reason = '',
                                                            implicit = false
                                                        } = {}
                                                    }) => ({
                                                        code,
                                                        description,
                                                        reason,
                                                        implicit,
                                                        rejected: false
                                                    }))
                                            ] :
                                            coupons),
                                    coupons)),
                            [])
                    ],
                    (collection, coupon) => {
                        const
                            {codes, coupons} = collection,
                            {code} = coupon;

                        if (!owns(codes, code)) {
                            codes[code] = true;
                            coupons.push(coupon);
                        }
                        return collection;
                    },
                    {
                        codes: {},
                        coupons: []
                    })
                .coupons)
        };
    },

    parseTransaction = (transaction, catalog) => {
        const {order_number: id} = transaction;

        return {
            id,
            ...parseOrder(transaction, catalog)
        };
    },

    checkout = (
        (auth, catalog, productOffers, planOffers, deliveryOffers, cart, preview, account, shipping, delivery, billing, payment, redemptions) => (
            fetch(
                // NOTE (ljames): You can mock a submit by changing 'orders' to 'order_previewer'
                // and passing '@id' for 'order_number' in the response data.
                `/fractus/${(auth && auth.token) ? 'p' : 'u'}/stratus/rest/v3/orders`,
                {
                    method: 'POST',
                    body: (
                        JSON.stringify({
                            payment_period_in_months: (
                                cart.payment.annual ?
                                    12 :
                                    1),
                            order_lines: (
                                serialize(
                                    format => (
                                        format
                                        .contract(cart)
                                        .devices(cart)
                                        .accessories(cart)
                                        .delivery(delivery && delivery.delivery)),
                                    productOffers,
                                    planOffers,
                                    deliveryOffers)),
                            submitter: {
                                first_name: account.first_name,
                                last_name: account.last_name,
                                email: account.email,
                                phone: account.phone.replace(/[^\d]/g, ''),
                                ...(account.password && account.password_repeat) ?
                                    {
                                        password: account.password,
                                        password_repeat: account.password_repeat
                                    } :
                                    {}
                            },
                            ...shipping ? {
                                    shipping_location: {
                                        address_1: shipping.address1,
                                        address_2: shipping.address2 || null,
                                        city: shipping.city,
                                        state: shipping.state,
                                        zip_code: shipping.zip
                                    }
                                } :
                                {},
                            billing_location: {
                                address_1: billing.address1,
                                address_2: billing.address2 || null,
                                city: billing.city,
                                state: billing.state,
                                zip_code: billing.zip
                            },
                            ...payment ? {payment_method_nonce: payment} : {},
                            redemptions,
                            terms: preview.data.terms,
                            metadata: {
                                company_name: account.company_name,
                                channel: 'Relay Pro Portal Store'
                                // HACK (ccunningham): For now we can hard code this because it is the only channel we're selling in
                                // If we want to bring back the Web Store, we need to figure out how to do this.
                                // Potential issue if they're allowed to order from multiple places in the future
                                // channel: 'Relay Pro Web Store'
                            },
                            b2b: true,
                            // HACK (ccunningham): hard coding to false for now.
                            // We need future use cases to determine if or when to set to true
                            update_default_billing: false,
                            ...cart.devices.length ? {activate: true} : {}
                        })),
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        ...(auth && auth.token) ?
                            {Authorization: `Bearer ${auth.token}`} :
                            {}
                    }
                })
                .then(response => {
                    if (response.ok) {
                        return (
                            response.json()
                            .then(
                                data => (
                                    storage.set(
                                        parseTransaction(
                                            data,
                                            catalog)))));
                    } else {
                        if (response.status === 503) {
                            errors.display(
                                'maintenance',
                                'Our site is going down for for maintenance',
                                'Refresh the page',
                                () => {
                                    window.location.reload(true);
                                });
                        } else if (response.status === 403) {
                            errors.display(
                                'cart-business/checkout/error',
                                'You do not have permission to purchase with this account. Please log out ' +
                                'and ask the account owner to log in to complete this purchase.',
                                'OK');
                        }
                        return Promise.reject();
                    }
                })
                .catch(() => (
                    Promise.reject(
                        'The order request failed. Please try again!')))));

export {
    storage,
    steps,
    serialize,
    parse,
    checkout
};
