import React, {Component} from 'react';
import {scrollSubscribe} from '@republic/foundation/browser/scroll';
import {createComponent} from '@republic/react-foundation';

export default (
    createComponent(
        'ScrollUpShow',
        {},
        class ScrollUpShow extends Component {
            constructor(props) {
                super(props);
                this.state = {
                    previous: 0,
                    show: true
                };
            }

            track = track => {
                if (track) {
                    if (!this.cancelScroll) {
                        this.cancelScroll = scrollSubscribe.updated(this.update);
                    }
                } else {
                    if (this.cancelScroll) {
                        this.cancelScroll();
                        this.cancelScroll = null;
                    }
                }
            }

            update = (coords) => {
                const {previous, show} = this.state;

                if ((previous > coords.top || coords.top <= this.props.offset || previous === 0) && !show) {
                    this.setState({show: true});
                } else if (previous < coords.top && coords.top > this.props.offset && show) {
                    this.setState({show: false});
                }

                this.setState({previous: coords.top});
            }

            componentDidMount() {
                this.track(true);
            }

            componentWillUnmount() {
                this.track(false);
            }

            render() {
                const
                    {show} = this.state,
                    {children} = this.props;

                return (
                    <div className={`${show ? 'scrolling-up' : 'scrolling-down'}`}>
                        {children}
                    </div>);
            }
        }));
