import PropTypes from 'prop-types';
import React from 'react';
import {compose} from '@republic/foundation/lang/function';
import {createComponent, use} from '@republic/react-foundation';
import AuthStream from '../../auth/streams/AuthStream';
import BusinessCartToggleOpen from '../../cart-business/components/BusinessCartToggleOpen';

export default (
    compose(
        use({authenticated: AuthStream.as(auth => auth.map(auth => !!(auth && auth.id)))}))(
        createComponent(
            'CartToggleOpen',
            {
                propTypes: {
                    authenticated: PropTypes.bool.isRequired
                }
            },
            ({authenticated}) => (
                authenticated ?
                    <BusinessCartToggleOpen /> :
                    null))));
