export default {
    white: '#FFFFFF',
    white_gray: '#F7F7F7',
    light_gray: '#EEEEEE',
    medium_light_gray: '#D4D4D4',
    gray: '#A1A1A1',
    medium_dark_gray: '#BABABA',
    dark_gray: '#545454',
    black_gray: '#3B3B3B',
    black: '#222222',
    orange: '#F19552',
    pink: '#DD5097',
    purple: '#7A48BD',
    red: '#CD2246',
    product_black: '#1D252D',
    product_blue: '#00A3E0',
    product_gray: '#D0D0CE',
    product_red: '#E63422',
    product_teal: '#64CCC9',
    another_teal: '#6eb4b0',
    seniors_orange: '#F17822',
    seniors_orange_cta: '#F19552',
    blue: '#032B3C',
    teal: '#1DB1BA',
    dark: '#1F2429',
    gray_dark: '#4F5B67',
    gray_4: '#8B99A7',
    gray_3: '#CDD3D9',
    gray_2: '#EEF0F2',
    gray_1: '#FAFAFA',
    border_gray: '#707070',
    accent_pink: '#FC0B6E',
    accent_red: '#ED260E',
    accent_yellow: '#F7C232',
    accent_blue: '#0E6DED',
    accent_orange: '#F75231'
};
// 2019 enterprise redesign's colors start after 'seniors_orange_cta'