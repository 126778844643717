import {createComponent, use} from '@republic/react-foundation';
import AuthStream from '../streams/AuthStream';

const
    {
        authenticate,
        deauthenticate
    } = AuthStream.provide;

export default (
    use({
            authorizing: (
                AuthStream.as(
                    auth => (
                        auth.map(
                            auth => (!!(
                                auth &&
                                auth.authorization &&
                                auth.state === AuthStream.states.unauthenticated)))
                        .unique())))
        },
        ({authorizing}) => {
            if (authorizing) {
                authenticate();
            } else {
                deauthenticate();
            }
            return null;
        })(
        createComponent(
            'AuthLoginHandler',
            {},
            ({children}) => children)));
