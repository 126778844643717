import PropTypes from 'prop-types';
import {all, each, index, reduce} from '@republic/foundation/lang/array';
import {update} from '@republic/foundation/lang/collection';
import {isEqual} from '@republic/foundation/lang/is';
import {get, keys, owns} from '@republic/foundation/lang/object';
import {createNamed} from '@republic/foundation/storage';
import {createStream, createStreamContext} from '@republic/react-foundation';
import AuthStream from '../../auth/streams/AuthStream';
import {cartAdd, cartRemove} from '../../core/services/analytics';
import local from '../../core/services/storage/local';
import CatalogStream from '../../products/streams/CatalogStream';
import OffersStream from '../../products/streams/OffersStream';
import PlanOffersStream from '../../products/streams/PlanOffersStream';
import {constraints} from '../services/cart';

const
    storage = createNamed(local, 'cart-business/cart'),

    clear = () => {
        storage.clear();
    },

    itemsEqual = (next, previous) => {
        const
            nextKeys = keys(next),
            previousKeys = keys(previous);

        return (
            nextKeys.length === previousKeys.length &&
            all(nextKeys,
                key => (
                    owns(previous, key) &&
                    isEqual(next[key], previous[key]))));
    },

    linesEqual = (next, previous) => (
        next.family === previous.family &&
        itemsEqual(next.items, previous.items)),

    typeEqual = (next, previous) => (
        next.length === previous.length &&
        all(next,
            (next, i) => (
                linesEqual(next, previous[i])))),

    cartEqual = (next, previous) => (
        isEqual(next.payment, previous.payment) &&
        typeEqual(next.devices, previous.devices) &&
        typeEqual(next.accessories, previous.accessories) &&
        typeEqual(next.contract, previous.contract)),

    initialize = () => ({
        payment: {
            annual: true
        },
        contract: [],
        devices: [],
        accessories: []
    }),

    constrain = (quantity, {min, max}) => (
        Math.min(
            Math.max(
                min !== null ? min : quantity,
                quantity),
            max !== null ? max : quantity,
            quantity)),

    reconcile = (cart, lines, catalog, offers) => (
        reduce(
            lines,
            (lines, {family, items}) => {
                const
                    next = (
                        reduce(
                            keys(items),
                            (next, sku) => {
                                const
                                    quantity = items[sku].quantity,
                                    product = catalog.data.products[sku],
                                    offer = offers.data[sku];

                                if (product && product.marketed && product.family.slug === family &&
                                    offer && offer.available >= 0) {

                                    next[sku] = {
                                        quantity: constrain(quantity, constraints(product, offer, cart))
                                    };
                                }
                                return next;
                            },
                            {}));

                if (keys(next).length) {
                    lines.push({
                        family,
                        items: next
                    });
                }
                return lines;
            },
            [])),

    report = (next, previous, type, catalog, offers) => {
        each(
            previous[type],
            ({family, items}) => {
                const line = index(next[type], line => line.family === family);

                each(
                    keys(items),
                    sku => {
                        if (line < 0 || !owns(next[type][line].items, sku)) {
                            const product = catalog.data.products[sku];

                            cartRemove(
                                sku,
                                catalog,
                                offers,
                                0,
                                product.model === 'service_plan' ?
                                    {payment: previous.payment.annual ? 'annual' : 'monthly'} :
                                    {});
                        }
                    });
            });
        each(
            next[type],
            ({family, items}) => {
                const line = index(previous[type], line => line.family === family);

                each(
                    keys(items),
                    sku => {
                        const
                            product = catalog.data.products[sku],
                            before = get(previous[type], line, 'items', sku, 'quantity') || 0,
                            quantity = items[sku].quantity;

                        if (quantity > before) {
                            cartAdd(
                                sku,
                                catalog,
                                offers,
                                quantity,
                                product.model === 'service_plan' ?
                                    {payment: next.payment.annual ? 'annual' : 'monthly'} :
                                    {});
                        } else if (quantity < before) {
                            cartRemove(
                                sku,
                                catalog,
                                offers,
                                quantity,
                                product.model === 'service_plan' ?
                                    {payment: previous.payment.annual ? 'annual' : 'monthly'} :
                                    {});
                        }
                    });
            });
        return next;
    },

    reporter = (type, update) => (
        (cart, data, dependencies) => (
            report(
                update(cart, data, dependencies),
                cart,
                type,
                dependencies.catalog,
                dependencies[type === 'contract' ? 'plans' : 'offers']))),

    updater = type => (
        (cart, {sku, quantity}, {catalog, [type === 'contract' ? 'plans' : 'offers']: offers}) => {
            if (catalog && catalog.data &&
                offers && offers.data) {

                const
                    product = catalog.data.products[sku],
                    offer = offers.data[sku];

                if (product && product.marketed &&
                    offer && offer.available >= 0) {

                    const
                        family = product.family.slug,
                        line = index(cart[type], line => line.family === family),
                        next = constrain(quantity, constraints(product, offer, cart));

                    return (
                        line >= 0 ?
                            (next > 0 ?
                                storage.set(
                                    update(cart, type, line, 'items', sku)({
                                        quantity: next
                                    })) :
                                (owns(cart[type][line].items, sku) ?
                                    storage.set(
                                        update(cart, type)(
                                            lines => (
                                                keys(lines[line].items).length > 1 ?
                                                    update(lines, line, 'items')(
                                                        ({[sku]: remove, ...items}) => (
                                                            items)) :
                                                    [
                                                        ...lines.slice(0, line),
                                                        ...lines.slice(line + 1)
                                                    ]))) :
                                    cart)) :
                            (next > 0 ?
                                storage.set(
                                    update(cart, type)(
                                        lines => ([
                                            ...lines,
                                            {
                                                family,
                                                items: {
                                                    [sku]: {
                                                        quantity: next
                                                    }
                                                }
                                            }
                                        ]))) :
                                cart));
                } else {
                    return cart;
                }
            } else {
                return cart;
            }
        }),

    reducer = type => reporter(type, updater(type));

export default (
    createStream(
        'BusinessCartStream',
        {singleton: true},
        createStreamContext(
            'BusinessCartContext',
            {
                payment: PropTypes.func.isRequired,
                plan: PropTypes.func.isRequired,
                device: PropTypes.func.isRequired,
                accessory: PropTypes.func.isRequired,
                clear: PropTypes.func.isRequired
            }),
        {
            auth: AuthStream,
            catalog: CatalogStream,
            offers: OffersStream,
            plans: PlanOffersStream
        },
        {
            payment: annual => ({annual}),
            plan: (sku, quantity) => ({sku, quantity}),
            device: (sku, quantity) => ({sku, quantity}),
            devicePlan: (deviceSku, planSku, quantity) => ({deviceSku, planSku, quantity}),
            accessory: (sku, quantity) => ({sku, quantity}),
            bundle: bundled => bundled
        },
        methods => ({
            ...methods,
            bundled: {
                payment: annual => ({type: 'payment', annual}),
                plan: (sku, quantity) => ({type: 'contract', sku, quantity}),
                device: (sku, quantity) => ({type: 'devices', sku, quantity}),
                accessory: (sku, quantity) => ({type: 'accessories', sku, quantity})
            },
            clear
        }),
        () => ({
            ...initialize(),
            ...storage.get()
        }),
        // on => on.dependencies((cart, {auth, catalog, offers, plans}) => {
        //     if (catalog && catalog.data &&
        //         offers && offers.data && (
        //             (auth.state === AuthStream.states.unauthenticated && plans && plans.data) ||
        //             (auth.state !== AuthStream.states.unauthenticated && !plans))) {
        //
        //         const
        //             next = {
        //                 ...cart,
        //                 contract: (
        //                     plans ?
        //                         reconcile(cart, cart.contract, catalog, plans) :
        //                         []),
        //                 devices: reconcile(cart, cart.devices, catalog, offers),
        //                 accessories: reconcile(cart, cart.accessories, catalog, offers)
        //             };
        //
        //         return (
        //             !cartEqual(next, cart) ?
        //                 storage.set(next) :
        //                 cart);
        //     } else {
        //         return cart;
        //     }
        // }),
        on => on.dependencies((cart, {auth, catalog, offers, plans}) => {
            if (catalog && catalog.data &&
                offers && offers.data && (
                    (auth.state === AuthStream.states.unauthenticated && plans && plans.data) ||
                    (auth.state !== AuthStream.states.unauthenticated && !plans))) {

                const
                    devices = (
                        auth.state === AuthStream.states.unauthenticated ?
                            reconcile(cart, cart.devices, catalog, offers) :
                            []),
                    accessories = reconcile(cart, cart.accessories, catalog, offers),
                    // HACK (mkibbel): This hack restores plan quantity for unauthenticated users.
                    contract = (
                        (plans && devices.length) ?
                            [
                                {
                                    family: 'relay-service-plan',
                                    items: {
                                        [catalog.data.families['relay-service-plan'].skus[0]]: {
                                            quantity: (
                                                reduce(
                                                    devices,
                                                    (quantity, {family, items}) => (
                                                        reduce(
                                                            keys(items),
                                                            (quantity, sku) => (
                                                                quantity + items[sku].quantity),
                                                            quantity)),
                                                    1))
                                        }
                                    }
                                }
                            ] :
                            []),
                    next = {
                        ...cart,
                        contract,
                        devices,
                        accessories
                    };

                return (
                    !cartEqual(next, cart) ?
                        storage.set(next) :
                        cart);
            } else {
                return cart;
            }
        }),
        (on, {payment}) => on(payment.stream, (cart, {annual}, {plans}) => (
            // TODO (mkibbel): We should fire remove/add analytics for plans in the cart.
            (plans && plans.data &&
            all(plans.data, offer => offer.type === 'add' &&
            cart.payment.annual !== !!annual)) ?
                storage.set(
                    update(cart, 'payment', 'annual')(
                        !!annual)) :
                cart)),
        (on, {plan}) => on(plan.stream, reducer('contract')),
        (on, {device}) => on(device.stream, reducer('devices')),
        (on, {devicePlan}) => on(devicePlan.stream, (cart, {deviceSku, planSku, quantity}, dependencies) => (
            reducer('contract')(
                reducer('devices')(
                    cart,
                    {
                        sku: deviceSku,
                        quantity
                    },
                    dependencies),
                {
                    sku: planSku,
                    quantity: quantity > 0 ? quantity + 1 : 0
                },
                dependencies))),
        (on, {accessory}) => on(accessory.stream, reducer('accessories')),
        (on, {bundle}) => on(bundle.stream, (cart, bundled, {catalog, plans, offers}) => {
            if (catalog && catalog.data &&
                plans && plans.data &&
                offers && offers.data) {

                const
                    next = (
                        reduce(
                            bundled,
                            (next, {type, ...bundle}) => (
                                type === 'payment' ?
                                    ((plans && plans.data &&
                                    all(plans.data, offer => offer.type === 'add')) ?
                                        update(next, 'payment', 'annual')(
                                            bundle.annual) :
                                        next) :
                                    updater(type)(
                                        next,
                                        bundle,
                                        {catalog, plans, offers})),
                            initialize()));

                report(next, cart, 'contract', catalog, plans);
                report(next, cart, 'devices', catalog, offers);
                report(next, cart, 'accessories', catalog, offers);
                return storage.set(next);
            } else {
                return cart;
            }
        }),
        on => on(storage.cleared, cart => (
            (cart.contract.length || cart.devices.length || cart.accessories.length) ?
                {
                    ...cart,
                    contract: [],
                    devices: [],
                    accessories: []
                } :
                cart))));
