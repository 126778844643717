import PropTypes from 'prop-types';
import React from 'react';
import {compose} from '@republic/foundation/lang/function';
import {isFunction} from '@republic/foundation/lang/is';
import {createComponent, create, use} from '@republic/react-foundation';
import ModalStream from '../../core/streams/ModalStream';
import {modalType} from '../../core/types';

export default (
    compose(
        create(ModalStream, {name: 'account-menu'}),
        use({modal: ModalStream}))(
        createComponent(
            'AccountToggleOpen',
            {
                propTypes: {
                    modal: PropTypes.shape(modalType).isRequired,
                    className: PropTypes.string.isRequired,
                    opened: PropTypes.func,
                    closed: PropTypes.func
                },
                defaultProps: {
                    className: 'nav-link'
                }
            },
            ({modal, className, opened, closed}) => (
                <span className={className}>
                    {modal.shown ?
                        <span
                            className="flex y-center pointer desktop-nav-link"
                            onClick={() => {
                                modal.hide();
                                if (isFunction(closed)) {
                                    closed();
                                }
                            }}>

                            Account
                        </span> :
                        <span
                            className="flex y-center pointer desktop-nav-link"
                            onClick={() => {
                                modal.show();
                                if (isFunction(opened)) {
                                    opened();
                                }
                            }}>

                            Account
                        </span>}
                </span>))));
