import {reduce} from '@republic/foundation/lang/array';
import {createStream, createStreamContext} from '@republic/react-foundation';
import ErrorsStream from '../../core/streams/ErrorsStream';

const errors = ErrorsStream.provide;

export default (
    createStream(
        'CatalogStream',
        {singleton: true},
        createStreamContext('CatalogContext'),
        null,
        {
            success: data => ({data}),
            failure: error => ({error})
        },
        null,
        ({success, failure}) => {
            (import(
                /* webpackChunkName: "catalog" */
                '../spells/catalog.spell')
            .then(({default: data}) => {
                success(data);
            })
            .catch(() => {
                failure('The products catalog is stale');
            }));
            return {
                data: null,
                fetching: true
            };
        },
        (on, {success}) => on(success.stream, (catalog, {data: {products, families}}) => ({
            ...catalog,
            data: {
                products: (
                    reduce(
                        families,
                        (data, family) => (
                            reduce(
                                family.skus,
                                (data, sku) => {
                                    data[sku].family = family;
                                    return data;
                                },
                                data)),
                        reduce(
                            products,
                            (data, product) => {
                                data[product.sku] = product;
                                return data;
                            },
                            {}))),
                families: (
                    reduce(
                        families,
                        (data, family) => {
                            data[family.slug] = family;
                            return data;
                        },
                        {}))
            },
            fetching: false
        })),
        (on, {failure}) => on(failure.stream, (catalog, {error}) => {
            errors.display(
                'products/catalog/error',
                error,
                'Refresh the page',
                () => {
                    window.location.reload(true);
                });
            return {
                ...catalog,
                fetching: false
            };
        })));
