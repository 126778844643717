import PropTypes from 'prop-types';
import React from 'react';
import {createComponent} from '@republic/react-foundation';
import CopySettings from './CopySettings';

export default (
    createComponent(
        'Label',
        {
            propTypes: {
                align: PropTypes.string,
                color: PropTypes.string,
                decoration: PropTypes.string,
                lh: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                setting: PropTypes.oneOf(['button', 'button_no_transform', 'button_tiny', 'menu_expanded', 'menu_primary', 'menu_secondary', 'portal_tiny', 'portal_small', 'portal_medium', 'huge_label']).isRequired,
                spacing: PropTypes.string,
                tag: PropTypes.string,
                transform: PropTypes.string,
                weight: PropTypes.string,
                forLabel: PropTypes.string
            }
        },
        ({align, children, color, decoration, lh, setting, spacing, tag, transform, weight, forLabel}) => (
            <CopySettings
                setting={setting}
                type="label"
                {...align ? {align} : {}}
                {...color ? {color} : {}}
                {...decoration ? {decoration} : {}}
                {...lh ? {lh} : {}}
                {...spacing ? {spacing} : {}}
                {...tag ? {tag} : {}}
                {...transform ? {transform} : {}}
                {...weight ? {weight} : {}}
                {...forLabel ? {forLabel} : {}}>

                {children}
            </CopySettings>)));
