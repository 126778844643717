import PropTypes from 'prop-types';
import {createStream, createStreamContext} from '@republic/react-foundation';
import BusinessCartStream from './BusinessCartStream';
import BusinessContractRemovalStream from './BusinessContractRemovalStream';

export default (
    createStream(
        'BusinessCartModalStream',
        {singleton: true},
        createStreamContext(
            'BusinessCartModalContext',
            {
                expand: PropTypes.func.isRequired,
                contract: PropTypes.func.isRequired,
                hide: PropTypes.func.isRequired
            }),
        {
            cart: BusinessCartStream,
            removal: BusinessContractRemovalStream
        },
        {
            expand: () => {},
            contract: () => {},
            hide: () => {}
        },
        methods => methods,
        () => ({
            opened: false,
            expanded: false
        }),
        on => on.dependencies((modal, next, prev) => (
            (next.removal.removed && (!prev.removal || !prev.removal.removed)) ?
                {
                    ...modal,
                    opened: true,
                    expanded: true
                } :
                ((prev.cart && next.cart !== prev.cart) ?
                    ((!modal.opened && (
                        next.cart.contract.length ||
                        next.cart.devices.length ||
                        next.cart.accessories.length)) ?
                        {
                            ...modal,
                            opened: true
                        } :
                        modal) :
                    modal))),
        (on, {expand}) => on(expand.stream, (modal, ignore, {cart}) => (
            !modal.expanded ?
                {
                    ...modal,
                    opened: true,
                    expanded: true
                } :
                modal)),
        (on, {contract}) => on(contract.stream, (modal, ignore, {cart}) => (
            (!modal.opened || modal.expanded) ?
                {
                    ...modal,
                    opened: true,
                    expanded: false
                } :
                modal)),
        (on, {hide}) => on(hide.stream, modal => (
            modal.opened ?
                {
                    ...modal,
                    opened: false,
                    expanded: false
                } :
                modal))));
