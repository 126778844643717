import loaded from '@republic/foundation/browser/loaded';
import script from '@republic/foundation/http/script';
import {each} from '@republic/foundation/lang/array';
import {noop} from '@republic/foundation/lang/function';
import {get} from '@republic/foundation/lang/object';
import {createNamed} from '@republic/foundation/storage';
import env from '../../env';
import frame from './http/frame';
import local from './storage/local';

let
    ticketsCache = null,
    queue = [];

    window.ZohoHCAsapSettings = {
        hideLauncherIcon: true
    };

    window.ZohoHCAsapReady = cb => {
        if (cb) {
            queue.push(cb);
        }
        if (window.ZohoHCAsapReadyStatus) {
            each(queue, cb => cb());
            queue = [];
        }
    };

const
    storage = createNamed(local, 'core/zoho', 7 * 24 * 60 * 60),

    loadTickets = () => (
        ticketsCache || (
            ticketsCache = loaded.then(
                () => script('//desk.zoho.com/portal/api/web/inapp/402167000000412071?orgId=691275750'))
                .catch(noop))),

    openTickets = () => {
        window.ZohoHCAsapReady(() => {
            if (window.ZohoHCAsap) {
                window.ZohoHCAsap.Action('open', {tab: 'TICKETS'});
            }
        });
    },

    closeTickets = () => {
        window.ZohoHCAsapReady(() => {
            if (window.ZohoHCAsap) {
                window.ZohoHCAsap.UnInstall();
            }
            ticketsCache = null;
        });
    },

    submit = (name, id, data) => (
        frame(
            `/zoho/rwhome/form/${encodeURIComponent(name)}/formperma/${encodeURIComponent(id)}/htmlRecords/submit`,
            {
                ...data,
                ...get(storage.get(), 'zoho'),
                zf_redirect_url: `https://${env.relay.domain}/blank.html`
            })
        .then(() => {
            if (window.clearbit) {
                window.clearbit.identify(
                    data.Email,
                    {
                        'email': data.Email
                    });
            }
        })
        .catch(noop)
    ),
    track = (utm) => (storage.set(utm));

export {
    submit,
    track,
    loadTickets,
    openTickets,
    closeTickets
};
