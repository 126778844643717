import PropTypes from 'prop-types';
import {mapfind, reduce} from '@republic/foundation/lang/array';
import {noop} from '@republic/foundation/lang/function';
import {get, owns} from '@republic/foundation/lang/object';
import {createStream, createStreamContext, stream} from '@republic/react-foundation';
import CatalogStream from '../../products/streams/CatalogStream';
import OffersStream from '../../products/streams/OffersStream';
import UserStream from '../../users/streams/UserStream';
import {constraints} from '../services/cart';
import BusinessCartStream from './BusinessCartStream';

export default (
    createStream(
        'BusinessCartAccessoryLineStream',
        {
            enhance: (
                stream(
                    ['family', 'available'],
                    {
                        propTypes: {
                            family: PropTypes.string,
                            available: PropTypes.bool
                        }
                    }))
        },
        createStreamContext('BusinessCartAccessoryLineContext'),
        {
            catalog: CatalogStream,
            offers: OffersStream,
            user: UserStream,
            cart: BusinessCartStream,
            slug: ({family}) => family,
            available: ({available}) => available
        },
        null,
        null,
        null,
        (on, methods, {cart: {accessory}}) => on.dependencies((state, {catalog, offers, user, cart, slug, available}) => {
            const business = !(user && user.data) || !!user.data.business;

            if (slug && catalog && catalog.data) {
                const
                    family = catalog.data.families[slug],
                    items = (
                        reduce(
                            family ? family.skus : [],
                            (items, sku) => {
                                const product = catalog.data.products[sku] || null;

                                if (product && product.marketed) {
                                    const offer = get(offers, 'data', sku) || null;

                                    if (!available || (offer && offer.available >= 0)) {
                                        const
                                            enabled = !!(business && offer && offer.available >= 0),
                                            quantity = (
                                                mapfind(
                                                    cart.accessories,
                                                    ({family, items}) => {
                                                        if (family === slug && owns(items, sku)) {
                                                            return items[sku].quantity;
                                                        }
                                                    }) ||
                                                0),
                                            {min, max} = constraints(product, offer, cart),
                                            update = (
                                                enabled ?
                                                    next => {
                                                        if (next !== quantity) {
                                                            accessory(sku, next);
                                                        }
                                                    } :
                                                    noop);

                                        items[sku] = {
                                            enabled,
                                            quantity,
                                            min,
                                            max,
                                            update
                                        };
                                    }
                                }
                                return items;
                            },
                            {}));

                return (
                    family ?
                        {
                            family,
                            items
                        } :
                        null);
            } else {
                return null;
            }
        })));
