import colors from './colors';

export default {
    heading: {
        card_header: {
            align: 'center',
            color: colors.dark,
            lh: 1,
            size: 1.5,
            tag: 'h3',
            weight: 'bold'
        },
        case_studies: {
            align: 'left',
            color: colors.teal,
            lh: 1.3636,
            size: 1.375,
            tag: 'h2',
            weight: 'bold'
        },
        solutions: {
            align: 'left',
            color: colors.dark,
            lh: 1.186,
            size: 1.75,
            tag: 'h2',
            weight: 'bold'
        },
        large: {
            align: 'center',
            color: colors.dark,
            lh: 1,
            size: 2.5,
            tag: 'h2',
            weight: 'bold'
        },
        huge: {
            align: 'center',
            color: colors.dark,
            lh: 1,
            size: 3.125,
            tag: 'h2',
            weight: 'bold'
        },
        hulk: {
            align: 'center',
            color: colors.dark,
            lh: 1,
            size: 3.75,
            tag: 'h2',
            weight: 'bold'
        },
        subheader: {
            align: 'center',
            color: colors.dark,
            lh: 1.286,
            size: 1.75,
            tag: 'h3',
            weight: 'normal'
        },
        title: {
            align: 'center',
            color: colors.dark,
            lh: 1.125,
            size: 2,
            tag: 'h2',
            weight: 'bold'
        },
        small: {
            align: 'center',
            color: colors.dark,
            lh: 1.2727,
            size: 1.375,
            tag: 'h4',
            weight: 'bold'
        },
        tiny: {
            align: 'center',
            color: colors.dark,
            lh: 1.2857,
            size: 0.875,
            tag: 'h4',
            weight: 'bold'
        },
        portal_small: {
            size: 1.25,
            tag: 'h3'
        }
    },
    copy: {
        body: {
            align: 'left',
            color: colors.dark,
            lh: 1.375,
            size: 1,
            tag: 'p',
            weight: 'normal'
        },
        body2: {
            align: 'left',
            color: colors.gray_4,
            lh: 1.375,
            size: 1,
            tag: 'p',
            weight: 'normal'
        },
        case_studies_body: {
            align: 'left',
            color: colors.gray_4,
            lh: 1.444,
            size: 1.125,
            tag: 'p',
            weight: 'normal'
        },
        case_studies_pull_quote: {
            align: 'center',
            color: colors.teal,
            lh: 1.125,
            size: 2,
            tag: 'p',
            weight: 'bold'
        },
        medium_body: {
            align: 'left',
            color: colors.dark,
            lh: 1.2727,
            size: 1.25,
            tag: 'p',
            weight: 'normal'
        },
        large_body: {
            align: 'left',
            color: colors.dark,
            lh: 1.2727,
            size: 1.375,
            tag: 'p',
            weight: 'bold'
        },
        large_quote: {
            align: 'center',
            color: colors.dark,
            lh: 1.286,
            size: 1.75,
            tag: 'p',
            weight: 'bold'
        },
        portal_xlarge: {
            color: colors.dark,
            lh: 1.3,
            size: 1.25,
            tag: 'p',
            weight: 'bold'
        },
        quote: {
            align: 'left',
            colors: colors.dark,
            italic: true,
            lh: 1.3,
            size: 1.25,
            tag: 'p',
            weight: 'normal'
        },
        small: {
            align: 'left',
            color: colors.dark,
            lh: 1.2857,
            size: 0.875,
            tag: 'p',
            weight: 'normal'
        },
        tiny: {
            align: 'left',
            color: colors.dark,
            lh: 1.125,
            size: 0.75,
            tag: 'p',
            weight: 'normal'
        },
        teeny: {
            align: 'left',
            color: colors.dark,
            lh: 1,
            size: 0.5,
            tag: 'p',
            weight: 'normal'
        },
        footer_label: {
            align: 'left',
            color: colors.gray_4,
            lh: 1.875,
            size: 0.75,
            tag: 'p',
            weight: 'normal'
        },
        footer_link: {
            align: 'left',
            color: colors.gray_2,
            lh: 1.875,
            size: 0.875,
            tag: 'p',
            weight: 'normal'
        },
        huge: {
            align: 'left',
            color: colors.dark,
            lh: 1.2727,
            size: 2,
            tag: 'p',
            weight: 'bold'
        },
        navlink: {
            align: 'left',
            color: colors.dark,
            lh: 1.375,
            size: 1,
            tag: 'p',
            weight: 'normal',
            class: 'desktop-nav-link'
        }
    },
    label: {
        button: {
            align: 'center',
            color: colors.white,
            lh: 1.375,
            size: 1,
            spacing: 0.02,
            tag: 'span',
            transform: 'uppercase',
            weight: 'bold',
            class: 'button-nowrap'
        },
        button_no_transform: {
            align: 'center',
            color: colors.white,
            lh: 1.375,
            size: 1,
            spacing: 0.02,
            tag: 'span',
            weight: 'bold',
            class: 'button-nowrap'
        },
        button_tiny: {
            align: 'center',
            color: colors.white,
            lh: 1,
            size: 0.7,
            spacing: 0.02,
            tag: 'span',
            transform: 'uppercase',
            weight: 'bold',
            class: 'button-nowrap'
        },
        menu_expanded: {
            align: 'left',
            color: colors.gray_4,
            lh: 1,
            size: 1.125,
            tag: 'span',
            transform: false,
            weight: 'normal'
        },
        menu_primary: {
            align: 'left',
            color: colors.white,
            lh: 1,
            size: 1.25,
            tag: 'span',
            transform: 'capitalize',
            weight: 'normal'
        },
        menu_secondary: {
            align: 'left',
            color: colors.white,
            lh: 1,
            size: 1.125,
            tag: 'span',
            transform: false,
            weight: 'normal'
        },
        portal_tiny: {
            size: 0.625,
            tag: 'p'
        },
        portal_small: {
            size: 0.75,
            tag: 'p'
        },
        portal_medium: {
            size: 0.875,
            tag: 'p',
            weight: 'bold'
        },
        huge_label: {
            size: 2.5,
            tag: 'label',
            weight: 'bold'
        }
    }
};
