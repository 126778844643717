import PropTypes from 'prop-types';
import {noop} from '@republic/foundation/lang/function';
import {createStream, createStreamContext, stream} from '@republic/react-foundation';
import ModalsStream from './ModalsStream';

export default (
    createStream(
        'ModalStream',
        {
            enhance: (
                stream(
                    ['name'],
                    {
                        propTypes: {
                            name: PropTypes.string.isRequired
                        }
                    }))
        },
        createStreamContext('ModalContext'),
        {
            modals: ModalsStream,
            name: ({name}) => name
        },
        null,
        null,
        {
            shown: false,
            show: noop,
            hide: noop
        },
        (on, methods, {modals: {show, hide}}) => on.dependencies((modal, {modals, name}) => ({
            ...modal,
            shown: modals[name] || false,
            show: () => {
                show(name);
            },
            hide: () => {
                hide(name);
            }
        }))));
