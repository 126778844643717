import PropTypes from 'prop-types';
import React, {createElement, Component} from 'react';
import {animated} from '@republic/foundation/browser/execution';
import events from '@republic/foundation/events';
import {createComponent, createNamedContext} from '@republic/react-foundation';

const
    ScrollContext = (
        createNamedContext(
            'Scroll',
            {
                subscribe: (
                    PropTypes.shape({
                        updated: PropTypes.func.isRequired
                    })
                    .isRequired)
            }));

export default (
    createComponent(
        'ScrollContext',
        {
            propTypes: {
                element: PropTypes.node.isRequired
            },
            defaultProps: {
                element: 'div'
            }
        },
        class extends Component {
            static context = ScrollContext;

            constructor(props) {
                super(props);

                const publisher = events('updated');

                this.provider = {
                    subscribe: publisher.subscribe
                };
                this.publisher = events('updated');
                this.handled = false;
                this.cancel = null;
            }

            ref = element => {
                const cancel = this.cancel;

                if (cancel) {
                    cancel();
                    this.cancel = null;
                }
                if (element) {
                    const
                        handler = () => {
                            if (!this.handled) {
                                this.handled = true;
                                animated(() => {
                                    this.handled = false;
                                    this.publisher.publish.updated();
                                });
                            }
                        };
                    element.addEventListener('scroll', handler);
                    this.cancel = () => {
                        element.removeEventListener('scroll', handler);
                    };
                    this.publisher.publish.updated();
                }
            }

            componentWillUnmount() {
                const cancel = this.cancel;

                if (cancel) {
                    cancel();
                    this.cancel = null;
                }
            }

            render() {
                const
                    {
                        element,
                        children,
                        ...props
                    } = this.props;

                return (
                    <ScrollContext.Provider value={this.provider}>
                        {createElement(
                            element,
                            {
                                ...props,
                                ref: this.ref
                            },
                            children)}
                    </ScrollContext.Provider>);
            }
        }));

export {ScrollContext};
