import React from 'react';
import {createComponent} from '@republic/react-foundation';
import Button from './Button';
import colors from './colors';

export default (
    createComponent(
        'PrivacyManagerButton',
        {},
        () => (
            <Button
                label="Do Not Sell My Personal Information"
                buttonColor="gray_1"
                border={`1px solid ${colors.gray_3}`}
                fontColor="dark"
                alignSelf="start"
                setting="button_tiny"
                padding={[0.5, 1, 0.5, 1]}
                width="240px"
                wrap={true}
                action={() => {
                    if (window._sp_ && window._sp_.usnat && window._sp_.usnat.loadPrivacyManagerModal) {
                        window._sp_.usnat.loadPrivacyManagerModal(1017501);
                    }
                }} />)));
