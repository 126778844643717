import PropTypes from 'prop-types';
import {find, index} from '@republic/foundation/lang/array';
import {createNamed} from '@republic/foundation/storage';
import {createStream, createStreamContext} from '@republic/react-foundation';
import local from '../../core/services/storage/local';

const
    storage = createNamed(local, 'cart-business/redemptions', 7 * 24 * 60 * 60),

    clear = () => {
        storage.clear();
    };

export default (
    createStream(
        'BusinessRedemptionsStream',
        {singleton: true},
        createStreamContext(
            'BusinessRedemptionsContext',
            {
                add: PropTypes.func.isRequired,
                remove: PropTypes.func.isRequired,
                clear: PropTypes.func.isRequired
            }),
        null,
        {
            add: code => ({code}),
            remove: code => ({code})
        },
        methods => ({
            ...methods,
            clear
        }),
        () => storage.get() || [],
        (on, {add}) => on(add.stream, (redemptions, {code}) => {
            code = code.toUpperCase();
            if (!find(redemptions, item => item.code === code)) {
                return (
                    storage.set([
                        ...redemptions,
                        {code}
                    ]));
            } else {
                return redemptions;
            }
        }),
        (on, {remove}) => on(remove.stream, (redemptions, {code}) => {
            const
                normalized = code.toUpperCase(),
                i = index(redemptions, ({code}) => code === normalized);

            return (
                i >= 0 ?
                    storage.set([
                        ...redemptions.slice(0, i),
                        ...redemptions.slice(i + 1)
                    ]) :
                    redemptions);
        }),
        on => on(storage.cleared, () => [])));
