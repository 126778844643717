import React, {Component} from 'react';
import {delayed} from '@republic/foundation/browser/execution';
import {createComponent, createEnhancer} from '@republic/react-foundation';
import {pageview} from '../../services/analytics';

const
    update = () => {
        delayed(() => {
            pageview(document.title, document.location.href, document.referrer);
        });
    };

export default (
    createEnhancer(
        WrappedComponent => (
            createComponent(
                `Pageviews(${
                    WrappedComponent.displayName ||
                    WrappedComponent.name ||
                    'Component'
                })`,
                {},
                class Pageview extends Component {
                    componentDidMount() {
                        update();
                    }

                    componentDidUpdate({location: previous}) {
                        const {location: next} = this.props;

                        if (next.pathname !== previous.pathname ||
                            next.search !== previous.search) {

                            update();
                        }
                    }

                    render() {
                        return <WrappedComponent {...this.props} />;
                    }
                }))));
