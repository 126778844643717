import PropTypes from 'prop-types';
import React from 'react';
import {createComponent} from '@republic/react-foundation';
import $ from '@republic/spellcaster';
import Image from './Image';
import {imageType} from '../types';

export default (
    createComponent(
        'LoadingSpinner',
        {
            propTypes: {
                image: (
                    PropTypes.shape(
                        imageType)
                    .isRequired)
            },
            defaultProps: {
                image: $.image('../../static/img/icons/spinner.gif', {width: 25, height: 25})
            }
        },
        ({image, ...props}) => (
            <Image
                image={image}
                alt="Loading"
                classes={{ready: 'ready'}}
                style={{alignSelf: 'center'}}
                {...props} />)));
