import React from 'react';
import {createComponent, use} from '@republic/react-foundation';
import $ from '@republic/spellcaster';
import Image from '../../core/components/Image';
import BusinessCartModalStream from '../streams/BusinessCartModalStream';
import BusinessCartStream from '../streams/BusinessCartStream';

export default (
    use({
            cart: BusinessCartStream,
            modal: BusinessCartModalStream
        },
        ({cart, modal: {expanded}}, {modal: {expand, hide}}) => ({
            expanded,
            full: !!(cart.contract.length || cart.devices.length || cart.accessories.length),
            expand,
            hide
        }))(
        createComponent(
            'BusinessCartToggleOpen',
            {},
            ({expanded, full, expand, hide}) => (
                <span className="cart-nav">
                    <span
                        className="flex y-center pointer"
                        role="button"
                        aria-label={expanded ? 'Hide Cart' : 'Show Cart'}
                        title={expanded ? 'Hide Cart' : 'Show Cart'}
                        onClick={expanded ? hide : expand}>

                        {full ?
                            <Image
                                image={$.svg('../../static/svg/nav/cart-light-full.svg', {width: 22, height: 18})}
                                alt="Cart with items" /> :
                            // HACK (ccunningham): for portal store requirements, only show a cart if they've added something
                            // <Image
                            //     image={$.svg('../../static/svg/nav/cart-light.svg', {width: 22, height: 18})}
                            //     alt="Cart" />
                            null}
                    </span>
                </span>))));
