import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Layout} from '@republic/bits';
import {delayed} from '@republic/foundation/browser/execution';
import {createComponent} from '@republic/react-foundation';
import $ from '@republic/spellcaster';
import colors from '../../core/components/colors';
import Image from '../../core/components/Image';
import {cartProductItemType} from '../types';

const
    debounce = 0.5,
    delay = 0.5,
    frequency = 20;

export default (
    createComponent(
        'BusinessCartQuantity',
        {
            propTypes: {
                ...cartProductItemType,
                removable: PropTypes.bool,
                readOnly: PropTypes.bool,
                bgColor: PropTypes.string,
                width: PropTypes.string,
                fontSize: PropTypes.string
            },
            defaultProps: {
                bgColor: 'white',
                width: '115px',
                fontSize: '2.5em'
            }
        },
        class extends Component {
            constructor(props) {
                super(props);
                this.update = null;
                this.increment = null;
                this.decrement = null;
                this.state = {
                    quantity: (
                        this.props.quantity ?
                            String(this.props.quantity) :
                            '')
                };
            }

            cancel = () => {
                const {update, increment, decrement} = this;

                if (update) {
                    this.update = null;
                    update();
                }
                if (increment) {
                    this.increment = null;
                    increment();
                }
                if (decrement) {
                    this.decrement = null;
                    decrement();
                }
            }

            onQuantityChange = event => {
                const quantity = event.target.value.replace(/[^\d]/g, '');

                this.cancel();
                if (this.state.quantity !== quantity) {
                    const
                        value = parseInt(quantity, 10) || 0,
                        {min = null, max = null, removable} = this.props;

                    this.setState({quantity});
                    if ((value >= (removable ? 0 : (min || 1))) && (max === null || value <= max)) {
                        this.update = (
                            delayed(
                                () => {
                                    const {update} = this.props;

                                    update(value);
                                },
                                1000 * debounce));
                    }
                }
            }

            onQuantityBlur = () => {
                if (!this.increment && !this.decrement) {
                    const
                        value = parseInt(this.state.quantity, 10) || 0,
                        {max = null, update} = this.props,
                        quantity = Math.min(value, max === null ? value : max);

                    this.cancel();
                    update(quantity);
                    if (quantity !== value) {
                        this.setState({quantity: String(quantity)});
                    }
                }
            }

            onIncrement = () => {
                const
                    commit = () => {
                        window.removeEventListener('mouseup', commit);
                        window.removeEventListener('blur', commit);
                        this.cancel();
                        this.update = (
                            delayed(
                                () => {
                                    const
                                        value = parseInt(this.state.quantity, 10) || 0,
                                        {min = null, max = null, removable, update} = this.props,
                                        quantity = (
                                            Math.max(
                                                removable ? 0 : (min || Math.min(max || 0, 1)),
                                                Math.min(
                                                    value,
                                                    max === null ? value : max)));

                                    update(quantity);
                                    if (quantity !== value) {
                                        this.setState({quantity: String(quantity)});
                                    }
                                },
                                1000 * debounce));
                    },
                    increment = delay => {
                        this.cancel();
                        this.setState(
                            ({quantity}) => {
                                const
                                    value = parseInt(quantity, 10) || 0,
                                    {max = null} = this.props;

                                if (max === null || value < max) {
                                    return {quantity: String(value + 1)};
                                } else {
                                    commit();
                                    return null;
                                }
                            },
                            () => {
                                const
                                    value = parseInt(this.state.quantity, 10) || 0,
                                    {max = null} = this.props;

                                this.cancel();
                                if (max === null || value < max) {
                                    this.increment = (
                                        delayed(
                                            () => {
                                                increment(1 / frequency);
                                            },
                                            1000 * delay));
                                } else {
                                    commit();
                                }
                            });
                    };

                window.addEventListener('mouseup', commit);
                window.addEventListener('blur', commit);
                increment(delay);
            }

            onDecrement = () => {
                const
                    commit = () => {
                        window.removeEventListener('mouseup', commit);
                        window.removeEventListener('mouseup', commit);
                        this.cancel();
                        this.update = (
                            delayed(
                                () => {
                                    const
                                        value = parseInt(this.state.quantity, 10) || 0,
                                        {min, max = null, removable, update} = this.props,
                                        quantity = (
                                            Math.max(
                                                removable ? 0 : (min || Math.min(max || 0, 1)),
                                                Math.min(
                                                    value,
                                                    max === null ? value : max)));

                                    update(quantity);
                                    if (quantity !== value) {
                                        this.setState({quantity: String(quantity)});
                                    }
                                },
                                1000 * debounce));
                    },
                    decrement = delay => {
                        this.cancel();
                        this.setState(
                            ({quantity}) => {
                                const
                                    value = parseInt(quantity, 10) || 0,
                                    {min, removable} = this.props;

                                if (value > (removable ? 0 : (min || 1))) {
                                    return {quantity: String(value - 1)};
                                } else {
                                    commit();
                                    return null;
                                }
                            },
                            () => {
                                const
                                    value = parseInt(this.state.quantity, 10) || 0,
                                    {min, removable} = this.props;

                                this.cancel();
                                if (value > (removable ? 0 : (min || 1))) {
                                    this.decrement = (
                                        delayed(
                                            () => {
                                                decrement(1 / frequency);
                                            },
                                            1000 * delay));
                                } else {
                                    commit();
                                }
                            });
                    };

                window.addEventListener('mouseup', commit);
                window.addEventListener('blur', commit);
                decrement(delay);
            }

            componentDidUpdate(props) {
                if (this.props.quantity !== props.quantity) {
                    const {quantity} = this.props;

                    if (this.state.quantity !== String(quantity) ||
                        (parseInt(this.state.quantity, 10) || 0) !== quantity) {

                        this.setState({quantity: String(quantity)});
                    }
                }
                if (!this.props.enabled && props.enabled) {
                    this.cancel();
                }
            }

            componentWillUnmount() {
                this.cancel();
            }

            render() {
                const
                    {bgColor, width, fontSize, enabled, min = null, max = null, removable, readOnly} = this.props,
                    {quantity} = this.state,
                    value = parseInt(quantity, 10) || 0;

                return (
                    <Layout row>
                        {!readOnly ?
                            <button
                                disabled={!enabled || value <= (removable ? 0 : (min || 1))}
                                onMouseDown={this.onDecrement}
                                style={{appearance: 'none', background: 'none', border: 'none', flexShrink: 0}}>

                                {(!enabled || value <= (removable ? 0 : (min || 1))) ?
                                    <Image
                                        image={$.svg('../../static/svg/button/purchase-minus-gray.svg')}
                                        alt="Minus button icon disabled"
                                        style={{alignSelf: 'center'}} /> :
                                    <Image
                                        image={$.svg('../../static/svg/button/purchase-minus-blue.svg')}
                                        alt="Minus button icon enabled"
                                        style={{alignSelf: 'center'}} />}
                            </button> :
                            null}
                        <input
                            type="text"
                            disabled={!enabled}
                            value={quantity}
                            onChange={this.onQuantityChange}
                            onBlur={this.onQuantityBlur}
                            style={{
                                backgroundColor: colors[bgColor],
                                color: colors.gray_4,
                                fontSize: fontSize,
                                width: width,
                                textAlign: 'center',
                                ...readOnly ?
                                    {
                                        border: 'none',
                                        outline: 'none'
                                    } :
                                    {
                                        borderBottom: `1px solid ${colors.gray_3}`,
                                        borderLeft: 'none',
                                        borderRight: 'none',
                                        borderTop: 'none'
                                    }
                            }}
                            {...removable ?
                                {placeholder: '0'} :
                                {}}
                            {...readOnly ?
                                {readOnly: true} :
                                {}} />
                        {!readOnly ?
                            <button
                                disabled={!enabled || (max !== null && value >= max)}
                                onMouseDown={this.onIncrement}
                                style={{appearance: 'none', background: 'none', border: 'none', flexShrink: 0}}>

                                {(!enabled || (max !== null && value >= max)) ?
                                    <Image
                                        image={$.svg('../../static/svg/button/purchase-plus-gray.svg')}
                                        alt="Plus button icon disabled"
                                        style={{alignSelf: 'center'}} /> :
                                    <Image
                                        image={$.svg('../../static/svg/button/purchase-plus-blue.svg')}
                                        alt="Plus button icon enabled"
                                        style={{alignSelf: 'center'}} />}
                            </button> :
                            null}
                    </Layout>);
            }
        }));
