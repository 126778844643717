import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import {Type} from '@republic/bits';
import {createComponent} from '@republic/react-foundation';
import Copy from '../../copy/components/Copy';
import colors from './colors';

export default (
    createComponent(
        'Anchor',
        {
            propTypes: {
                tag: PropTypes.string,
                color: PropTypes.string,
                setting: PropTypes.string,
                decoration: PropTypes.string,
                external: PropTypes.bool,
                href: PropTypes.string,
                size: PropTypes.number,
                weight: PropTypes.string,
                action: PropTypes.func,
                target: PropTypes.string,
                imageLink: PropTypes.bool
            },
            defaultProps: {
                tag: 'a',
                color: colors.gray_4,
                setting: 'body',
                decoration: 'underline',
                external: false,
                size: 1,
                weight: 'normal',
                imageLink: false
            }
        },
        ({children, tag, color, setting, decoration, href, size, weight, external, action, target, imageLink}) => (
            external ?
                <Type
                    tag={tag}
                    href={href}
                    decoration={decoration}
                    color={color}
                    {...imageLink ? {display: 'flex'} : {}}
                    {...action ? {onClick: action} : {}}
                    {...target ? 
                        {
                            target,
                            rel: 'noopener noreferrer'
                        } : {}}>

                    {imageLink ?
                        children :
                        <Copy
                            tag="span"
                            setting={setting}
                            color={color}
                            size={size}
                            weight={weight}
                            decoration={decoration}>

                            {children}
                        </Copy>}
                </Type> :
                <Link
                    to={href}
                    {...action ? {onClick: action} : {}}
                    {...decoration === 'underline' ? {style: {textDecoration: decoration, color: color}} : {}}>
                    
                    {imageLink ?
                        children :
                        <Copy
                            tag="span"
                            setting={setting}
                            color={color}
                            decoration={decoration}
                            size={size}
                            weight={weight}>

                            {children}
                        </Copy>}
                </Link>)));
