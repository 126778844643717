import React from 'react';
import $ from '@republic/spellcaster';
import {Layout} from '@republic/bits';
import {createComponent} from '@republic/react-foundation';
import AccountMenuToggleOpen from '../../../accounts/components/AccountMenuToggleOpen';
import Copy from '../../../copy/components/Copy';
import Anchor from '../Anchor';
import colors from '../colors';
import CartToggleOpen from '../CartToggleOpen';
import Image from '../Image';

export default (
    createComponent(
        'Header',
        {},
        () => (
            <>
                <Layout tag="header" layer={200} width='100%' style={{position: 'fixed', overflow: 'hidden'}} color={colors.blue}>
                    <Layout row={true} height={3.75} justify="between" align="center" padding={{left: 1.25}}>
                        <Layout justify="center" align="center" row>
                            <Anchor
                                className="logo flex y-center"
                                external={true}
                                href={'https://relaypro.com'}>

                                <Image
                                    image={$.svg('../../../static/svg/nav/logo-light.svg', {width: 159, height: 50})}
                                    alt="Relay" />
                            </Anchor>
                        </Layout>
                        <Layout row align="center">
                            <Layout padding={{right: 2}} align="center" justify="center">
                                <Layout className="desktop-nav-link">
                                    <Anchor tag="a" decoration="none" external={true} href="https://support.relaypro.com/portal/en/kb/relay-pro">
                                        <Copy setting="navlink" color={colors.white}>Help</Copy>
                                    </Anchor>
                                </Layout>
                            </Layout>
                            <Layout padding={{right: 1}} align="center" justify="center">
                                <AccountMenuToggleOpen className={'text-white'} />
                            </Layout>
                            <CartToggleOpen />
                        </Layout>
                    </Layout>
                </Layout>
                <div className="header-shim"></div>
            </>)));
