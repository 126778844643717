import sha1 from '@republic/foundation/crypto/sha1';
import {filter, map, reduce, sorted} from '@republic/foundation/lang/array';
import {isEqual} from '@republic/foundation/lang/is';
import {stringify} from '@republic/foundation/lang/json';
import {get, keys} from '@republic/foundation/lang/object';
import {createPrefixed} from '@republic/foundation/storage';
import AuthStream from '../../auth/streams/AuthStream';
import session from '../../core/services/storage/session';
import {createRequestStream} from '../../core/streams';
import CatalogStream from '../../products/streams/CatalogStream';
import OffersStream from '../../products/streams/OffersStream';
import PlanOffersStream from '../../products/streams/PlanOffersStream';
import {steps, serialize} from '../services/checkout';
import BusinessCartStream from './BusinessCartStream';
import BusinessCheckoutModelStream from './BusinessCheckoutModelStream';

export default (
    createRequestStream(
        'BusinessDeliveryOffers',
        {
            code: 'cart-business/delivery',
            error: 'A request for shipping offers failed',
            singleton: true
        },
        createPrefixed(session, 'cart-business/delivery/3.0', 15 * 60),
        {
            auth: AuthStream,
            catalog: CatalogStream,
            offers: OffersStream,
            plans: PlanOffersStream,
            cart: BusinessCartStream,
            address: (
                BusinessCheckoutModelStream.as(
                    (model, {auth: {synchronized}}) => (
                        synchronized(
                            model,
                            model => (
                                model ?
                                    model.operators.get(model, steps.shipping) :
                                    null))
                        .map(
                            model => ((
                                model &&
                                model.operators.valid(model) &&
                                model.operators.pristine(model)) ?
                                    model.operators.value(model) :
                                    null))
                        .unique(isEqual))))
        },
        ({auth, catalog, offers, plans, cart, address}) => (
            (catalog && catalog.data &&
            offers && offers.data &&
            (!cart.contract.length || (plans && plans.data)) &&
            (cart.devices.length || cart.accessories.length) &&
            address) ?
                sha1(
                    stringify({
                        id: (auth && auth.id) || '',
                        cart: {
                            payment: cart.payment,
                            contract: map(cart.contract, ({items}) => sorted(keys(items))),
                            devices: map(cart.devices, ({items}) => sorted(keys(items))),
                            accessories: map(cart.accessories, ({items}) => sorted(keys(items)))
                        },
                        address
                    })) :
                null),
        delivery => !!delivery.data,
        ({offers, plans}) => offers.valid && (!plans || plans.valid),
        ({auth, offers, plans, cart, address}) => (
            fetch(
                `/fractus/${(auth && auth.token) ? 'p' : 'u'}/stratus/rest/v3/shipping_offers`,
                {
                    method: 'POST',
                    body: (
                        JSON.stringify({
                            order_lines: (
                                serialize(
                                    format => (
                                        format
                                        .contract(cart)
                                        .devices(cart)
                                        .accessories(cart)),
                                    offers,
                                    plans)),
                            shipping_location: {
                                address_1: address.address1 || '',
                                address_2: address.address2 || '',
                                city: address.city || '',
                                state: address.state || '',
                                zip_code: address.zip || ''
                            }
                        })),
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        ...(auth && auth.token) ?
                            {Authorization: `Bearer ${auth.token}`} :
                            {}
                    }
                })),
        (raw, {catalog}) => {
            const
                data = (
                    reduce(
                        get(raw, 'shipping_offer', 'offer_components') || [],
                        (data, {offer_items: items}) => (
                            reduce(
                                items,
                                (data, {
                                    item: {
                                        sku,
                                        type: model,
                                        days_till_available: available
                                    },
                                    one_time_price: price,
                                    discounted_one_time_price: discount
                                }) => {
                                    data[sku] = {
                                        sku,
                                        model,
                                        available,
                                        price: discount || price
                                    };
                                    return data;
                                },
                                data)),
                        {}));

            return {
                raw,
                data: (
                    reduce(
                        filter(
                            keys(catalog.data.families),
                            slug => (
                                catalog.data.families[slug].model === 'shipping_handling_family')),
                        (products, slug) => (
                            reduce(
                                catalog.data.families[slug].skus,
                                (products, sku) => {
                                    const
                                        product = catalog.data.products[sku],
                                        offer = data[sku];

                                    if (product && offer && offer.available >= 0) {
                                        products.push({
                                            ...product,
                                            ...offer
                                        });
                                    }
                                    return products;
                                },
                                products)),
                        []))
            };
        },
        () => ({raw: undefined, data: undefined})));
