import PropTypes from 'prop-types';
import React, {Component}  from 'react';
import {blankImage} from '@republic/foundation/browser/blank';
import {animated} from '@republic/foundation/browser/execution';
import {get} from '@republic/foundation/lang/object';
import {createComponent} from '@republic/react-foundation';
import visibility from '../enhancers/visibility';
import {imageType} from '../types';

export default (
    visibility(
        createComponent(
            'Image',
            {
                propTypes: {
                    ...visibility.propTypes,
                    image: (
                        PropTypes.shape(
                            imageType)
                        .isRequired),
                    classes: (
                        PropTypes.shape({
                            lazy: PropTypes.string,
                            ready: PropTypes.string
                        }))
                }
            },
            class extends Component {
                constructor(props) {
                    super(props);

                    const {image: {width, height}} = props;

                    this.state = {
                        blank: blankImage(width, height),
                        loading: false,
                        ready: false
                    };
                }

                onReady = () => {
                    if (this.state.loading) {
                        this.setState({
                            loading: false,
                            ready: true
                        });
                    }
                }

                componentDidUpdate(props) {
                    const {visibility: {visible, track}} = this.props;

                    if (visible && !props.visibility.visible) {
                        track(false);
                        this.setState(
                            {loading: true},
                            () => {
                                const cancel = this.cancel;

                                if (cancel) {
                                    cancel();
                                }
                                this.cancel = animated(() => {
                                    this.setState({blank: null});
                                    this.cancel = null;
                                });
                            });
                    }
                }

                componentWillUnmount() {
                    const cancel = this.cancel;

                    if (cancel) {
                        cancel();
                        this.cancel = null;
                    }
                }

                render() {
                    const
                        {visibility, image, classes, alt, ...props} = this.props,
                        {blank, ready} = this.state;

                    return (
                        <img
                            {...props}
                            ref={visibility.ref}
                            src={blank || image.url}
                            alt={alt || ''}
                            className={`${get(classes, 'lazy') || 'lazy-image'}${ready ? ` ${get(classes, 'ready') || 'ready'}`: ''}`}
                            onLoad={this.onReady}
                            onError={this.onReady}
                            onCancel={this.onReady} />);
                }
            })));
