import React from 'react';
import {Layout} from '@republic/bits';
import {createComponent} from '@republic/react-foundation';
import $ from '@republic/spellcaster';
import AccountMenuToggleOpen from '../../../accounts/components/AccountMenuToggleOpen';
import CartToggleOpen from '../CartToggleOpen';
import Anchor from '../Anchor';
import colors from '../colors';
import Image from '../Image';

export default (
    createComponent(
        'HeaderMobile',
        {},
        ({}) => (
            <>
                <Layout
                    tag="header"
                    layer={200}
                    width='100%'
                    style={{position: 'fixed', overflow: 'hidden'}}
                    color={colors.blue}>
                    <Layout row={true} height={3.75} justify="between" align="center" padding={{left: 0.625}}>
                        <Layout justify="center" align="center" row>
                            <Anchor
                                className="logo flex y-center"
                                external={true}
                                href={'https://relaypro.com'}>

                                <Image
                                    image={$.svg('../../../static/svg/nav/logo-light.svg', {width: 159, height: 50})}
                                    alt="Relay" />
                            </Anchor>
                        </Layout>
                        <Layout row>
                            <Layout padding={{right: 1}} align="center" justify="center">
                                <AccountMenuToggleOpen className={'text-white'} />
                            </Layout>
                            <CartToggleOpen />
                        </Layout>
                    </Layout>
                </Layout>
                <div className="header-shim"></div>
            </>)));
