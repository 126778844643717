import PropTypes from 'prop-types';
import React from 'react';
import {Layout} from '@republic/bits';
import {createComponent} from '@republic/react-foundation';
import Label from '../../copy/components/Label';
import Image from '../../core/components/Image';
import {imageType} from '../../core/types';
import colors from '../../core/components/colors';

export default (
    createComponent(
        'Button',
        {
            propTypes: {
                type: PropTypes.string,
                buttonColor: PropTypes.string,
                fontColor: PropTypes.string,
                disabled: PropTypes.bool,
                disabledColor: PropTypes.string,
                disabledBorder: PropTypes.string,
                label: PropTypes.string.isRequired,
                action: PropTypes.func,
                margin: PropTypes.array,
                padding: PropTypes.array,
                leading: PropTypes.number,
                width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                icon: PropTypes.shape(imageType),
                iconPosition: PropTypes.string,
                radius: PropTypes.number,
                spacing: PropTypes.string,
                alignSelf: PropTypes.string,
                buttonTypeAttr: PropTypes.string,
                border: PropTypes.string,
                setting: PropTypes.string,
                wrap: PropTypes.bool,
                borderWidth: PropTypes.number,
                borderStyle: PropTypes.string,
                align: PropTypes.string,
                justify: PropTypes.string,
                transform: PropTypes.string
            },
            defaultProps: {
                border: 'none',
                disabled: false,
                disabledColor: 'medium_dark_gray',
                disabledBorder: 'none',
                padding: [1, 2.8, 1, 2.8],
                width: 'auto',
                maxWidth: '100%',
                height: 'auto',
                radius: 0.5,
                iconPosition: 'left',
                alignSelf: 'center',
                setting: 'button',
                buttonColor: 'teal',
                fontColor: 'white',
                wrap: false,
                borderWidth: 1,
                borderStyle: 'solid',
                align: 'center',
                justify: 'center'
            }
        },
        ({type, buttonColor, fontColor, border, disabled, disabledColor, disabledBorder, label, action, margin, padding, width, maxWidth, height, icon, radius, spacing, iconPosition, alignSelf, buttonTypeAttr, setting, wrap, borderWidth, borderStyle, align, justify, transform}) => (
            <Layout style={{alignSelf: alignSelf}}>
                <Layout
                    row
                    border={
                        !disabled ?
                            type === 'transparent' ?
                                `${borderWidth}px ${borderStyle} ${colors[buttonColor]}` :
                                border !== 'none' ?
                                    border :
                                    'none' :
                            disabledBorder}
                    color={
                        !disabled ?
                            type === 'transparent' ?
                                'transparent' :
                                colors[buttonColor] :
                            colors[disabledColor]}
                    disabled={disabled}
                    height={height}
                    width={type === 'wide' ? '100%' : width}
                    maxWidth={maxWidth}
                    margin={margin}
                    padding={padding}
                    radius={radius}
                    tag="button"
                    align={align}
                    justify={justify}
                    wrap={wrap}
                    {...action || buttonTypeAttr === 'submit' ? {cursor: 'pointer'} : {cursor: 'default'}}
                    {...action ? {onClick: action} : {}}
                    {...buttonTypeAttr ? {type: buttonTypeAttr} : {}}>

                    <>
                        {icon && iconPosition === 'left' ?
                            <Image
                                image={icon}
                                alt=""
                                role="presentation"
                                style={{paddingRight: '0.625em', flexShrink: 0}}
                                classes={{ready: 'ready'}} /> :
                            null}
                        <Label
                            setting={setting}
                            color={disabled ? colors.white : colors[fontColor]}
                            spacing={spacing}
                            {...transform ? {transform} : {}}
                            tag="span">

                            {label}
                        </Label>
                        {icon && iconPosition === 'right' ?
                            <Image
                                image={icon}
                                alt=""
                                role="presentation"
                                style={{paddingLeft: '0.625em', position: 'relative', top: '0.375em'}}
                                classes={{ready: 'ready'}} /> :
                            null}
                    </>
                </Layout>
            </Layout>)));
