import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {createComponent} from '@republic/react-foundation';
import Copy from '../../copy/components/Copy';
import {cartProductItemType} from '../types';

export default (
    createComponent(
        'BusinessCartRemove',
        {
            propTypes: {
                ...cartProductItemType,
                align: PropTypes.string
            },
            defaultProps: {
                align: 'center'
            }
        },
        class extends Component {
            onClick = () => {
                const {update} = this.props;

                update(0);
            }

            render() {
                const {enabled, align} = this.props;

                return (
                    <button
                        disabled={!enabled}
                        onClick={this.onClick}
                        style={{appearance: 'none', border: 'none', background: 'none', alignSelf: align}}>

                        <Copy setting="tiny" decoration="underline" tag="span">
                            Remove
                        </Copy>
                    </button>);
            }
        }));
