import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import {Layout, Type} from '@republic/bits';
import {createComponent} from '@republic/react-foundation';
import Label from '../../copy/components/Label';
import colors from './colors';

export default (
    createComponent(
        'ButtonLink',
        {
            propTypes: {
                align: PropTypes.string,
                buttonColor: PropTypes.string,
                fontColor: PropTypes.string,
                decoration: PropTypes.string,
                display: PropTypes.string,
                external: PropTypes.bool.isRequired,
                href: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                padding: PropTypes.array,
                size: PropTypes.number,
                transparent: PropTypes.bool,
                width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                weight: PropTypes.string,
                disabled: PropTypes.bool,
                radius: PropTypes.number,
                action: PropTypes.func,
                setting: PropTypes.string,
                border: PropTypes.string,
                borderWidth: PropTypes.number,
                borderStyle: PropTypes.string,
                target: PropTypes.string
            },
            defaultProps: {
                align: 'center',
                buttonColor: 'blue',
                fontColor: 'white',
                decoration: 'none',
                width: 'auto',
                maxWidth: '100%',
                weight: 'bold',
                radius: 0.5,
                padding: [1, 2.8, 1, 2.8],
                setting: 'button',
                disabled: false,
                border: 'none',
                borderWidth: 1,
                borderStyle: 'solid'
            }
        },
        ({align, external, href, label, width, maxWidth, buttonColor, fontColor, transparent, disabled, radius, action, padding, setting, border, borderWidth, borderStyle, target, ...props}) => (
            <Layout
                align={align}
                border={
                    !disabled ?
                        transparent ?
                            `${borderWidth}px ${borderStyle} ${colors[buttonColor]}` :
                            border !== 'none' ?
                                border :
                                'none' :
                        'none'}
                color={
                    !disabled ?
                        transparent ?
                            'transparent' :
                            colors[buttonColor] :
                        colors.border_gray}
                radius={radius}
                width={width}
                maxWidth={maxWidth}
                style={{alignSelf: align}}
                {...action ? {onClick: action} : {}}>

                {disabled ?
                    <Layout padding={padding}>
                        <Label
                            setting={setting}
                            align="center"
                            color={colors[fontColor]}>

                            {label}
                        </Label>
                    </Layout> :
                    external ?
                        <Type 
                            href={href} 
                            tag="a" 
                            align="center" 
                            display="block" 
                            width="100%" 
                            {...target ? 
                                {
                                    target,
                                    rel: 'noopener noreferrer'
                                } : {}}>

                            <Layout padding={padding}>
                                <Label
                                    setting={setting}
                                    align="center"
                                    color={colors[fontColor]}>

                                    {label}
                                </Label>
                            </Layout>
                        </Type> :
                        <Link to={href} style={{display: 'block', width: '100%'}}>
                            <Layout padding={padding}>
                                <Label
                                    setting={setting}
                                    align="center"
                                    color={colors[fontColor]}>

                                    {label}
                                </Label>
                            </Layout>
                        </Link>}
            </Layout>)));
