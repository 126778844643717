import PropTypes from 'prop-types';
import React from 'react';
import {Layout} from '@republic/bits';
import {createComponent} from '@republic/react-foundation';
import {stringOrNumber} from '../types';

export default (
    createComponent(
        'Grid',
        {
            propTypes: {
                align: PropTypes.string,
                justify: PropTypes.string,
                stack: PropTypes.bool,
                flex: PropTypes.oneOfType([
                    PropTypes.bool,
                    PropTypes.number
                ]),
                reverse: PropTypes.bool,
                wrap: PropTypes.bool,
                color: PropTypes.string,
                padding: (
                    PropTypes.oneOfType([
                        PropTypes.string,
                        PropTypes.number,
                        PropTypes.arrayOf(stringOrNumber),
                        PropTypes.shape({
                            bottom: stringOrNumber,
                            left: stringOrNumber,
                            right: stringOrNumber,
                            top: stringOrNumber
                        })
                    ]))
            },
            defaultProps: {
                stack: false
            }
        },
        ({children, stack, reverse, ...rest}) => (
            <Layout
                {...reverse && stack ? {reverse} : {}}
                row={stack ? false : true}
                {...rest}>

                {children}
            </Layout>)));
