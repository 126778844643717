import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {mutationSubscribe} from '@republic/foundation/browser/mutation';
import {scrollBottom, scrollTop, scrollSubscribe} from '@republic/foundation/browser/scroll';
import {createComponent, createEnhancer} from '@republic/react-foundation';
import ScrollContext from '../components/ScrollContext';

const
    types = {
        visibility: (
            PropTypes.shape({
                ref: PropTypes.func.isRequired,
                track: PropTypes.func.isRequired,
                visible: PropTypes.bool.isRequired
            })
            .isRequired)
    };

export default (
    createEnhancer(
        WrappedComponent => (
            ScrollContext.context.with('scrollcontext')(
                createComponent(
                    `Visibility(${
                        WrappedComponent.displayName ||
                        WrappedComponent.name ||
                        'Component'
                    })`,
                    {
                        propTypes: {
                            scrollcontext: (
                                PropTypes.shape(
                                    ScrollContext.context.types))
                        }
                    },
                    class Visibility extends Component {
                        constructor(props) {
                            super(props);
                            this.state = {visible: false};
                        }

                        ref = element => {
                            this.element = element;
                        }

                        update = () => {
                            const {visible} = this.state;

                            if (visible) {
                                if (!this.visible()) {
                                    this.setState({visible: false});
                                }
                            } else {
                                if (this.visible()) {
                                    this.setState({visible: true});
                                }
                            }
                        }

                        track = track => {
                            if (track) {
                                if (!this.cancelContext) {
                                    this.cancelContext = (
                                        this.props.scrollcontext ?
                                            this.props.scrollcontext.subscribe.updated(this.update) :
                                            null);
                                }
                                if (!this.cancelScroll) {
                                    this.cancelScroll = scrollSubscribe.updated(this.update);
                                }
                                if (!this.cancelMutation) {
                                    this.cancelMutation = mutationSubscribe.mutated(this.update);
                                }
                            } else {
                                if (this.cancelContext) {
                                    this.cancelContext();
                                    this.cancelContext = null;
                                }
                                if (this.cancelScroll) {
                                    this.cancelScroll();
                                    this.cancelScroll = null;
                                }
                                if (this.cancelMutation) {
                                    this.cancelMutation();
                                    this.cancelMutation = null;
                                }
                            }
                        }

                        visible() {
                            const element = this.element;

                            return !!(
                                element &&
                                scrollBottom(element) > scrollTop() &&
                                scrollTop(element) < scrollBottom());
                        }

                        componentDidMount() {
                            this.track(true);
                            this.update();
                        }

                        componentWillUnmount() {
                            this.track(false);
                        }

                        render() {
                            const {scrollcontext, ...props} = this.props;

                            return (
                                <WrappedComponent
                                    visibility={{
                                        ref: this.ref,
                                        track: this.track,
                                        visible: this.state.visible
                                    }}
                                    {...props} />);
                        }
                    })))));

export {types};
