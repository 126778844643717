import PropTypes from 'prop-types';
import {familyType} from '../products/types';

const
    itemType = {
        quantity: PropTypes.number.isRequired
    },

    lineType = {
        family: PropTypes.string.isRequired,
        items: (
            PropTypes.objectOf(
                PropTypes.shape(
                    itemType)
                .isRequired)
            .isRequired)
    },

    cartType = {
        payment: (
            PropTypes.shape({
                annual: PropTypes.bool.isRequired
            })),
        contract: (
            PropTypes.arrayOf(
                PropTypes.shape(
                    lineType)
                .isRequired)
            .isRequired),
        devices: (
            PropTypes.arrayOf(
                PropTypes.shape(
                    lineType)
                .isRequired)
            .isRequired),
        accessories: (
            PropTypes.arrayOf(
                PropTypes.shape(
                    lineType)
                .isRequired)
            .isRequired)
    },

    cartProductItemType = {
        enabled: PropTypes.bool.isRequired,
        quantity: PropTypes.number.isRequired,
        min: PropTypes.number,
        max: PropTypes.number,
        update: PropTypes.func.isRequired
    },

    cartProductLineType = {
        family: (
            PropTypes.shape(
                familyType)
            .isRequired),
        items: (
            PropTypes.objectOf(
                PropTypes.shape(
                    cartProductItemType)
                .isRequired)
            .isRequired)
    },

    bundleType = {
        annual: PropTypes.bool.isRequired,
        plan: (
            PropTypes.objectOf(
                PropTypes.shape({
                    sku: PropTypes.string.isRequired,
                    quantity: PropTypes.number.isRequired,
                    product: PropTypes.object.isRequired
                })
                .isRequired)
            .isRequired),
        device: (
            PropTypes.objectOf(
                PropTypes.shape({
                    sku: PropTypes.string.isRequired,
                    quantity: PropTypes.number.isRequired,
                    product: PropTypes.object.isRequired
                })
                .isRequired)
            .isRequired),
        accessory: (
            PropTypes.objectOf(
                PropTypes.shape({
                    sku: PropTypes.string.isRequired,
                    quantity: PropTypes.number.isRequired,
                    product: PropTypes.object.isRequired
                })
                .isRequired)
            .isRequired),
        price: (
            PropTypes.shape({
                once: PropTypes.number.isRequired,
                recurring: PropTypes.number.isRequired
            })),
        enabled: PropTypes.bool.isRequired,
        add: PropTypes.func.isRequired
    },

    termsType = {
        name: PropTypes.string.isRequired,
        version: PropTypes.string.isRequired
    },

    couponType = {
        code: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        reason: PropTypes.string.isRequired,
        implicit: PropTypes.bool.isRequired,
        rejected: PropTypes.bool.isRequired
    },

    orderType = {
        annual: PropTypes.bool.isRequired,
        original: (
            PropTypes.shape({
                price: (
                    PropTypes.shape({
                        recurring: PropTypes.number.isRequired,
                        once: PropTypes.number.isRequired
                    })
                    .isRequired),
                tax: (
                    PropTypes.shape({
                        recurring: PropTypes.number.isRequired,
                        once: PropTypes.number.isRequired
                    })
                    .isRequired)
            })
            .isRequired),
        discounts: (
            PropTypes.shape({
                price: (
                    PropTypes.shape({
                        recurring: PropTypes.number.isRequired,
                        once: PropTypes.number.isRequired
                    })
                    .isRequired),
                tax: (
                    PropTypes.shape({
                        recurring: PropTypes.number.isRequired,
                        once: PropTypes.number.isRequired
                    })
                    .isRequired)
            })
            .isRequired),
        total: (
            PropTypes.shape({
                price: (
                    PropTypes.shape({
                        recurring: PropTypes.number.isRequired,
                        once: PropTypes.number.isRequired
                    })
                    .isRequired),
                tax: (
                    PropTypes.shape({
                        recurring: PropTypes.number.isRequired,
                        once: PropTypes.number.isRequired
                    })
                    .isRequired)
            })
            .isRequired),
        lines: (
            PropTypes.arrayOf(
                PropTypes.shape({
                    sku: PropTypes.string.isRequired,
                    recurring: PropTypes.bool.isRequired,
                    quantity: PropTypes.number.isRequired,
                    original: (
                        PropTypes.shape({
                            price: PropTypes.number.isRequired,
                            tax: PropTypes.number.isRequired
                        })
                        .isRequired),
                    discounts: (
                        PropTypes.shape({
                            price: PropTypes.number.isRequired,
                            tax: PropTypes.number.isRequired
                        })
                        .isRequired),
                    total: (
                        PropTypes.shape({
                            price: PropTypes.number.isRequired,
                            tax: PropTypes.number.isRequired
                        })
                        .isRequired),
                    taxes: (
                        PropTypes.arrayOf(
                            PropTypes.shape({
                                name: PropTypes.string.isRequired,
                                amount: PropTypes.number.isRequired,
                                discount: PropTypes.number.isRequired,
                                total: PropTypes.number.isRequired
                            })
                            .isRequired)
                        .isRequired)
                })
                .isRequired)
            .isRequired)
    },

    previewType = {
        ...orderType,
        terms: (
            PropTypes.arrayOf(
                PropTypes.shape(
                    termsType)
                .isRequired)
            .isRequired),
        coupons: (
            PropTypes.arrayOf(
                PropTypes.shape(
                    couponType)
                .isRequired)
            .isRequired)
    },

    transactionType = {
        id: PropTypes.string.isRequired,
        ...orderType
    };

export {
    itemType,
    lineType,
    cartType,
    cartProductItemType,
    cartProductLineType,
    bundleType,
    termsType,
    couponType,
    orderType,
    previewType,
    transactionType
};
