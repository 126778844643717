import {reversed} from '@republic/foundation/lang/array';
import {floor, round} from '@republic/foundation/lang/number';

const
    formatPrice = (price, decimals = false) => {
        const
            parts = [
                (decimals || (price % 100 !== 0)) ?

                    (price > 99999 ?
                        (0.01 * (100000 + (price % 100000))).toFixed(2).slice(1) :
                        (0.01 * price).toFixed(2)) :

                    (price > 99999 ?
                        String(round(0.01 * (100000 + (price % 100000)))).slice(1) :
                        String(round(0.01 * price)))
            ];

        for (let remainder = floor(0.00001 * price); remainder > 0; remainder = floor(0.001 * remainder)) {
            parts.push(
                remainder > 999 ?
                    String(floor(1000 + (remainder % 1000))).slice(1) :
                    String(floor(remainder % 1000)));
        }
        return reversed(parts).join(',');
    };

export {formatPrice};
