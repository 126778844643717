import PropTypes from 'prop-types';
import {filter} from '@republic/foundation/lang/array';
import {update} from '@republic/foundation/lang/collection';
import {isFunction} from '@republic/foundation/lang/is';
import {owns} from '@republic/foundation/lang/object';
import {createStream, createStreamContext} from '@republic/react-foundation';

export default (
    createStream(
        'ErrorsStream',
        {singleton: true},
        createStreamContext(
            'ErrorsContext',
            {
                display: PropTypes.func.isRequired,
                dismiss: PropTypes.func.isRequired
            }),
        null,
        {
            display: (name, message, action, dismiss) => ({name, message, action, dismiss}),
            dismiss: name => ({name})
        },
        methods => methods,
        {
            names: [],
            by: {}
        },
        (on, {display}) => on(display.stream, ({names, by}, {name, message, action, dismiss}) => ({
            names: owns(by, name) ? names : [...names, name],
            by: (
                update(by, name)(
                    (error = {}) => ({
                        ...error,
                        message,
                        action,
                        dismiss
                    })))
        })),
        (on, {dismiss}) => on(dismiss.stream, (errors, {name}) => {
            const {by} = errors;

            if (owns(by, name)) {
                const {dismiss} = by[name];

                if (isFunction(dismiss)) {
                    dismiss();
                }
                return {
                    names: (
                        filter(
                            errors.names,
                            value => value !== name)),
                    by: (
                        (({[name]: error, ...by}) => by)(
                            by))
                };
            } else {
                return errors;
            }
        })));
