import React from 'react';
import {createComponent} from '@republic/react-foundation';
import page from '../../core/enhancers/page';

export default (
    page('Page Not Found | Relay')(
        createComponent(
            'NotFoundPage',
            {},
            () => (
                <section className="text-center">
                    <h1 className="heading-huge text-red padding-top-1 padding-bottom-1">404</h1>
                    <p className="body-copy-huge">Page Not Found</p>
                </section>))));
