import {createComponent} from '@republic/react-foundation';
import Button from '../../core/components/Button';
import LoginButtonContainer from '../containers/LoginButtonContainer';

export default (
    createComponent(
        'LoginButton',
        {},
        props => (
            <LoginButtonContainer>
                {({enabled, authorize}) => (
                    <Button
                        label="Sign in"
                        {...props}
                        disabled={!enabled}
                        action={event => {
                            event.preventDefault();
                            authorize();
                        }} />)}
            </LoginButtonContainer>)));
