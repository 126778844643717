import React from 'react';
import {Container, Layout, Quantum} from '@republic/bits';
import {createComponent} from '@republic/react-foundation';
import $ from '@republic/spellcaster';
import Copy from '../../copy/components/Copy';
import GridContainer from '../containers/GridContainer';
import Anchor from './Anchor';
import colors from './colors';
import Grid from './Grid';
import GridCell from './GridCell';
import Image from './Image';
// import PCI from './PCI';
import PrivacyManagerButton from './PrivacyManagerButton';
import SecureTrust from './SecureTrust';

export default (
    createComponent(
        'Footer',
        {},
        () => (
            <Quantum leaps={[
                {
                    footerRow: false,
                    padding: {top: 2, bottom: 1, left: 1}
                },
                ['tiny', {
                    footerRow: false
                }],
                ['medium', {
                    footerRow: true,
                    padding: {top: 3, bottom: 2, left: 0}
                }]]}>

                {({footerRow}) => (
                    <>
                        <Layout color={colors.dark} padding={{top: 2, bottom: 2}}>
                            <Container>
                                <Grid stack={!footerRow}>
                                    <GridCell stack={!footerRow} width="50%" justify="center" padding={{bottom: footerRow ? 0 : 2, left: footerRow ? 0 : 1}}>
                                        <Image
                                            image={$.svg('../../static/svg/nav/logo-light.svg')}
                                            alt=""
                                            role="presentation"
                                            width="133px"
                                            style={{alignSelf: 'start'}} />
                                    </GridCell>
                                    <GridCell stack={!footerRow} width="50%" justify="center" padding={{bottom: footerRow ? 0 : 1, left: footerRow ? 0 : 1}}>
                                        <Layout style={{alignSelf: footerRow ? 'end' : 'center'}} >
                                            <PrivacyManagerButton />
                                        </Layout>
                                    </GridCell>
                                </Grid>
                            </Container>
                        </Layout>
                        <Layout
                            padding={{top: 1.5, bottom: 1}}
                            color={colors.gray_3}
                            position="relative"
                            justify="center"
                            align="center">
                            <Layout>
                                <GridContainer>
                                        {({row}) => (
                                            <Grid stack={!row} justify="center" align="center">
                                                <GridCell justify="center" align="center" width="100%">
                                                    <Grid width="100%" justify="around">
                                                        <GridCell padding={{left: 0.25}}>
                                                            <Anchor href="https://www.linkedin.com/company/relaypro/" external={true}>
                                                                <Image
                                                                    image={$.svg('../../static/svg/logo/footer-linkedin.svg')}
                                                                    alt="LinkedIn"
                                                                    style={{alignSelf: 'center'}}/>
                                                            </Anchor>
                                                        </GridCell>
                                                        <GridCell padding={{left: 0.25, right: 0.25}}>
                                                            <Anchor href="https://www.facebook.com/RelayPro" external={true}>
                                                                <Image
                                                                    image={$.svg('../../static/svg/logo/footer-facebook.svg')}
                                                                    alt="Facebook"
                                                                    style={{alignSelf: 'center'}}/>
                                                            </Anchor>
                                                        </GridCell>
                                                        <GridCell padding={{right: 0.25}}>
                                                            <Anchor href="https://www.instagram.com/relay_pro/" external={true}>
                                                                <Image
                                                                    image={$.svg('../../static/svg/logo/footer-insta.svg')}
                                                                    alt="Instagram"
                                                                    style={{alignSelf: 'center'}}/>
                                                            </Anchor>
                                                        </GridCell>
                                                        <GridCell padding={{left: 0.25, right: 0.25}}>
                                                            <Anchor href="https://twitter.com/Relay_Pro" external={true}>
                                                                <Image
                                                                    image={$.svg('../../static/svg/logo/footer-twitter.svg')}
                                                                    alt="Twitter"
                                                                    style={{alignSelf: 'center'}}/>
                                                            </Anchor>
                                                        </GridCell>
                                                        <GridCell padding={{left: 0.25, right: 0.25}}>
                                                            <Anchor href="https://vimeo.com/relaypro" external={true}>
                                                                <Image
                                                                    image={$.svg('../../static/svg/logo/footer-vimeo.svg')}
                                                                    alt="Vimeo"
                                                                    style={{alignSelf: 'center'}}/>
                                                            </Anchor>
                                                        </GridCell>
                                                    </Grid>
                                                </GridCell>
                                                <GridCell width="100%" padding={{top: 0}}>
                                                    <Layout width="290px" justify="center" align="center" padding={{left: 0.625, right: 0.625, bottom: footerRow ? 0 : 1}}>
                                                        <Copy setting="footer_label" color={colors.gray_dark} align="center" transform="uppercase">
                                                            Copyright © 2022 Relay, Inc.
                                                        </Copy>
                                                        <Copy setting="footer_label" color={colors.gray_dark} align="center" transform="uppercase">
                                                            All rights reserved.
                                                        </Copy>
                                                    </Layout>
                                                </GridCell>
                                            </Grid>)}
                                </GridContainer>
                            </Layout>
                            <Layout justify="center" align="center" position={footerRow ? 'absolute' : 'static'} positions={{bottom: 2.125, left: 1.75}}>
                                <SecureTrust customer="ec5c6a9722824ae3a054c9fa2f5f6025" />
                            </Layout>
                        </Layout>
                    </>)}
            </Quantum>)));
