import loaded from '@republic/foundation/browser/loaded';
import {reduce} from '@republic/foundation/lang/array';
import {noop} from '@republic/foundation/lang/function';
import {keys, owns} from '@republic/foundation/lang/object';
import {updated} from '@republic/foundation/http/query';
import script from '@republic/foundation/http/script';
import env from '../../env';
const
    dataLayer = (
        window.dataLayer = window.dataLayer || [
            {
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
            }
        ]),

    load = (
        (promise => (
            () => (
                promise ||
                (promise = (
                    loaded.then(
                        () => (
                            // HACK (mkibbel): neuter GTM for webviews.
                            window.location.pathname.indexOf('/webview') === 0 ?
                                null :
                                script(
                                    updated(
                                        '//www.googletagmanager.com/gtm.js',
                                        env.analytics.gtm)))))))))(
                                            null)),

    event = (event, config = {}) => (
        Promise.all([
            load(),
            new Promise(resolve => {
                dataLayer.push({
                    eventCallback: () => {
                        resolve();
                    },
                    event,
                    ...config
                });
            })
        ])
        .catch(noop)),

    pageview = (name, url, referrer) => (
        event(
            'pageview',
            {
                page: {
                    pageInfo: {
                        pageName: name,
                        destinationURL: url,
                        referringURL: referrer
                    }
                }
            })),

    login = id => (
        event(
            'auth:login',
            {
                user: {
                    profile: {
                        profileInfo: {
                            contactID: id
                        }
                    }
                }
            })),

    logout = () => (
        event(
            'auth:logout',
            {user: null})),

    cartPrice = price => (0.01 * (price || 0)).toFixed(2),

    cartDimensions = {
        payment: env.name === 'prod' ? 'dimension7' : 'dimension3'
    },

    cartMetrics = {},

    cartAdd = (sku, catalog, offers, quantity = 1, dimensions = {}, metrics = {}) => {
        if (catalog && catalog.data && owns(catalog.data.products, sku) &&
            offers && offers.data && owns(offers.data, sku)) {

            const {name, category} = catalog.data.products[sku];

            return (
                event(
                    'cart:add',
                    {
                        ecommerce: {
                            add: {
                                products: [{
                                    id: sku,
                                    name,
                                    quantity,
                                    category,
                                    price: cartPrice(offers.data[sku].price),
                                    ...reduce(
                                        keys(dimensions),
                                        (data, name) => {
                                            if (owns(cartDimensions, name)) {
                                                data[cartDimensions[name]] = dimensions[name];
                                            }
                                            return data;
                                        },
                                        {}),
                                    ...reduce(
                                        keys(metrics),
                                        (data, name) => {
                                            if (owns(cartMetrics, name)) {
                                                data[cartMetrics[name]] = metrics[name];
                                            }
                                            return data;
                                        },
                                        {})
                                }]
                            }
                        }
                    }));
        } else {
            return Promise.resolve();
        }
    },

    cartRemove = (sku, catalog, offers, quantity = 1, dimensions = {}, metrics = {}) => {
        if (catalog && catalog.data && owns(catalog.data.products, sku) &&
            offers && offers.data && owns(offers.data, sku)) {

            const {name, category} = catalog.data.products[sku];

            return (
                event(
                    'cart:remove',
                    {
                        ecommerce: {
                            remove: {
                                products: [{
                                    id: sku,
                                    name,
                                    quantity,
                                    category,
                                    price: cartPrice(offers.data[sku].price),
                                    ...reduce(
                                        keys(dimensions),
                                        (data, name) => {
                                            if (owns(cartDimensions, name)) {
                                                data[cartDimensions[name]] = dimensions[name];
                                            }
                                            return data;
                                        },
                                        {}),
                                    ...reduce(
                                        keys(metrics),
                                        (data, name) => {
                                            if (owns(cartMetrics, name)) {
                                                data[cartMetrics[name]] = metrics[name];
                                            }
                                            return data;
                                        },
                                        {})
                                }]
                            }
                        }
                    }));
        } else {
            return Promise.resolve();
        }
    },

    checkout = (step, name, cart) => {
        const
            lines = (
                reduce(
                    cart,
                    (data, {sku, catalog, offers, quantity = 1, dimensions = {}, metrics = {}}) => {
                        if (catalog && catalog.data && owns(catalog.data.products, sku) &&
                            offers && offers.data && owns(offers.data, sku)) {

                            const {name, category} = catalog.data.products[sku];

                            data.push({
                                id: sku,
                                name,
                                quantity,
                                category,
                                price: cartPrice(offers.data[sku].price),
                                ...reduce(
                                    keys(dimensions),
                                    (data, name) => {
                                        if (owns(cartDimensions, name)) {
                                            data[cartDimensions[name]] = dimensions[name];
                                        }
                                        return data;
                                    },
                                    {}),
                                ...reduce(
                                    keys(metrics),
                                    (data, name) => {
                                        if (owns(cartMetrics, name)) {
                                            data[cartMetrics[name]] = metrics[name];
                                        }
                                        return data;
                                    },
                                    {})
                            });
                        }
                        return data;
                    },
                    []));

        if (lines.length === cart.length) {
            return (
                event(
                    `checkout:${name}`,
                    {
                        ecommerce: {
                            checkout: {
                                actionField: {step},
                                products: lines
                            }
                        }
                    }));
        } else {
            return Promise.resolve();
        }
    },

    purchase = (transaction, revenue, tax, shipping, cart) => {
        const
            lines = (
                reduce(
                    cart,
                    (data, {sku, catalog, price, quantity = 1, dimensions = {}, metrics = {}}) => {
                        if (catalog && catalog.data && owns(catalog.data.products, sku)) {
                            const {name, category} = catalog.data.products[sku];

                            data.push({
                                id: sku,
                                name,
                                quantity,
                                category,
                                price: cartPrice(price),
                                ...reduce(
                                    keys(dimensions),
                                    (data, name) => {
                                        if (owns(cartDimensions, name)) {
                                            data[cartDimensions[name]] = dimensions[name];
                                        }
                                        return data;
                                    },
                                    {}),
                                ...reduce(
                                    keys(metrics),
                                    (data, name) => {
                                        if (owns(cartMetrics, name)) {
                                            data[cartMetrics[name]] = metrics[name];
                                        }
                                        return data;
                                    },
                                    {})
                            });
                        }
                        return data;
                    },
                    []));

        if (lines.length === cart.length) {
            return (
                event(
                    'purchase',
                    {
                        ecommerce: {
                            purchase: {
                                actionField: {
                                    id: transaction,
                                    affiliation: 'relaygo.com',
                                    revenue: cartPrice(revenue),
                                    tax: cartPrice(tax),
                                    shipping: cartPrice(shipping)
                                },
                                products: lines
                            }
                        }
                    }));
        } else {
            return Promise.resolve();
        }
    };

export {
    event,
    pageview,
    login,
    logout,
    cartAdd,
    cartRemove,
    checkout,
    purchase
};
