import sha1 from '@republic/foundation/crypto/sha1';
import {updated} from '@republic/foundation/http/query';
import {reduce} from '@republic/foundation/lang/array';
import {stringify} from '@republic/foundation/lang/json';
import {createPrefixed} from '@republic/foundation/storage';
import AuthStream from '../../auth/streams/AuthStream';
import {createRequestStream} from '../../core/streams';
import session from '../../core/services/storage/session';

export default (
    createRequestStream(
        'Offers',
        {
            code: 'products/offers',
            error: 'A request for product offers failed',
            singleton: true
        },
        createPrefixed(session, 'products/offers/3.0', 15 * 60),
        {auth: AuthStream},
        ({auth}) => (
            sha1(
                stringify(
                    auth.id ?
                        {id: auth.id} :
                        null))),
        offers => !!offers.data,
        () => true,
        ({auth}) => (
            fetch(
                updated(
                    `/fractus/${auth.token ? 'p' : 'u'}/stratus/rest/v3/offers`,
                    {
                        offer_type: 'Addition',
                        item_type: ['accessory', 'relay']
                    }),
                {
                    ...auth.token ?
                        {
                            headers: {
                                Authorization: `Bearer ${auth.token}`
                            }
                        } :
                        {}
                })),
        raw => ({
            raw,
            data: (
                reduce(
                    raw,
                    (data, {offer_components: components}) => (
                        reduce(
                            components,
                            (data, {offer_items: items, min = null, max = null}) => (
                                reduce(
                                    items,
                                    (data,
                                    {
                                        item: {
                                            sku,
                                            product,
                                            type: model,
                                            days_till_available: available
                                        },
                                        one_time_price: price,
                                        discounted_one_time_price: discount
                                    }) => {
                                        if (product === 'Relay') {
                                            data[sku] = {
                                                sku,
                                                model,
                                                available,
                                                price: discount || price,
                                                min,
                                                max
                                            };
                                        }
                                        return data;
                                    },
                                    data)),
                            data)),
                    {}))
        }),
        () => ({raw: undefined, data: undefined})));
