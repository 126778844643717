import {map} from '@republic/foundation/lang/array';
import {owns} from '@republic/foundation/lang/object';
import env from '../../env';
import experiments from '../../experiments';

const
    overrides = {},

    optimize = new Promise(resolve => {
        window.relay.optimize(resolve);
    }),

    override = (name, value) => {
        if (value && env.name !== 'prod') {
            overrides[name] = value;
        } else {
            delete overrides[name];
        }
    },

    experiment = name => (
        owns(overrides, name) ?
            Promise.resolve(
                map(overrides[name].split('-'),
                    digit => parseInt(digit, 10))) :
            (owns(experiments, name) ?
                optimize.then(optimize => {
                    const experiment = optimize && optimize.get(experiments[name]);

                    return (
                        experiment ?
                            map(experiment, digit => parseInt(digit, 10)) :
                            []);
                }) :
                Promise.resolve([])));

export {
    optimize,
    override,
    experiment
};
