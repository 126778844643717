import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {screenSubscribe, screenWidth} from '@republic/foundation/browser/screen';
import events from '@republic/foundation/events';
import {any, reduce, sorted} from '@republic/foundation/lang/array';
import {isEqual} from '@republic/foundation/lang/is';
import {keys, owns} from '@republic/foundation/lang/object';
import {createComponent, createNamedContext} from '@republic/react-foundation';

const
    BreakpointsContext = (
        createNamedContext(
            'Breakpoints',
            {
                choose: PropTypes.func.isRequired,
                subscribe: (
                    PropTypes.shape({
                        updated: PropTypes.func.isRequired
                    })
                    .isRequired)
            })),

    normalize = breakpoints => (
        sorted(
            reduce(
                keys(breakpoints),
                (normalized, name) => {
                    normalized.push({
                        name,
                        width: breakpoints[name]
                    });
                    return normalized;
                },
                []),
            ({width: left}, {width: right}) => left - right));

export default (
    createComponent(
        'Breakpoints',
        {
            propTypes: {
                breakpoints: (
                    PropTypes.objectOf(
                        PropTypes.number.isRequired)
                    .isRequired)
            }
        },
        class Breakpoints extends Component {
            static context = BreakpointsContext;

            constructor(props) {
                super(props);

                const publisher = events('updated');

                this.publish = publisher.publish;
                this.breakpoints = normalize(props.breakpoints);
                this.name = null;
                this.provider = {
                    choose: this.choose,
                    subscribe: publisher.subscribe
                };
            }

            choose = choices => {
                const {name, breakpoints} = this;
                let found = false,
                    choice = null;

                if (name) {
                    any(breakpoints, breakpoint => {
                        if (breakpoint.name === name) {
                            found = true;
                        }
                        if (owns(choices, breakpoint.name)) {
                            choice = choices[breakpoint.name];
                            return found;
                        }
                    });
                }
                return choice;
            };

            update = () => {
                const
                    {name, breakpoints} = this,
                    width = screenWidth();
                let choice = null;

                any(breakpoints, breakpoint => {
                    choice = breakpoint.name;
                    return breakpoint.width >= width;
                });

                if (choice !== name) {
                this.name = choice;
                    this.publish.updated(name);
                }
            };

            componentDidMount() {
                this.cancel = screenSubscribe.updated(this.update);
                this.update();
            }

            componentWillUnmount() {
                const cancel = this.cancel;

                if (cancel) {
                    cancel();
                    this.cancel = null;
                }
            }

            componentDidUpdate({breakpoints}) {
                if (!isEqual(this.props.breakpoints, breakpoints)) {
                    this.breakpoints = normalize(this.props.breakpoints);
                    this.update();
                }
            }

            render() {
                return (
                    <BreakpointsContext.Provider value={this.provider}>
                        {this.props.children}
                    </BreakpointsContext.Provider>);
            }
        }));
