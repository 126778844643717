import PropTypes from 'prop-types';
import React from 'react';
import {createComponent, use} from '@republic/react-foundation';
import Image from '../../core/components/Image';
import {ProductStreamInterface} from '../streams/products';
import {productType} from '../types';

export default (
    use({product: ProductStreamInterface})(
        createComponent(
            'ProductImage',
            {
                propTypes: {
                    product: PropTypes.shape(productType),
                    image: PropTypes.string.isRequired,
                    alignSelf: PropTypes.string
                },
                defaultProps: {
                    image: 'small'
                }
            },
            ({product, image, alignSelf}) => (
                (product && product.image) ?
                    <Image
                        image={product.image[image]}
                        alt={product.name}
                        {...alignSelf ?
                            {style: {alignSelf}} :
                            {}} /> :
                    null))));
