import PropTypes from 'prop-types';
import React from 'react';
import {createComponent} from '@republic/react-foundation';
import CopySettings from './CopySettings';

export default (
    createComponent(
        'Heading',
        {
            propTypes: {
                align: PropTypes.string,
                color: PropTypes.string,
                decoration: PropTypes.string,
                lh: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                setting: PropTypes.oneOf(['card_header', 'case_studies', 'solutions', 'large', 'huge', 'hulk', 'subheader', 'title', 'small', 'tiny', 'portal_small']).isRequired,
                tag: PropTypes.string,
                transform: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
                weight: PropTypes.string
            }
        },
        ({align, children, color, decoration, lh, setting, tag, transform, weight}) => (
            <CopySettings
                setting={setting}
                type="heading"
                {...align ? {align} : {}}
                {...color ? {color} : {}}
                {...decoration ? {decoration} : {}}
                {...lh ? {lh} : {}}
                {...tag ? {tag} : {}}
                {...transform ? {transform} : {}}
                {...weight ? {weight} : {}}>

                {children}
            </CopySettings>)));
