import PropTypes from 'prop-types';
import React from 'react';
import {createComponent, use} from '@republic/react-foundation';
import Image from '../../core/components/Image';
import PlanStream from '../streams/PlanStream';
import {planType} from '../types';

export default (
    use({plan: PlanStream})(
        createComponent(
            'PlanImage',
            {
                propTypes: {
                    plan: PropTypes.shape(planType),
                    image: PropTypes.string.isRequired,
                    alignSelf: PropTypes.string
                },
                defaultProps: {
                    image: 'small'
                }
            },
            ({plan, image, alignSelf}) => (
                (plan && plan.image) ?
                    <Image
                        image={plan.image[image]}
                        alt={plan.name}
                        {...alignSelf ?
                            {style: {alignSelf}} :
                            {}} /> :
                    null))));
