import React, {Component} from 'react';
import {Quantum} from '@republic/bits';
import {createContainer} from '@republic/react-foundation';

export default (
    createContainer(
        'GridContainer',
        {},
        Wrapped => (
            class extends Component {
                static displayName = (
                    `Grid(${
                        Wrapped.displayName ||
                        Wrapped.name ||
                        'Component'
                    })`);

                render() {
                    return (
                        <Quantum leaps={[
                            {row: false},
                            ['small', {row: true}]]}>

                            {({row}) => (
                                <Wrapped
                                    row={row}
                                    {...this.props} />)}
                        </Quantum>);
                }
            })));
