import PropTypes from 'prop-types';
import React from 'react';
import {map} from '@republic/foundation/lang/array';
import {createComponent, use} from '@republic/react-foundation';
import ErrorsStream from '../streams/ErrorsStream';
import {errorType} from '../types';

export default (
    use({errors: ErrorsStream},
        ({errors: {names, by}}, {errors: {dismiss}}) => ({
            errors: (
                map(names, name => ({
                    name,
                    message: by[name].message,
                    action: by[name].action,
                    dismiss: () => {
                        dismiss(name);
                    }
                })))
        }))(
        createComponent(
            'Errors',
            {
                propTypes: {
                    errors: (
                        PropTypes.arrayOf(
                            PropTypes.shape(
                                errorType)
                            .isRequired)
                        .isRequired)
                }
            },
            ({errors}) => (
                errors.length ?
                    <div className="generic-error">
                        {map(errors,
                            ({name, message, action, dismiss}) => (
                                <div className="text-center padding-top-2" key={name}>
                                    <p className="body-copy-small padding-bottom-1">{message}</p>
                                    <button className="button" onClick={dismiss}>{action}</button>
                                </div>))}
                    </div> :
                    null))));
