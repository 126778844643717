import sha1 from '@republic/foundation/crypto/sha1';
import {map, sorted} from '@republic/foundation/lang/array';
import {isEqual} from '@republic/foundation/lang/is';
import {stringify} from '@republic/foundation/lang/json';
import {get} from '@republic/foundation/lang/object';
import {createPrefixed} from '@republic/foundation/storage';
import $ from '@republic/spellcaster';
import AuthStream from '../../auth/streams/AuthStream';
import {addressFields} from '../../core/services/address';
import session from '../../core/services/storage/session';
import {createRequestStream} from '../../core/streams';
import CatalogStream from '../../products/streams/CatalogStream';
import OffersStream from '../../products/streams/OffersStream';
import PlanOffersStream from '../../products/streams/PlanOffersStream';
import UserStream from '../../users/streams/UserStream';
import {steps, serialize, parse} from '../services/checkout';
import BusinessCartStream from './BusinessCartStream';
import BusinessCheckoutModelStream from './BusinessCheckoutModelStream';
import BusinessDeliveryOffersStream from './BusinessDeliveryOffersStream';
import BusinessRedemptionsStream from './BusinessRedemptionsStream';

const
    model = name => (
        BusinessCheckoutModelStream.as(
            (model, {auth: {synchronized}}) => (
                synchronized(
                    model,
                    model => (
                        model ?
                            model.operators.get(model, name) :
                            null))
                .map(
                    model => ((
                        model &&
                        model.operators.valid(model) &&
                        model.operators.pristine(model)) ?
                            model.operators.value(model) :
                            null))
                .unique(isEqual))));

export default (
    createRequestStream(
        'BusinessPreview',
        {
            code: 'cart-business/preview',
            error: (
                (key, response, error, dismiss, {auth, user}) => {
                    if (response && response.status === 403) {
                        error(
                            `cart-business/preview/denied:${(auth && auth.id) || ''}`,
                            'You do not have permission to purchase with this account. Please ' +
                            'log out and ask the account owner to log in and complete this ' +
                            'purchase.',
                            'OK');
                    } else if (
                        response &&
                        response.status === 409 &&
                        /invoice/.test(get(response, 'raw', 'message') || '')) {

                        error(
                            `cart-business/preview/overdue:${key}`,
                            'Your account has outstanding invoice(s) that are past due. ' +
                            'To complete this purchase, you must issue payment to resolve your past due balance. ' +
                            'Please update your payment information through our portal or by emailing Billing@RelayPro.com',
                            'Update payment',
                            () => {
                                dismiss();
                                if (user) {
                                    user.clear();
                                }

                                if (user && user.data && user.data.business && (user.data.business.ach || user.data.business.net_days > 0)) {
                                    window.location = $.url('account:make-payment').url;
                                } else {
                                    window.location = $.url('account:update-payment').url;
                                }
                            });
                    } else {
                        error(
                            `cart-business/preview/error:${key}`,
                            'A request to preview taxes failed',
                            'Try again',
                            () => {
                                dismiss();
                            });
                    }
                }),
            singleton: true
        },
        createPrefixed(session, 'cart-business/preview/3.0', 15 * 60),
        {
            auth: AuthStream,
            user: UserStream,
            catalog: CatalogStream,
            productOffers: OffersStream,
            planOffers: PlanOffersStream,
            deliveryOffers: BusinessDeliveryOffersStream,
            cart: BusinessCartStream,
            redemptions: BusinessRedemptionsStream,
            account: model(steps.account),
            shipping: model(steps.shipping),
            delivery: model(steps.delivery),
            billing: (
                BusinessCheckoutModelStream.as(
                    (model, {auth: {synchronized}}) => (
                        synchronized(
                            model,
                            model => {
                                if (model) {
                                    const
                                        {operators} = model,
                                        shipping = operators.get(model, steps.shipping),
                                        billing = operators.get(model, steps.billing);

                                    return (
                                        (billing && billing.operators.valid(billing) && billing.operators.pristine(billing)) ?
                                            (billing.operators.value(billing).copy ?
                                                ((shipping && shipping.operators.valid(shipping) && shipping.operators.pristine(shipping)) ?
                                                    shipping.operators.value(shipping) :
                                                    null) :
                                                addressFields(
                                                    billing.operators.value(billing),
                                                    true)) :
                                            null);
                                } else {
                                    return null;
                                }
                            })
                        .unique(isEqual))))
        },
        ({auth, user, catalog, productOffers, planOffers, deliveryOffers, cart, redemptions, account, shipping, delivery, billing}) => ((
            (!user || (user.data && user.data.business)) &&
            catalog && catalog.data &&
            productOffers && productOffers.data && (
                (auth.state === AuthStream.states.unauthenticated && planOffers && planOffers.data) ||
                (auth.state !== AuthStream.states.unauthenticated && !planOffers)) &&
            (cart.contract.length || cart.devices.length || cart.accessories.length) &&
            (!delivery || (deliveryOffers && deliveryOffers.data))) ?
                sha1(
                    stringify({
                        id: (auth && auth.id) || '',
                        cart: {
                            payment: cart.payment,
                            contract: map(cart.contract, ({items}) => items),
                            devices: map(cart.devices, ({items}) => items),
                            accessories: map(cart.accessories, ({items}) => items)
                        },
                        coupons: sorted(map(redemptions, ({code}) => code)),
                        account,
                        shipping,
                        delivery,
                        billing
                    })) :
                null),
        preview => !!preview.data,
        ({user, productOffers, planOffers, deliveryOffers}) => (
            (!user || user.valid) &&
            productOffers.valid &&
            (!planOffers || planOffers.valid) &&
            (!deliveryOffers || deliveryOffers.valid)),
        ({auth, productOffers, planOffers, deliveryOffers, cart, redemptions, account, shipping, delivery, billing}) => (
            fetch(
                `/fractus/${(auth && auth.token) ? 'p': 'u'}/stratus/rest/v3/cart_previewer`,
                {
                    method: 'POST',
                    body: (
                        JSON.stringify({
                            payment_period_in_months: (
                                cart.payment.annual ?
                                    12 :
                                    1),
                            order_lines: (
                                serialize(
                                    format => (
                                        format
                                        .contract(cart)
                                        .devices(cart)
                                        .accessories(cart)
                                        .delivery(delivery && delivery.delivery)),
                                    productOffers,
                                    planOffers,
                                    deliveryOffers)),
                            ...account ?
                                {
                                    submitter: {
                                        first_name: account.first_name,
                                        last_name: account.last_name,
                                        email: account.email,
                                        phone: account.phone.replace(/[^\d]/g, '')
                                    }
                                } :
                                {},
                            ...shipping ?
                                {
                                    shipping_location: {
                                        address_1: shipping.address1,
                                        address_2: shipping.address2 || null,
                                        city: shipping.city,
                                        state: shipping.state,
                                        zip_code: shipping.zip
                                    }
                                } :
                                {},
                            ...billing ?
                                {
                                    billing_location: {
                                        address_1: billing.address1,
                                        address_2: billing.address2 || null,
                                        city: billing.city,
                                        state: billing.state,
                                        zip_code: billing.zip
                                    }
                                } :
                                {},
                            redemptions,
                            metadata: {
                                taxation: true,
                                ...account ?
                                    {company_name: account.company_name} :
                                    {}
                            },
                            b2b: true
                        })),
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        ...(auth && auth.token) ?
                            {Authorization: `Bearer ${auth.token}`} :
                            {}
                    }
                })),
        (order, {catalog}) => ({data: parse(order, catalog)}),
        () => ({data: undefined})));
