import PropTypes from 'prop-types';
import React from 'react';
import {Layout} from '@republic/bits';
import {createComponent} from '@republic/react-foundation';
import {stringOrNumber} from '../types';

export default (
    createComponent(
        'GridCell',
        {
            propTypes: {
                align: PropTypes.string,
                justify: PropTypes.string,
                width: PropTypes.string,
                height: PropTypes.string,
                minHeight: PropTypes.string,
                stack: PropTypes.bool,
                flex: PropTypes.oneOfType([
                    PropTypes.bool,
                    PropTypes.number
                ]),
                color: PropTypes.string,
                position: PropTypes.string,
                border: (
                    PropTypes.oneOfType([
                        PropTypes.array,
                        PropTypes.shape({
                            color: PropTypes.string,
                            width: PropTypes.array,
                            style: PropTypes.string
                        }),
                        PropTypes.string])),
                padding: (
                    PropTypes.oneOfType([
                        PropTypes.string,
                        PropTypes.number,
                        PropTypes.arrayOf(stringOrNumber),
                        PropTypes.shape({
                            bottom: stringOrNumber,
                            left: stringOrNumber,
                            right: stringOrNumber,
                            top: stringOrNumber
                        })
                    ]))
            },
            defaultProps: {
                stack: false
            }
        },
        ({children, width, stack, ...rest}) => (
            <Layout
                {...width ?
                    {width: stack ? '100%' : width} : {}}
                {...rest}>

                {children}
            </Layout>)));
