import PropTypes from 'prop-types';
import {createStream, createStreamContext} from '@republic/react-foundation';
import AuthStream from '../../auth/streams/AuthStream';
import UserStream from '../../users/streams/UserStream';
import BusinessCartStream from './BusinessCartStream';

export default (
    createStream(
        'BusinessContractRemovalStream',
        {singleton: true},
        createStreamContext(
            'BusinessCartRemovalContext',
            {dismiss: PropTypes.func.isRequired}),
        {
            auth: AuthStream,
            user: UserStream,
            cart: BusinessCartStream
        },
        {dismiss: () => {}},
        methods => methods,
        () => ({
            pending: false,
            removed: false
        }),
        on => on.dependencies((removal, next, prev) => (
            removal.pending ?
                ((!next.user || (next.user.data && !next.user.data.business)) ?
                    {
                        ...removal,
                        pending: false,
                        removed: false
                    } :
                    ((next.user.data && next.user.data.business) ?
                        {
                            ...removal,
                            pending: false,
                            removed: true
                        } :
                        removal)) :
                (removal.removed ?
                    ((!next.user || (next.user.data && !next.user.data.business)) ?
                        {
                            ...removal,
                            pending: false,
                            removed: false
                        } :
                        removal) :
                    (prev.auth &&
                    next.auth.state !== AuthStream.states.unauthenticated &&
                    prev.auth.state === AuthStream.states.unauthenticated &&
                    !next.cart.contract.length &&
                    (prev.cart.contract.length) ?
                        {
                            ...removal,
                            pending: true,
                            removed: !!(
                                next.user !== prev.user &&
                                next.user && next.user.data && next.user.data.business)
                        } :
                        removal)))),
        (on, {dismiss}) => on(dismiss.stream, removal => (
            removal.removed ?
                {
                    ...removal,
                    pending: false,
                    removed: false
                } :
                removal))));
