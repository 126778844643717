import {all, reduce} from '@republic/foundation/lang/array';
import {isEqual} from '@republic/foundation/lang/is';
import {owns} from '@republic/foundation/lang/object';

const
    states = [
        {code: 'AA', name: 'Military', discontiguous: true, territory: true},
        {code: 'AE', name: 'Military', discontiguous: true, territory: true},
        {code: 'AP', name: 'Military', discontiguous: true, territory: true},
        {code: 'AL', name: 'Alabama'},
        {code: 'AK', name: 'Alaska', discontiguous: true},
        {code: 'AS', name: 'American Samoa', discontiguous: true, territory: true},
        {code: 'AZ', name: 'Arizona'},
        {code: 'AR', name: 'Arkansas'},
        {code: 'CA', name: 'California'},
        {code: 'CO', name: 'Colorado'},
        {code: 'CT', name: 'Connecticut'},
        {code: 'DE', name: 'Delaware'},
        {code: 'DC', name: 'Dist. of Columbia'},
        {code: 'FM', name: 'Micronesia', discontiguous: true, territory: true},
        {code: 'FL', name: 'Florida'},
        {code: 'GA', name: 'Georgia'},
        {code: 'GU', name: 'Guam', discontiguous: true, territory: true},
        {code: 'HI', name: 'Hawaii', discontiguous: true},
        {code: 'ID', name: 'Idaho'},
        {code: 'IL', name: 'Illinois'},
        {code: 'IN', name: 'Indiana'},
        {code: 'IA', name: 'Iowa'},
        {code: 'KS', name: 'Kansas'},
        {code: 'KY', name: 'Kentucky'},
        {code: 'LA', name: 'Louisiana'},
        {code: 'ME', name: 'Maine'},
        {code: 'MH', name: 'Marshall Islands', discontiguous: true, territory: true},
        {code: 'MD', name: 'Maryland'},
        {code: 'MA', name: 'Massachusetts'},
        {code: 'MI', name: 'Michigan'},
        {code: 'MN', name: 'Minnesota'},
        {code: 'MS', name: 'Mississippi'},
        {code: 'MO', name: 'Missouri'},
        {code: 'MT', name: 'Montana'},
        {code: 'NE', name: 'Nebraska'},
        {code: 'NV', name: 'Nevada'},
        {code: 'NH', name: 'New Hampshire'},
        {code: 'NJ', name: 'New Jersey'},
        {code: 'NM', name: 'New Mexico'},
        {code: 'NY', name: 'New York'},
        {code: 'NC', name: 'North Carolina'},
        {code: 'ND', name: 'North Dakota'},
        {code: 'MP', name: 'Northern Marianas', discontiguous: true, territory: true},
        {code: 'OH', name: 'Ohio'},
        {code: 'OK', name: 'Oklahoma'},
        {code: 'OR', name: 'Oregon'},
        {code: 'PW', name: 'Palau', discontiguous: true, territory: true},
        {code: 'PA', name: 'Pennsylvania'},
        {code: 'PR', name: 'Puerto Rico', discontiguous: true, territory: true},
        {code: 'RI', name: 'Rhode Island'},
        {code: 'SC', name: 'South Carolina'},
        {code: 'SD', name: 'South Dakota'},
        {code: 'TN', name: 'Tennessee'},
        {code: 'TX', name: 'Texas'},
        {code: 'UT', name: 'Utah'},
        {code: 'VT', name: 'Vermont'},
        {code: 'VI', name: 'Virgin Islands', discontiguous: true, territory: true},
        {code: 'VA', name: 'Virginia'},
        {code: 'WA', name: 'Washington'},
        {code: 'WV', name: 'West Virginia'},
        {code: 'WI', name: 'Wisconsin'},
        {code: 'WY', name: 'Wyoming'}
    ],

    codes = (
        reduce(
            states,
            (codes, state) => {
                codes[state.code.toLowerCase()] = state;
                return codes;
            },
            {})),

    names = (
        reduce(
            states,
            (names, state) => {
                names[state.name.toLowerCase()] = state;
                return names;
            },
            {})),

    getStateByCode = code => {
        code = code.toLowerCase();
        return (
            owns(codes, code) ?
                codes[code] :
                null);
    },

    getStateByName = name => {
        name = name.toLowerCase();
        return (
            owns(names, name) ?
                names[name] :
                null);
    },

    fields = ['address1', 'address2', 'city', 'state', 'zip'],

    addressFields = (address, strict) => (
        (strict && !all(fields, field => owns(address, field))) ?
            null :
            reduce(
                fields,
                (fields, name) => {
                    fields[name] = address[name] || '';
                    return fields;
                },
                {})),

    isAddressEqual = (left, right) => (
        isEqual(
            addressFields(left),
            addressFields(right)));

export {
    states,
    getStateByCode,
    getStateByName,
    addressFields,
    isAddressEqual
};
