import {get} from '@republic/foundation/lang/object';
import {createStreamContext, createStreamInterface, createStream} from '@republic/react-foundation';
import CatalogStream from './CatalogStream';
import OffersStream from './OffersStream';

const
    ProductStreamContext = createStreamContext('ProductStreamContext'),

    createProductStream = (name, options, sku) => (
        createStream(
            name,
            options || {},
            ProductStreamContext,
            {
                catalog: CatalogStream,
                offers: OffersStream,
                sku
            },
            null,
            null,
            null,
            on => on.dependencies((product, {catalog, offers, sku}) => (
                (sku && catalog && catalog.data) ?
                    {
                        ...catalog.data.products[sku] || {},
                        ...get(offers, 'data', sku) || {}
                    } :
                    null)))),

    ProductStreamInterface = createStreamInterface('ProductStreamInterface', ProductStreamContext);

export {
    createProductStream,
    ProductStreamInterface
};
