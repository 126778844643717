import PropTypes from 'prop-types';
import {get} from '@republic/foundation/lang/object';
import {createStream, createStreamContext, stream} from '@republic/react-foundation';
import CatalogStream from './CatalogStream';
import PlanOffersStream from './PlanOffersStream';

export default (
    createStream(
        'PlanStream',
        {
            enhance: (
                stream(
                    ['sku'],
                    {
                        propTypes: {
                            sku: PropTypes.string
                        }
                    }))
        },
        createStreamContext('PlanStream'),
        {
            catalog: CatalogStream,
            plans: PlanOffersStream,
            sku: ({sku}) => sku
        },
        null,
        null,
        null,
        on => on.dependencies((plan, {catalog, plans, sku}) => (
            (sku && catalog && catalog.data) ?
                {
                    ...catalog.data.products[sku] || {},
                    ...get(plans, 'data', sku) || {}
                } :
                null))));
