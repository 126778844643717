import PropTypes from 'prop-types';
import {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {decode, encode} from '@republic/foundation/http/query';
import {keys} from '@republic/foundation/lang/object';
import {createComponent} from '@republic/react-foundation';
import env from '../../env';
import {boot} from '../services/sourcepoint';

export default (
    withRouter(
        createComponent(
            'Sourcepoint',
            {
                propTypes: {
                    location: PropTypes.object.isRequired,
                    param: PropTypes.string.isRequired
                },
                defaultProps: {
                    param: 'sourcepoint.staging'
                }
            },
            class Sourcepoint extends Component {
                sourcepoint = ({location: {pathname, search, hash}, history}) => {
                    const {[this.props.param]: staging, ...query} = decode(search.slice(1));

                    if (staging) {
                        const
                            body = document.body,
                            frame = document.createElement('iframe'),
                            loaded = () => {
                                frame.removeEventListener('load', loaded);
                                body.removeChild(frame);
                                if (staging !== 'on') {
                                    document.cookie = '_sp_v1_stage=';
                                }
                                boot();
                            };

                        history.replace(
                            `${pathname}${
                                keys(query).length ?
                                    `?${encode(query)}` :
                                    ''
                            }${hash}`);
                        frame.addEventListener('load', loaded);
                        frame.style.cssText = 'display:none';
                        frame.src = `${env.sourcepoint.mms}/mms/qa_set_env?env=${staging === 'on' ? 'stage' : 'public'}`;
                        body.appendChild(frame);
                    } else {
                        boot();
                    }
                }

                componentDidMount() {
                    this.sourcepoint(this.props);
                }

                render() {
                    return null;
                }
            })));
