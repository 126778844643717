import PropTypes from 'prop-types';
import {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {decode} from '@republic/foundation/http/query';
import {reduce} from '@republic/foundation/lang/array';
import {keys, owns} from '@republic/foundation/lang/object';
import {createComponent} from '@republic/react-foundation';
import {track} from '../services/zoho';

export default (
    withRouter(
        createComponent(
            'UTMScraper',
            {
                propTypes: {
                    params: PropTypes.array.isRequired
                }
            },
            class extends Component {
                parse({location: {search}, params}) {
                    const
                        query = decode(search.slice(1)),
                        utm = (
                            reduce(
                                params,
                                (data, param) => {
                                    if (owns(query, param)) {
                                        data[param] = query[param];
                                    }
                                    return data;
                                },
                                {channel: 'Relay Pro Site'}));

                    if (keys(utm).length) {
                        track({zoho: utm});
                    }
                }
                componentDidMount() {
                    this.parse(this.props);
                }

                render() {
                    return null;
                }
            })));
