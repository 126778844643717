import sha1 from '@republic/foundation/crypto/sha1';
import {find} from '@republic/foundation/lang/array';
import {stringify} from '@republic/foundation/lang/json';
import {title} from '@republic/foundation/lang/string';
import {createPrefixed} from '@republic/foundation/storage';
import AuthStream from '../../auth/streams/AuthStream';
import session from '../../core/services/storage/session';
import {createRequestStream} from '../../core/streams';

const
    parseDate = (date = '') => {
        const [match, year, month, day] = /^(\d{4})-(\d{1,2})-(\d{1,2})/.exec(date) || [];

        return (
            match ?
                (new Date(
                    parseInt(year, 10),
                    parseInt(month, 10) - 1,
                    parseInt(day, 10))
                .toISOString()) :
                null);
    };

export default (
    createRequestStream(
        'User',
        {
            code: 'users/user',
            error: 'A request for a user\'s info failed',
            singleton: true
        },
        createPrefixed(session, 'users/user/3.12.6', 15 * 60),
        {auth: AuthStream},
        ({auth}) => (
            auth.id ?
                sha1(
                    stringify({
                        id: auth.id
                    })) :
                null),
        user => !!user.data,
        () => true,
        ({auth}) => (
            fetch(
                `/fractus/p/stratus/rest/v3/users/${auth.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${auth.token}`
                    }
                })),
        raw => {
            const
                {
                    account: {
                        id: account,
                        customer_id: customer,
                        name: company_name,
                        b2b = false,
                        portal_store = false,
                        billing_location: address,
                        created_date: created,
                        billing_account_details: billing = {},
                        contract_payment_next_due_date: contract_bill_date,
                        contract_payment_period_in_months: contract_period,
                        net_days
                    } = {},
                    role = 'User'
                } = (
                    find(
                        raw.account_contacts || [],
                        ({contact}) => contact.contact_id === raw.contact_id) ||
                    {});

            return {
                data: {
                    id: raw.contact_id,
                    account: account ? String(account) : null,
                    customer: customer || null,
                    role,
                    portal_store,
                    profile: {
                        first_name: raw.first_name,
                        last_name: raw.last_name,
                        ...(b2b && company_name) ?
                            {company_name} :
                            {},
                        email: raw.email,
                        phone: (
                            raw.phone_number &&
                            (raw.phone_number
                            .replace(/[^\d]/g, '')
                            .slice(-10)
                            .replace(/^(\d{3})(\d{3})(\d{4})$/, '$1.$2.$3')))
                    },
                    ...b2b ?
                        {
                            business: {
                                ach: billing.bank_number ? true : false,
                                ...(contract_bill_date && contract_period) ?
                                    {
                                        contract: {
                                            bill_date: parseDate(contract_bill_date),
                                            period: contract_period
                                        }
                                    } :
                                    {},
                                net_days
                            }
                        } :
                        {},
                    ...address ?
                        {
                            address: {
                                address1: title(address.address_1 || ''),
                                address2: title(address.address_2 || ''),
                                city: title(address.city || ''),
                                state: address.state || '',
                                zip: address.zip_code || ''
                            }
                        } :
                        {},
                    balance: billing.balance || 0,
                    created: new Date(created).toISOString()
                }
            };
        },
        () => ({data: undefined})));
