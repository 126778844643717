import PropTypes from 'prop-types';
import React from 'react';
import {createComponent} from '@republic/react-foundation';
import CopySettings from './CopySettings';

export default (
    createComponent(
        'Copy',
        {
            propTypes: {
                align: PropTypes.string,
                color: PropTypes.string,
                decoration: PropTypes.string,
                lh: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                setting: PropTypes.oneOf(['body', 'body2', 'navlink', 'case_studies_body', 'case_studies_pull_quote', 'medium_body', 'large_body', 'large_quote', 'portal_xlarge', 'quote', 'small', 'tiny', 'teeny', 'footer_label', 'footer_link', 'huge']).isRequired,
                tag: PropTypes.string,
                transform: PropTypes.string,
                weight: PropTypes.string,
                cursor: PropTypes.string
            }
        },
        ({align, children, color, decoration, lh, setting, tag, transform, weight, cursor}) => (
            <CopySettings
                setting={setting}
                type="copy"
                {...align ? {align} : {}}
                {...color ? {color} : {}}
                {...decoration ? {decoration} : {}}
                {...lh ? {lh} : {}}
                {...tag ? {tag} : {}}
                {...transform ? {transform} : {}}
                {...weight ? {weight} : {}}
                {...cursor ? {cursor} : {}}>

                {children}
            </CopySettings>)));
