import React from 'react';
import {Quantum} from '@republic/bits';
import {createComponent} from '@republic/react-foundation';
import HeaderDesktop from './nav-desktop/HeaderDesktop';
import HeaderMobile from './nav-mobile/HeaderMobile';

export default (
    createComponent(
        'Header',
        {},
        () => {

        return(
            <Quantum leaps={[
                {size: 'tiny'},
                ['small', {size: 'small'}],
                ['medium', {size: 'medium'}],
                [1024, {size: 'desktop'}]
            ]}>

            {({size}) => (
                <>
                    {size !== 'desktop' ? <HeaderMobile /> : <HeaderDesktop />}
                </>)}
            </Quantum>);
        }));
