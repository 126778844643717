import PropTypes from 'prop-types';
import {stream} from '@republic/react-foundation';
import {createProductStream} from './products';

export default (
    createProductStream(
        'ProductStream',
        {
            enhance: (
                stream(
                    ['sku'],
                    {
                        propTypes: {
                            sku: PropTypes.string
                        }
                    }))
        },
        ({sku}) => sku));
