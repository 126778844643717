import PropTypes from 'prop-types';

const
    backgroundType = {
        url: PropTypes.string.isRequired
    },

    errorType = {
        message: PropTypes.string.isRequired,
        action: PropTypes.string.isRequired,
        dismiss: PropTypes.func
    },

    imageType = {
        url: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired
    },

    modalType = {
        shown: PropTypes.bool.isRequired,
        show: PropTypes.func.isRequired,
        hide: PropTypes.func.isRequired
    },

    stateType = {
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    },

    locationType = {
        name: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        zip: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
    },

    jobType = {
        url: PropTypes.string.isRequired,
        department: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
    },

    stringOrNumber = (
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]));


export {
    backgroundType,
    errorType,
    imageType,
    modalType,
    stateType,
    locationType,
    jobType,
    stringOrNumber
};
