import 'react-app-polyfill/ie11';
import React, {createElement} from 'react';
import {render} from 'react-dom';
import {HelmetProvider} from 'react-helmet-async';
import {Route, Router, Switch, withRouter} from 'react-router-dom';
import {map} from '@republic/foundation/lang/array';
import {compose} from '@republic/foundation/lang/function';
import {wrapped} from '@republic/react-foundation';
import $ from '@republic/spellcaster';
import {LazySwitch, LazyRoute} from '@republic/spellcaster/components';
import LoginHandler from './auth/components/LoginHandler';
import AccountMenu from './accounts/components/AccountMenu';
import BusinessCart from './cart-business/components/BusinessCart';
import Breakpoints from './core/components/Breakpoints';
import Errors from './core/components/Errors';
import Footer from './core/components/Footer';
import Header from './core/components/Header';
import LazyLoader from './core/components/LazyLoader';
import LoadingSpinner from './core/components/LoadingSpinner';
import ScrollUpShow from './core/components/ScrollUpShow';
import Sourcepoint from './core/components/Sourcepoint';
import UTMScraper from './core/components/UTMScraper';
import optimize from './core/enhancers/analytics/optimize';
import pageviews from './core/enhancers/analytics/pageviews';
import history from './core/services/history';
import NotFoundPage from './pages/notfound/NotFoundPage';
import * as serviceWorker from './serviceWorker';
import './static/css/style.scss';

const
    Wrapper = (
        compose(
            wrapped(HelmetProvider),
            wrapped(Router, {history}),
            withRouter,
            pageviews,
            optimize('relay-optimize-loading'))(
                Breakpoints));

render(
    <Wrapper breakpoints={{tiny: 550, small: 750, medium: 960, large: 1200, 'x-large': 1920, 'xx-large': 2500}}>
        <Switch>
            <Route path="/login">
                <LoginHandler>
                    <LoadingSpinner />
                    <p>Authenticating...</p>
                </LoginHandler>
            </Route>
            <Route path="/webview">
                {() => (
                    <LazySwitch>
                        <LazyLoader>
                            {map(
                                [
                                    ...$.urls('webview:cancel')
                                ],
                                ({url, component}) => (
                                    <LazyRoute
                                        key={url}
                                        path={url}
                                        component={component}
                                        exact={true} />))}
                            <LazyRoute component={NotFoundPage} />
                        </LazyLoader>
                    </LazySwitch>)}
            </Route>
            <Route
                path={$.url('account:invoices:print').url}>
                {({match: {path}}) => (
                    <LazySwitch>
                        <LazyLoader>
                            <LazyRoute
                                path={`${path}/:invoice`}
                                component={$.url('account:invoices:print').component}
                                exact={true} />
                            <LazyRoute component={NotFoundPage} />
                        </LazyLoader>
                    </LazySwitch>)}
            </Route>
            <Route>
                <ScrollUpShow offset={54}>
                    <AccountMenu />

                    <Switch>
                        <Route
                            component={BusinessCart} />
                    </Switch>

                    <Header />
                    <div className="site-frame">
                        <LazySwitch>
                            {createElement(
                                LazyLoader,
                                {},
                                ...map([
                                        ...$.urls('account:cancel'),
                                        ...$.urls('account:info'),
                                        ...$.urls('account:invoices'),
                                        ...$.urls('account:make-payment'),
                                        ...$.urls('account:purchase'),
                                        ...$.urls('account:returns'),
                                        ...$.urls('account:support'),
                                        ...$.urls('account:update-password'),
                                        ...$.urls('account:update-payment'),
                                        ...$.urls('account:update-profile'),
                                        ...$.urls('account:update-privacy'),
                                        ...$.urls('pages:home'),
                                        ...$.urls('pages:legal'),
                                        ...$.urls('pages:legal:detail'),
                                        ...$.urls('pages:business:checkout'),
                                        ...$.urls('pages:business:thank-you')
                                    ],
                                    ({url, component}) => (
                                        <LazyRoute
                                            path={url}
                                            component={component}
                                            exact={true} />)),
                                <LazyRoute component={NotFoundPage} />)}
                        </LazySwitch>
                    </div>

                    <Footer />
                    <Sourcepoint />
                </ScrollUpShow>
            </Route>
        </Switch>
        <Errors />
        <UTMScraper params={['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'channel']} />
    </Wrapper>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
