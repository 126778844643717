import React from 'react';
import PropTypes from 'prop-types';
import {createComponent} from '@republic/react-foundation';
import {LazySwitch} from '@republic/spellcaster/components';

export default (
    LazySwitch.context.with('lazy')(
        createComponent(
            'Loader',
            {
                propTypes: {
                    lazy: PropTypes.shape(LazySwitch.context.types).isRequired
                }
            },
            class Loader extends React.Component {
                constructor(props) {
                    super(props);

                    this.mounted = false;
                    this.cancel = null;
                    this.loading = null;
                    this.state = {loading: false};
                }

                componentDidMount() {
                    this.mounted = true;
                    this.cancel = (
                        this.props.lazy.listen(
                            loading => {
                                const
                                    wait = () => {
                                        (loading
                                        .then(() => {
                                            if (this.mounted &&
                                                this.state.loading &&
                                                this.loading === loading) {

                                                this.setState({loading: false});
                                            }
                                        })
                                        .catch(error => {
                                            if (this.mounted &&
                                                this.state.loading &&
                                                this.loading === loading) {

                                                this.setState({loading: false});
                                                window.location.reload();
                                            }
                                        }));
                                    };

                                this.loading = loading;
                                if (!this.state.loading) {
                                    this.setState({loading: true}, wait);
                                } else {
                                    wait();
                                }
                            }));
                }

                componentWillUnmount() {
                    const cancel = this.cancel;

                    this.mounted = false;
                    this.loading = null;
                    if (cancel) {
                        cancel();
                        this.cancel = null;
                    }
                }

                render() {
                    return (
                        <div
                            style={{
                                transition: 'opacity 0.2s ease-out',
                                ...this.state.loading ? {opacity: 0.6} : {}
                            }}>
                            {this.props.children}
                        </div>);
                }
            })));
