import PropTypes from 'prop-types';
import {owns} from '@republic/foundation/lang/object';
import {createStream, createStreamContext} from '@republic/react-foundation';

export default (
    createStream(
        'ModalsStream',
        {singleton: true},
        createStreamContext(
            'ModalsContext',
            {
                show: PropTypes.func.isRequired,
                hide: PropTypes.func.isRequired
            }),
        null,
        {
            show: name => ({name}),
            hide: name => ({name})
        },
        methods => methods,
        {},
        (on, {show}) => on(show.stream, (modals, {name}) => (
            !owns(modals, name) ?
                {
                    ...modals,
                    [name]: true
                } :
                modals)),
        (on, {hide}) => on(hide.stream, (modals, {name}) => (
            owns(modals, name) ?
                (({[name]: modal, ...modals}) => modals)(
                    modals) :
                modals))));
