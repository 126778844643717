import PropTypes from 'prop-types';
import {updated} from '@republic/foundation/http/query';
import {createComponent} from '@republic/react-foundation';
import Image from './Image';

// Reference:
// <img
//     id="trustwaveSealImage"
//     src="https://sealserver.trustwave.com/seal_image.php?customerId=ec5c6a9722824ae3a054c9fa2f5f6025&size=105x54&style=invert"
//     border="0"
//     style="cursor:pointer;"
//     onclick="javascript:window.open('https://sealserver.trustwave.com/cert.php?customerId=ec5c6a9722824ae3a054c9fa2f5f6025&size=105x54&style=invert', 'c_TW', 'location=no, toolbar=no, resizable=yes, scrollbars=yes, directories=no, status=no, width=615, height=720'); return false;"
//     oncontextmenu="javascript:alert('Copying Prohibited by Law - Trusted Commerce is a Service Mark of TrustWave Holdings, Inc.'); return false;"
//     alt="This site is protected by Trustwave's Trusted Commerce program" title="This site is protected by Trustwave's Trusted Commerce program" />

export default (
    createComponent(
        'SecureTrust',
        {
            propTypes: {
                customer: PropTypes.string.isRequired
            }
        },
        ({customer}) => (
            <Image
                image={{
                    url: (
                        updated(
                            '//sealserver.trustwave.com/seal_image.php',
                            {
                                customerId: customer,
                                size: '105x54',
                                style: 'invert'
                            })),
                    width: 105,
                    height: 54
                }}
                style={{cursor: 'pointer'}}
                onClick={event => {
                    event.preventDefault();
                    open(
                        updated(
                            '//sealserver.trustwave.com/cert.php?',
                            {
                                customerId: customer,
                                size: '105x54',
                                style: 'invert'
                            }),
                        'c_TW',
                        'location=no, toolbar=no, resizable=yes, scrollbars=yes, directories=no, status=no, width=615, height=720');
                }}
                onContextMenu={event => {
                    event.preventDefault();
                    alert('Copying Prohibited by Law - Trusted Commerce is a Service Mark of TrustWave Holdings, Inc.');
                }}
                alt="This site is protected by Trustwave's Trusted Commerce program" title="This site is protected by Trustwave's Trusted Commerce program" />)));