import PropTypes from 'prop-types';
import {identity} from '@republic/foundation/lang/function';
import {Source} from '@republic/foundation/streams';
import {createStream, createStreamContext} from '@republic/react-foundation';

const
    empty = (
        (new Source())
        .scan(identity, null)
        .latest());

export default (
    createStream(
        'BusinessCheckoutModelStream',
        {
            wrap: (dependencies, stream) => stream.map(({data}) => data).select(),
            singleton: true
        },
        createStreamContext(
            'BusinessCheckoutModelContext',
            {
                install: PropTypes.func.isRequired,
                uninstall: PropTypes.func.isRequired
            }),
        null,
        {
            install: model => ({model}),
            uninstall: () => {}
        },
        methods => methods,
        empty,
        (on, {install}) => on(install.stream, (stream, {model}) => {
            if (stream !== model) {
                if (stream !== empty) {
                    throw new Error('A checkout model stream has already been installed');
                } else {
                    return model;
                }
            } else {
                return stream;
            }
        }),
        (on, {uninstall}) => on(uninstall.stream, () => empty)));
