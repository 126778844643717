import loaded from '@republic/foundation/browser/loaded';
import script from '@republic/foundation/http/script';
import {noop} from '@republic/foundation/lang/function';
import env from '../../env';

// Requirements:
// 1) Geotargeted to CA - banner notification at or before time of collection - button
//    should link to privacy policy:
//
//    Cookies and other technologies are used on this site to offer users the best experience of
//    relevant content, information and advertising. As a California resident you have the right
//    to adjust what data we and our partners collect to optimize your experience.
//    For more information, please review our Privacy Policy.
//
// 2) Geotargeted, persistent, prominent link in the footer to access privacy manager - does not have
//    to be a button, but may not blend in
//
// 3) Present on relaygo.com, republicwireless.com and account.republicwireless.com - any places
//    where personal information is collected.
//
// 4) Think through webviews :(

let sourcepoint = null;

const
    boot = () => (
        sourcepoint || (
            sourcepoint = loaded.then(
                () => {
                    window._sp_queue = [];
                    window._sp_ = {
                        config: {
                            accountId: env.sourcepoint.account,
                            baseEndpoint: 'https://cdn.privacy-mgmt.com',
                            usnat: {
                                includeUspApi: true
                            }
                        }
                    };
                    return script('//cdn.privacy-mgmt.com/unified/wrapperMessagingWithoutDetection.js');
                })
                .catch(noop)));

export {
    boot
};
