import {identity, noop} from '@republic/foundation/lang/function';
import {isEqual, isFunction} from '@republic/foundation/lang/is';
import {owns} from '@republic/foundation/lang/object';
import {Stream} from '@republic/foundation/streams';
import {createMethod, createModel, on} from '@republic/foundation/streams/models';
import {createStream, createStreamContext} from '@republic/react-foundation';
import ErrorsStream from './streams/ErrorsStream';

const
    errors = ErrorsStream.provide,

    empty = (
        new Stream()
        .scan(identity, null)
        .latest()),

    createRequestStream = (
        name,
        {
            code,
            error,
            pool = 'pool',
            compareStream = isEqual,
            wrap,
            ...options
        },
        storage,
        dependencies,
        keyed,
        exists,
        valid,
        request,
        success,
        failure) => {

        const
            requesters = {},
            requester = key => {
                const
                    methods = {
                        success: createMethod(value => ({value})),
                        failure: createMethod((response, dependencies, value) => ({response, dependencies, value})),
                        dismiss: createMethod()
                    },

                    fetch = (dependencies, complete) => (
                        Promise.resolve(
                            request(dependencies))
                        .then(response => {
                            if (!response) {
                                methods.success(
                                    storage.set(
                                        key,
                                        success(
                                            response,
                                            dependencies)));
                                complete();
                            } else {
                                if (response.ok) {
                                    return (
                                        response.json()
                                        .then(raw => {
                                            methods.success(
                                                storage.set(
                                                    key,
                                                    success(
                                                        raw,
                                                        dependencies)));
                                            complete();
                                        }));
                                } else {
                                    return (
                                        response.json()
                                        .then(raw => {
                                            const data = {raw};

                                            for (let name in response) {
                                                data[name] = response[name];
                                            }
                                            methods.failure(
                                                data,
                                                dependencies,
                                                storage.set(
                                                    key,
                                                    failure(
                                                        data,
                                                        dependencies)));
                                            complete();
                                        }));
                                }
                            }
                        })
                        .catch(() => {
                            methods.failure(
                                null,
                                dependencies,
                                storage.set(
                                    key,
                                    failure(
                                        null,
                                        dependencies)));
                            complete();
                        })),

                    stream = (
                        createModel(
                            () => {
                                const data = storage.get(key) || {};

                                return {
                                    ...data,
                                    key,
                                    fetching: !exists(data)
                                };
                            },
                            on(methods.success.stream, (data, {value}) => (
                                {
                                    ...data,
                                    fetching: false,
                                    ...value
                                })),
                            on(methods.failure.stream, (data, {response, dependencies, value}) => {
                                if (response && response.status === 503) {
                                    errors.display(
                                        'maintenance',
                                        'Our site is going down for maintenance',
                                        'Refresh the page',
                                        () => {
                                            window.location.reload(true);
                                        });
                                } else if (isFunction(error)) {
                                    error(
                                        key,
                                        response,
                                        errors.display,
                                        () => {
                                            methods.dismiss();
                                        },
                                        dependencies);
                                } else {
                                    errors.display(
                                        `${code ? code : name.toLowerCase()}/error:${key}`,
                                        error || 'A request failed',
                                        response ? 'Try again' : 'OK',
                                        response ?
                                            () => {
                                                methods.dismiss();
                                            } :
                                            null);
                                }
                                return {
                                    ...data,
                                    fetching: false,
                                    ...value
                                };
                            }),
                            on(methods.dismiss.stream, data => {
                                storage.clear(key);
                                return (
                                    data.fetching ?
                                        data :
                                        {
                                            ...data,
                                            fetching: true
                                        });
                            }),
                            on(storage.cleared, (data, which) => (
                                key === which ?
                                    (data.fetching ?
                                        data :
                                        {
                                            ...data,
                                            fetching: true
                                        }) :
                                data))));

                return {
                    stream,
                    fetch,
                    request: null
                };
            },
            cleared = storage.cleared.tap(key => {
                if (owns(requesters, key)) {
                    delete requesters[key];
                }
            });

        cleared.subscribe(noop);
        return (
            createStream(
                `${name}Stream`,
                {
                    ...options,
                    compareStream,
                    wrap: (
                        wrap ||
                        ((dependencies, stream) => (
                            Stream.combineAll([
                                stream,
                                (stream
                                .map(({data: {requester}}) => (requester && requester.stream) || empty)
                                .unique()
                                .select())
                            ])
                            .map(([state, value]) => {
                                const {data: {key, valid, requester, clear}} = state;

                                if (key && valid && requester &&
                                    value && value.key === key && value.fetching &&
                                    !requester.request) {

                                    const
                                        {fetch} = requester,
                                        {dependencies: {[pool]: ignore, ...dependencies}} = state;

                                    requester.request = (
                                        fetch(
                                            dependencies,
                                            () => {
                                                requester.request = null;
                                            }));
                                }
                                return (
                                    (key && requester && value && value.key === key) ?
                                        {
                                            ...value,
                                            valid: valid && !value.fetching,
                                            clear
                                        } :
                                        null);
                            }))))
                },
                createStreamContext(`${name}Context`),
                {
                    ...dependencies || {},
                    [pool]: (
                        new Stream()
                        .scan(identity, requesters)
                        .latest())
                },
                null,
                null,
                () => ({
                    key: null,
                    valid: false,
                    requester: null,
                    clear: noop
                }),
                on => on.dependencies((data, {[pool]: requesters, ...dependencies}) => {
                    const key = keyed(dependencies);

                    if (key) {
                        if (!owns(requesters, key)) {
                            requesters[key] = requester(key);
                        }
                        const validity = valid(dependencies);

                        return (
                            (data.key === key &&
                            data.valid === validity &&
                            data.requester === requesters[key]) ?
                                data :
                                {
                                    key,
                                    valid: validity,
                                    requester: requesters[key],
                                    clear: () => {
                                        storage.clear(key);
                                    }
                                });
                    } else {
                        return (
                            (!data.key && data.requester === null) ?
                                data :
                                {
                                    key,
                                    valid: false,
                                    requester: null,
                                    clear: noop
                                });
                    }
                }),
                on => on(cleared, (data, key, {[pool]: requesters, ...dependencies}) => {
                    if (key === data.key && !owns(requesters, key)) {
                        requesters[key] = data.requester;
                    }
                    return data;
                })));
    };

export {
    createRequestStream
};
