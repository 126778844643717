import {updated} from '@republic/foundation/http/query';
import {reduce} from '@republic/foundation/lang/array';
import {createPrefixed} from '@republic/foundation/storage';
import AuthStream from '../../auth/streams/AuthStream';
import session from '../../core/services/storage/session';
import {createRequestStream} from '../../core/streams';

export default (
    createRequestStream(
        'PlanOffersStream',
        {
            code: 'products/plan-offers',
            error: 'A request for plan offers failed',
            singleton: true
        },
        createPrefixed(session, 'products/plan-offers', 15 * 60),
        {auth: AuthStream},
        ({auth}) => (
            auth.state === AuthStream.states.unauthenticated ?
                'plan-offers' :
                null),
        offers => !!offers.data,
        () => true,
        () => (
            fetch(
                updated(
                    '/fractus/u/stratus/rest/v3/offers',
                    {
                        offer_type: 'ContractStart',
                        item_type: 'service_plan'
                    }))),
        raw => ({
            raw,
            data: (
                reduce(
                    raw,
                    (data, {offer_components: components}) => (
                        reduce(
                            components,
                            (data, {offer_items: items, min = null, max = null}) => (
                                reduce(
                                    items,
                                    (data,
                                    {
                                        item: {
                                            sku,
                                            product,
                                            type: model,
                                            days_till_available: available
                                        },
                                        recurring_price: price,
                                        discounted_recurring_price: discount
                                    }) => {
                                        if (product === 'Relay') {
                                            data[sku] = {
                                                sku,
                                                model,
                                                available,
                                                price: discount || price,
                                                min,
                                                max
                                            };
                                        }
                                        return data;
                                    },
                                    data)),
                            data)),
                    {}))
        }),
        () => ({raw: undefined, data: undefined})));
