import PropTypes from 'prop-types';
import random from '@republic/foundation/crypto/random';
import {decode as b64decode, encode as b64encode} from '@republic/foundation/crypto/base64';
import {bytes as s256bytes} from '@republic/foundation/crypto/sha256';
import {decode, encode, updated} from '@republic/foundation/http/query';
import {reduce} from '@republic/foundation/lang/array';
import {identity} from '@republic/foundation/lang/function';
import {get} from '@republic/foundation/lang/object';
import {createNamed} from '@republic/foundation/storage';
import {Source, Stream, ready} from '@republic/foundation/streams';
import {createStream, createStreamContext} from '@republic/react-foundation';
import {login as reportLogin, logout as reportLogout} from '../../core/services/analytics';
import history from '../../core/services/history';
import local from '../../core/services/storage/local';
import ErrorsStream from '../../core/streams/ErrorsStream';
import env from '../../env';

const
    errors = ErrorsStream.provide,

    storage = createNamed(local, 'auth/4.0', 2 * 60 * 60),

    states = (
        reduce(
            [
                'unauthenticated',
                'authenticating',
                'authenticated'
            ],
            (states, state) => {
                states[state] = state;
                return states;
            },
            {})),

    initial = {
        state: states.unauthenticated,
        authorization: null,
        authentication: null,
        token: null,
        id: null,
        roles: {}
    },

    AuthStream = (
        createStream(
            'AuthStream',
            {singleton: true},
            createStreamContext(
                'AuthContext',
                {
                    authorize: PropTypes.func.isRequired,
                    authenticate: PropTypes.func.isRequired,
                    deauthorize: PropTypes.func.isRequired,
                    deauthenticate: PropTypes.func.isRequired,
                    synchronized: PropTypes.func.isRequired
                }),
            null,
            {
                loginAuthorize: (
                    () => ({
                        redirect: (
                            `${window.location.pathname}${window.location.search}${window.location.hash}`)
                    })),
                loginAuthenticate: () => decode(window.location.search.slice(1)),
                loginSuccess: params => params,
                loginFailure: () => {},
                logoutDeauthorize: (
                    () => ({
                        redirect: (
                            `${window.location.pathname}${window.location.search}${window.location.hash}`)
                    })),
                logoutDeauthenticate: () => {}
            },
            ({loginAuthorize, loginAuthenticate, logoutDeauthorize, logoutDeauthenticate}, auth) => {
                const
                    synchronizer = (
                        Stream.combineAny([
                            auth,
                            (auth
                            .map(auth => {
                                const source = new Source();

                                (Promise.resolve()
                                .then(ready)
                                .then(() => {
                                    source.next(auth);
                                    source.complete();
                                }));
                                return source;
                            })
                            .select())
                        ])
                        .map(([prev, next]) => prev === next)
                        .unique()),

                    synchronized = (stream, success, failure) => {
                        const
                            resolvedSuccess = success || identity,
                            resolvedFailure = failure || identity;

                        return (
                            Stream.combineAll([stream, synchronizer])
                            .map(
                                ([data, synchronized]) => (
                                    synchronized ?
                                        resolvedSuccess(data) :
                                        resolvedFailure(null))));
                    };

                return {
                    authorize: loginAuthorize,
                    authenticate: loginAuthenticate,
                    deauthorize: logoutDeauthorize,
                    deauthenticate: logoutDeauthenticate,
                    synchronized
                };
            },
            () => ({
                ...initial,
                ...storage.get() || {}
            }),
            (on, {loginAuthorize}) => on(loginAuthorize.stream, (data, {redirect}) => {
                if (data.authentication) {
                    return data;
                } else if (!data.authorization) {
                    const
                        state = random(36),
                        verifier = random(36);

                    data = storage.set({
                        ...data,
                        authorization: {
                            redirect,
                            state,
                            verifier,
                            challenge: b64encode(s256bytes(verifier))
                        }
                    });
                } else {
                    data = storage.set({
                        ...data,
                        authorization: {
                            ...data.authorization,
                            redirect
                        }
                    });
                }
                window.location = (
                    updated(
                        `${env.auth.url}/realms/Relay/protocol/openid-connect/auth`,
                        {
                            client_id: env.auth.client,
                            code_challenge: data.authorization.challenge,
                            code_challenge_method: 'S256',
                            redirect_uri: `${window.location.origin}/login`,
                            response_type: 'code',
                            scope: 'openid profile roles email',
                            state: data.authorization.state
                        }));
                return data;
            }),
            (on, {loginAuthenticate, loginSuccess, loginFailure}) => (
                on(loginAuthenticate.stream, (data, {state, code, error, error_description}) => {
                    const {authorization} = data;

                    if (!authorization) {
                        history.replace('/');
                        return data;
                    } else if (error) {
                        errors.display(
                            'auth/authorize/error',
                            `An authorization error occurred${
                                error_description ?
                                    `: ${error_description}` :
                                    ''}`,
                            'OK');
                        data = storage.set({...initial});
                        history.replace(authorization.redirect);
                        return data;
                    } else if (state !== authorization.state) {
                        errors.display(
                            'auth/authorize/csrf',
                            'Suspicious authorization activity detected, aborting authentication',
                            'OK');
                        data = storage.set({...initial});
                        history.replace(authorization.redirect);
                        return data;
                    } else if (data.authentication) {
                        history.replace(authorization.redirect);
                        return data;
                    } else {
                        (fetch(
                            '/oauth2/realms/Relay/protocol/openid-connect/token',
                            {
                                method: 'POST',
                                body: encode({
                                    client_id: env.auth.client,
                                    code,
                                    code_verifier: authorization.verifier,
                                    grant_type: 'authorization_code',
                                    redirect_uri: `${window.location.origin}/login`
                                }),
                                headers: {
                                    Accept: 'application/json',
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                }
                            })
                        .then(response => {
                            if (response.ok) {
                                return response.json().then(loginSuccess);
                            } else {
                                errors.display(
                                    'auth/authenticate/error',
                                    `An authentication error occurred${
                                        (response.data && response.data.error_description) ?
                                            `: ${error_description}` :
                                            ''}`,
                                    'OK');
                                loginFailure();
                            }
                        })
                        .catch(() => {
                            errors.display(
                                'auth/authenticate/request',
                                'An error occurred while making an authentication request',
                                'OK');
                            loginFailure();
                        }));
                        return {
                            ...data,
                            state: states.authenticating
                        };
                    }
                })),
            (on, {loginSuccess}) => on(loginSuccess.stream, (data, params) => {
                const {authorization} = data;

                if (!authorization) {
                    history.replace('/');
                    return data;
                } else {
                    try {
                        const
                            {
                                expires_in: expires,
                                access_token: token,
                                id_token: id,
                                refresh_token: refresh
                            } = params,
                            {
                                sub: user,
                                user_info: {roles}
                            } = JSON.parse(b64decode(token.split('.')[1]));

                        reportLogin(id);
                        data = (
                            storage.set(
                                {
                                    ...data,
                                    state: states.authenticated,
                                    authorization: null,
                                    authentication: {
                                        access: token,
                                        id,
                                        refresh
                                    },
                                    token,
                                    id: user,
                                    roles: (
                                        reduce(
                                           roles,
                                            (roles, role) => {
                                                roles[role] = true;
                                                return roles;
                                            },
                                            {}))
                                },
                                expires));
                        history.replace(authorization.redirect);
                        return data;
                    } catch (error) {
                        errors.display(
                            'auth/authenticate/parse',
                            'An error occurred while processing authentication data',
                            'OK');
                        data = storage.set({...initial});
                        history.replace(authorization.redirect);
                        return data;
                    }
                }
            }),
            (on, {loginFailure}) => on(loginFailure.stream, data => {
                const {authorization} = data;

                data = storage.set({...initial});
                history.replace(authorization ? authorization.redirect : '/');
                return data;
            }),
            (on, {logoutDeauthorize}) => on(logoutDeauthorize.stream, (data, {redirect}) => {
                const {authentication} = data;

                if (!authentication) {
                    return data;
                } else {
                    data = storage.set({
                        ...data,
                        authorization: {
                            redirect
                        }
                    });
                }
                window.location = (
                    updated(
                        `${env.auth.url}/realms/Relay/protocol/openid-connect/logout`,
                        {
                            id_token_hint: authentication.id,
                            post_logout_redirect_uri: `${window.location.origin}/login`
                        }));
                return data;
            }),
            (on, {logoutDeauthenticate}) => on(logoutDeauthenticate.stream, data => {
                const
                    {authorization, authentication} = data;

                if (authorization && authentication) {
                    storage.clear();
                }
                history.replace(authorization ? authorization.redirect : '/');
                return {...initial};
            }),
            on => on(storage.cleared, data => {
                if (data.state === states.authenticated) {
                    reportLogout();
                }
                return {...initial};
            })));

AuthStream.states = states;

export default AuthStream;
