import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {render, unmountComponentAtNode} from 'react-dom';
import {decode} from '@republic/foundation/http/query';
import {map} from '@republic/foundation/lang/array';
import {keys} from '@republic/foundation/lang/object';
import {createComponent} from '@republic/react-foundation';

const
    counter = (count => () => count += 1)(0),

    mount = (
        (component, container) => (
            new Promise(resolve => {
                render(
                    component,
                    container,
                    () => {
                        resolve();
                    });
            }))),

    HttpFrameIframe = (
        createComponent(
            'HttpFrameIframe',
            {
                propTypes: {
                    container: PropTypes.instanceOf(HTMLElement).isRequired,
                    name: PropTypes.string.isRequired,
                    resolve: PropTypes.func.isRequired,
                    reject: PropTypes.func.isRequired
                }
            },
            ({container, name, resolve, reject}) => (
                <iframe
                    title="Submitting..."
                    name={name}
                    border="0"
                    width="0"
                    height="0"
                    style={{
                        width: 0,
                        height: 0,
                        border: 'none',
                        display: 'none'
                    }}
                    onLoad={event => {
                        const
                            search = (
                                (event.target.contentWindow.location.search || '')
                                .slice(1));

                        unmountComponentAtNode(container);
                        document.body.removeChild(container);
                        resolve(decode(search));
                    }}
                    onError={() => {
                        unmountComponentAtNode(container);
                        document.body.removeChild(container);
                        reject();
                    }}
                    onAbort={() => {
                        unmountComponentAtNode(container);
                        document.body.removeChild(container);
                        reject();
                    }} />))),

    HttpFrameForm = (
        createComponent(
            'HttpFrameForm',
            {
                propTypes: {
                    container: PropTypes.instanceOf(HTMLElement).isRequired,
                    target: PropTypes.string.isRequired,
                    url: PropTypes.string.isRequired,
                    children: PropTypes.node.isRequired
                }
            },
            class extends Component {
                componentDidMount() {
                    const {container} = this.props;

                    this.ref.submit();
                    unmountComponentAtNode(container);
                    document.body.removeChild(container);
                }

                render() {
                    const {target, url, children} = this.props;

                    return (
                        <form
                            ref={ref => {
                                this.ref = ref;
                            }}
                            target={target}
                            action={url}
                            method="post"
                            style={{
                                visibility: 'hidden',
                                position: 'absolute',
                                width: 0,
                                height: 0
                            }}>
                            {children}
                        </form>);
                }
            }));

export default (
    (url, data, prefix = 'http-frame') => (
        new Promise((resolve, reject) => {
            const
                name = `${prefix}-iframe-${counter()}`,
                iframeContainer = document.createElement('div'),
                formContainer = document.createElement('div');

            document.body.appendChild(iframeContainer);
            document.body.appendChild(formContainer);
            (mount(
                <HttpFrameIframe
                    container={iframeContainer}
                    name={name}
                    resolve={resolve}
                    reject={reject} />,
                iframeContainer)
            .then(() => {
                render(
                    <HttpFrameForm
                        container={formContainer}
                        target={name}
                        url={url}>
                        {map(
                            keys(data),
                            name => (
                                <input
                                    key={name}
                                    type="hidden"
                                    name={name}
                                    value={data[name]} />))}
                    </HttpFrameForm>,
                    formContainer);
            }));
        })));
